import Vue from 'vue';

import axios from 'axios';

import orderCreation from '@store/modules/orderCreation';

import basket from './basket';
import comments from './comments';
import details from './details';
import knives from './knives';

const CancelToken = axios.CancelToken;

let cancel;

const DEFAULT_COMPOSITION_STAGE = orderCreation.state.DEFAULT_COMPOSITION_STAGE;
const DEFAULT_CREATION_STAGE = orderCreation.state.DEFAULT_CREATION_STAGE;
const DEFAULT_FORM_DATA = orderCreation.state.DEFAULT_FORM_DATA;
const DEFAULT_PRODUCT_DATA = orderCreation.state.DEFAULT_PRODUCT_DATA;
const DEFAULT_DELIVERY_DATA = orderCreation.state.DEFAULT_DELIVERY_DATA;

const DEFAULT_FILTERS = {
    page: null,
    limit: 30,
    title: null,
    client_id: null,
    company_id: null,
    urgency_id: null,
    principle: null,
    'created_at[from]': null,
    'created_at[to]': null,
    sort: 'new',
    direction: 'desc',
};

export default {
    namespaced: true,

    modules: {
        basket,
        comments,
        details,
        knives,
    },

    state: {
        WIZARD_STAGES: [{ ...DEFAULT_COMPOSITION_STAGE }, { ...DEFAULT_CREATION_STAGE }],

        list: [],
        listPagination: {},
        listFilter: { ...DEFAULT_FILTERS },

        details_placement: [],
        logs: [],
        payload: {},
        recommendations: [],
        winners: [],

        stage: null,
        clientTab: null,

        formData: { ...DEFAULT_FORM_DATA },
        productData: { ...DEFAULT_PRODUCT_DATA },
        deliveryData: { ...DEFAULT_DELIVERY_DATA },

        loadingGetList: false,
        loadingGetItem: false,
        loadingSaveItem: false,
        loadingSaveItemClientAddress: false,
        loadingSaveItemProductWorkingName: false,
        loadingDeleteItem: false,
    },

    getters: {
        // @Explanation: Layout system
        compositionStage(state, getters, rootState) {
            return state.WIZARD_STAGES.find((stage) => stage.value === rootState.orderCreation.WIZARD_ORDER_COMPOSITION_VALUE);
        },

        compositionStageIndex(state, getters, rootState) {
            return state.WIZARD_STAGES.findIndex((stage) => stage.value === rootState.orderCreation.WIZARD_ORDER_COMPOSITION_VALUE);
        },

        placingStage(state, getters, rootState) {
            return state.WIZARD_STAGES.find((stage) => stage.value === rootState.orderCreation.WIZARD_ORDER_PLACING_VALUE);
        },

        placingStageIndex(state, getters, rootState) {
            return state.WIZARD_STAGES.findIndex((stage) => stage.value === rootState.orderCreation.WIZARD_ORDER_PLACING_VALUE);
        },

        hasCompositionStage(state, getters) {
            return state.stage.value === getters.compositionStage.value;
        },

        hasPlacingStage(state, getters) {
            return state.stage.value === getters.placingStage.value;
        },

        // @Explanation: Filters
        listActiveFilter(state) {
            return Object.keys(state.listFilter)
                .filter((key) => state.listFilter[key] !== null)
                .reduce((resp, key) => ((resp[key] = state.listFilter[key]), resp), {});
        },

        isFilterNotEmpty(state, getters, rootState) {
            return !!Object.keys(getters.listActiveFilter).filter(
                (key) => !rootState.constant.INACTIVE_FILTERING_KEYS.concat(state.INACTIVE_FILTERING_KEYS).includes(key),
            ).length;
        },

        // @Explanation: Data
        contactData(state) {
            const { name, surname, patronymic, phone } = state.deliveryData;
            return { name, surname, patronymic, phone };
        },
    },

    actions: {
        GET_LIST: async ({ state, rootGetters, getters, commit }, filters) => {
            try {
                if (cancel !== undefined) {
                    cancel();
                }

                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                const path = `/api/typographies/${rootGetters[`currentTypography`].id}/orders/calculations`;

                const resp = await $axios.get(path, {
                    params: filters || getters.listActiveFilter,

                    cancelToken: new CancelToken(function executor(c) {
                        cancel = c;
                    }),
                });

                if (resp) {
                    commit('SET_LIST', resp.list);
                    commit('SET_PAGINATION', resp.pagination);
                }

                return resp || {};
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        GET_ITEM: async ({ state, getters, rootGetters, commit }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: true });

                const path = `/api/typographies/${rootGetters[`currentTypography`].id}/orders/calculations/${id}`;

                const resp = await $axios.get(path);

                commit('SET_ITEM', resp.single);
                commit('SET_DETAILS_PLACEMENT', resp.details_placement);
                commit('SET_LOGS', resp.logs);
                commit('SET_PAYLOAD', resp.payload);
                commit('SET_RECOMMENDATIONS', resp.recommendations);
                commit('SET_WINNERS', resp.winners);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: false });
            }
        },

        SAVE_ITEM: async ({ state, rootGetters, commit, dispatch }, { formData, without_fetch }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

                const path = `/api/typographies/${rootGetters[`currentTypography`].id}/orders/calculations`;

                const resp = await $axios.post(path, formData);

                if (!without_fetch) dispatch('GET_ITEM', resp.single.id);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
            }
        },

        SAVE_ITEM_CLIENT_ADDRESS: async ({ state, commit, rootGetters, dispatch }, { id, formData }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItemClientAddress', status: true });

                const path = `/api/typographies/${rootGetters.currentTypography.id}/orders/calculations/${id}/address`;

                const resp = await $axios.post(path, formData);

                dispatch('GET_ITEM', id);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItemClientAddress', status: false });
            }
        },

        SAVE_ITEM_PRODUCT_WORKING_NAME: async ({ state, commit, rootGetters, dispatch }, { id, formData }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItemProductWorkingName', status: true });

                const path = `/api/typographies/${rootGetters.currentTypography.id}/orders/calculations/${id}/products`;

                const resp = await $axios.post(path, formData);

                dispatch('GET_ITEM', id);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItemProductWorkingName', status: false });
            }
        },

        DELETE_ITEM: async ({ commit, rootGetters, dispatch }, { id, without_fetch }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: true });

                const path = `/api/typographies/${rootGetters[`currentTypography`].id}/orders/calculations/${id}`;

                const resp = await $axios.delete(path);

                if (!without_fetch) dispatch('GET_LIST');

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: false });
            }
        },
    },

    mutations: {
        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },

        SET_LIST(state, list) {
            Vue.set(state, 'list', list);
        },

        SET_PAGINATION(state, pagination) {
            Vue.set(state, 'listPagination', pagination);
        },

        SET_ITEM(state, item) {
            Vue.set(state, 'item', item);
        },

        SET_DETAILS_PLACEMENT(state, details_placement) {
            Vue.set(state, 'details_placement', details_placement);
        },

        SET_LOGS(state, logs) {
            Vue.set(state, 'logs', logs);
        },

        SET_PAYLOAD(state, payload) {
            Vue.set(state, 'payload', payload);
        },

        SET_RECOMMENDATIONS(state, recommendations) {
            Vue.set(state, 'recommendations', recommendations);
        },

        SET_WINNERS(state, winners) {
            Vue.set(state, 'winners', winners);
        },

        SET_CLIENT_TAB(state, clientTab) {
            Vue.set(state, 'clientTab', clientTab);
        },

        SET_STAGE(state, stage) {
            Vue.set(state, 'stage', stage);
        },

        SET_FORM_DATA(state, formData) {
            Vue.set(state, 'formData', { ...DEFAULT_FORM_DATA, ...formData });
        },

        SET_FORM_DATA_PROP(state, { key, value }) {
            Vue.set(state.formData, key, value);
        },

        SET_PRODUCT_DATA(state, productData) {
            Vue.set(state, 'productData', { ...DEFAULT_PRODUCT_DATA, ...productData });
        },

        SET_PRODUCT_DATA_PROP(state, { key, value }) {
            Vue.set(state.productData, key, value);
        },

        SET_DELIVERY_DATA(state, deliveryData) {
            Vue.set(state, 'deliveryData', { ...DEFAULT_DELIVERY_DATA, ...deliveryData });
        },

        SET_DELIVERY_DATA_PROP(state, { key, value }) {
            Vue.set(state.deliveryData, key, value);
        },

        UPDATE_LIST_FILTER(state, newFilterlist) {
            Object.entries(newFilterlist).forEach(([key, value]) => Vue.set(state.listFilter, key, value));
            Vue.set(state.listFilter, 'page', newFilterlist.page || 1);
        },

        UPDATE_STAGES(state, stages) {
            state.WIZARD_STAGES = state.WIZARD_STAGES.map((stage, index) => ({
                ...stage,
                ...stages[index],
            }));
        },

        UPDATE_FORM_DATA(state, data) {
            Vue.set(state, 'formData', { ...state.formData, ...data });
        },

        UPDATE_PRODUCT_DATA(state, data) {
            Vue.set(state, 'productData', { ...state.productData, ...data });
        },

        UPDATE_DELIVERY_DATA(state, data) {
            Vue.set(state, 'deliveryData', { ...state.deliveryData, ...data });
        },

        RESET_LIST(state) {
            state.list = [];
        },

        RESET_ITEM(state) {
            state.item = {};
            state.details_placement = [];
            state.logs = [];
            state.payload = {};
            state.recommendations = [];
            state.winners = [];
        },

        RESET_STAGES(state) {
            state.stage = null;
        },

        RESET_CLIENT_TAB(state) {
            Vue.set(state, 'clientTab', null);
        },

        RESET_FORM_DATA(state) {
            Vue.set(state, 'formData', { ...DEFAULT_FORM_DATA });
        },

        RESET_PRODUCT_DATA(state) {
            Vue.set(state, 'productData', { ...DEFAULT_PRODUCT_DATA });
        },

        RESET_DELIVERY_DATA(state) {
            Vue.set(state, 'deliveryData', { ...DEFAULT_DELIVERY_DATA });
        },

        RESET_STORE(state) {
            state.listFilter = { ...DEFAULT_FILTERS };
        },
    },
};
