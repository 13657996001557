import Vue from 'vue';

import pattern from './pattern';

const DEFAULT_FILTER = {
    page: null,
    short: null,
    limit: 30,
    sort: 'new',
    direction: 'desc',
};

export default {
    namespaced: true,

    modules: {
        pattern,
    },

    state: {
        list: [],
        listPagination: {},
        listFilter: { ...DEFAULT_FILTER },

        item: {},

        loadingGetList: false,
        loadingGetItem: false,
        loadingSaveItem: false,
        loadingDeleteItem: false,
    },

    getters: {
        listActiveFilter(state) {
            return Object.keys(state.listFilter)
                .filter((key) => state.listFilter[key] !== null)
                .reduce((resp, key) => ((resp[key] = state.listFilter[key]), resp), {});
        },
    },

    actions: {
        GET_LIST: async ({ rootState, rootGetters, state, getters, commit }, filters) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                const path = `/api/partner-pages/entities`;

                const resp = await $axios.get(path, {
                    params: filters || getters.listActiveFilter,
                });

                commit('SET_LIST', resp.list);
                commit('SET_PAGINATION', resp.pagination);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        GET_ITEM: async ({ state, getters, rootGetters, commit }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: true });

                const path = `/api/partner-pages/entities/${id}`;

                const resp = await $axios.get(path);

                commit('SET_ITEM', resp.single);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: false });
            }
        },

        SAVE_ITEM: async ({ rootState, rootGetters, commit, dispatch }, formData) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

                const path = `/api/partner-pages/entities`;

                const resp = await $axios.post(path, formData);

                dispatch('GET_LIST');

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
            }
        },

        DELETE_ITEM: async ({ commit, rootGetters, dispatch }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: true });

                const path = `/api/partner-pages/entities/${id}`;

                const resp = await $axios.delete(path);

                dispatch('GET_LIST');

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: false });
            }
        },
    },

    mutations: {
        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },

        SET_LIST(state, list) {
            state.list = list;
        },

        SET_PAGINATION(state, pagination) {
            state.listPagination = pagination;
        },

        SET_ITEM(state, item) {
            state.item = item;
        },

        UPDATE_LIST_FILTER(state, newFilterlist) {
            Object.entries(newFilterlist).forEach(([key, value]) => Vue.set(state.listFilter, key, value));
            Vue.set(state.listFilter, 'page', newFilterlist.page || 1);
        },

        RESET_STORE(state) {
            state.listFilter = { ...DEFAULT_FILTERS };
        },

        RESET_LIST(state) {
            state.list = [];
        },

        RESET_ITEM(state) {
            state.item = {};
        },
    },
};
