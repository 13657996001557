<template>
    <div
        class="selector selector--input"
        :class="{
            'has-focus': hasActive || hasPseudoActive,
            'has-full': hasFull,
            'has-error': errors.has(name) || hasMaxLengthError,
            'has-loading': loader,
            'druk-has-hint': hint && !hint.isSide,
            'druk-has-side-hint': hint && hint.isSide,
        }">
        <div v-if="label" class="selector__label" :class="{ [`druk-l-surface-${surface}`]: surface }" @click="onToggle">
            <span>
                <label>{{ label }}</label>
                <template v-if="isRequired">*</template>
            </span>

            <druk-hint v-if="hint && !hint.isSide && !hint.isHidden" :tooltip="tooltip" :icon="icon" :isInline="true" />
        </div>

        <div class="selector__main" :class="{ [`druk-l-surface-${surface}`]: surface }">
            <div class="selector__beforeText" v-if="beforeText" @click="onToggle">
                {{ beforeText }}
            </div>

            <div v-if="leadIcon" class="selector__lead">
                <druk-icon :name="leadIcon" :color="hasActive || hasPseudoActive ? 'primary' : 'on-surface-variant'" />
            </div>

            <template>
                <input
                    :value="value"
                    class="selector__area"
                    :class="{
                        'has-password': type === TYPE_PASSWORD,
                        'has-before': beforeText || leadIcon,
                        'has-after': afterText,
                        'druk-has-reduced-width': hasReducedWidth,
                    }"
                    ref="area"
                    step="any"
                    :data-vv-name="name"
                    :data-vv-as="validatorLabel || label"
                    v-validate="rules || ''"
                    :key="name"
                    :placeholder="placeholder && typeof placeholder === 'string' ? placeholder : null"
                    :type="type || 'text'"
                    :autocomplete="autocomplete || 'off'"
                    :readonly="readonly"
                    @focus="onFocus"
                    @blur="onBlur"
                    @keyup.enter="$emit('enter')"
                    @keyup="$emit('keyup')"
                    @input="$emit('input', $event.target.value)" />
            </template>

            <!-- <div v-if="placeholder && !hasFull" class="selector__placeholder">
				<span>{{placeholder}}</span>
			</div> -->

            <div v-if="afterText" class="selector__afterText" @click="onToggle">
                {{ afterText }}
            </div>

            <div
                class="selector__nav"
                v-if="isFilterType && !hasFull && !trailIcon"
                :class="{ 'has-active': hasFull || hasActive || hasPseudoActive }"
                @click="onToggle">
                <font-awesome-icon icon="fa-regular fa-magnifying-glass" />
            </div>

            <div v-if="trailIcon && (!hasFull || !isFilterType)" class="selector__trail">
                <druk-icon :name="trailIcon" :color="hasActive || hasPseudoActive ? 'primary' : 'on-surface-variant'" />
            </div>

            <div class="selector__clear" v-if="isFilterType && hasFull" @click="onClear">
                <font-awesome-icon icon="fa-regular fa-xmark" />
            </div>

            <div
                v-if="buttonIcon"
                class="selector__option"
                :class="{
                    'druk-is-disabled': !hasFull && !canSaveEmpty,
                    'has-animate': !staticButton,
                }"
                @click="$emit('submit')">
                <druk-icon :name="buttonIcon" :color="!hasFull && !canSaveEmpty ? 'on-surface' : 'on-surface-variant'" />
            </div>

            <div
                v-if="hasShowLength"
                class="selector__label selector__label--bottom"
                :class="{ [`druk-l-surface-${surface}`]: surface }">
                {{ $t('common.symbols') }}: {{ value ? value.length : '0' }}<template v-if="maxLength">/{{ maxLength }}</template>
            </div>
        </div>

        <druk-hint v-if="hint && hint.isSide && !hint.isHidden" class="selector__hint" :tooltip="tooltip" :icon="icon" />

        <div v-if="errors.has(name)" class="selector__error">
            <span :class="{ [`druk-l-surface-${surface}`]: surface }">{{ errorText || errors.first(name) }}</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'form-input',

        inject: ['$validator'],

        props: {
            value: {
                required: true,
            },
            name: {
                type: String,
                required: true,
            },

            hint: [String, Object],
            mask: String,
            placeholder: [String, Object],
            type: String,
            label: String,
            validatorLabel: String,
            rules: [String, Object],
            autocomplete: String,
            errorText: String,
            buttonIcon: String,
            defaultCount: [Number, String],
            maxLength: [Number, String],
            leadIcon: String,
            trailIcon: String,

            loader: Boolean,
            canSaveEmpty: Boolean,
            staticButton: Boolean,
            isFilterType: Boolean,
            hasShowLength: Boolean,
            readonly: Boolean,
            hasPseudoActive: Boolean,

            //
            surface: {
                type: String,
                default: 'tint-pale',
            },
        },

        data() {
            return {
                TYPE_PASSWORD: 'password',
                NUMBER_RULES: ['decimal', 'max_value', 'min_value'],

                count: this.defaultCount,

                hasActive: false,
            };
        },

        created() {
            if (!!this.value) this.onStartValidation();
        },

        watch: {
            defaultCount() {
                this.count = this.defaultCount;
            },

            loader() {
                this.onRemoveFocus();
            },

            hasPseudoActive(value) {
                this.hasActive = value;
            },
        },

        computed: {
            beforeText() {
                return typeof this.placeholder === 'object' ? this.placeholder.before : null;
            },

            afterText() {
                return typeof this.placeholder === 'object' ? this.placeholder.after : null;
            },

            tooltip() {
                if (!this.hint) return null;

                return {
                    icon: this.hint.icon?.name ? this.hint.icon : { name: this.hint.icon || 'circle-info' },
                    text: this.hint.text || this.hint,
                    from: this.hint.from || 'top',
                    maxWidth: this.hint.maxWidth,
                    isNotCollapse: this.hint.isNotCollapse,
                };
            },

            icon() {
                return {
                    name: this.hint.icon?.name || this.hint.icon || 'circle-info',
                    color: this.hint.icon?.color || 'primary',
                    size: this.hint.icon?.size || 'xs',
                };
            },

            hasFull() {
                return this.value !== null && this.value !== '' && this.value !== undefined;
            },

            isRequired() {
                if (this.rules && (typeof this.rules === 'string' ? this.rules.indexOf('required') !== -1 : this.rules.required))
                    return true;

                return false;
            },

            hasMaxLengthError() {
                return this.maxLength && this.value?.length > this.maxLength;
            },

            hasReducedWidth() {
                return (this.isFilterType && !this.hasFull) || this.buttonIcon || this.beforeText;
            },
        },

        methods: {
            onToggle() {
                this.$refs.area.focus();
            },

            onClear() {
                if (!this.hasFull) return;
                this.readonly ? this.$emit('clear') : this.$emit('input', null);
            },

            onFocus() {
                this.hasActive = true;
                this.$emit('onFocus', this.hasActive);
            },

            onBlur() {
                this.hasActive = false;
                this.$emit('onFocus', this.hasActive);
            },

            onRemoveFocus() {
                this.hasActive = false;
                this.$refs.area.blur();
            },

            onStartValidation() {
                let timer = setTimeout(() => {
                    clearTimeout(timer);
                    this.$validator.validate(this.name);
                }, 200);
            },
        },
    };
</script>

<style lang="scss" scoped></style>
