export default {
    state: {
        PATTERN_MAP: {
            index: [
                {
                    key: 'promo',
                    content: [['img'], ['title', 'subtitle', 'button']], // @Explanation - Each content block is an array that contains internal data divided into columns
                    cols: 2,
                },

                {
                    key: 'modules',
                    content: [['title', 'rich', 'link'], ['img']],
                    cols: 2,
                    isDynamic: true,
                    isGun: true,
                },

                {
                    key: 'sales',
                    content: [['title', 'subtitle', 'button', 'link']],
                },

                {
                    key: 'digitization',
                    content: [['title', 'subtitle']],
                },

                {
                    key: 'digitizationCards',
                    content: [['icon', 'title', 'subtitle', 'text', 'color']],
                    cols: 3,
                    isDynamic: true,
                },

                {
                    key: 'digitizationMainCard',
                    content: [['title', 'text', 'link']],
                },

                {
                    key: 'founder',
                    content: [['text', 'name'], ['img']],
                    cols: 2,
                },

                {
                    key: 'map',
                    content: [['title', 'countries', 'text']],
                },

                {
                    key: 'reviews',
                    content: [['title']],
                },

                {
                    key: 'reviewsCards',
                    content: [['img', 'name', 'text', 'color']],
                    cols: 3,
                    isDynamic: true,
                },
            ],

            features: [
                {
                    key: 'promo',
                    content: [['title']],
                    isDynamic: true,
                },

                {
                    key: 'promoContent',
                    content: [['subtitle', 'button']],
                },

                {
                    key: 'featuresCards',
                    content: [['icon', 'title', 'subtitle', 'text', 'link', 'color']],
                    cols: 3,
                    isDynamic: true,
                },
            ],

            about: [
                {
                    key: 'promo',
                    content: [['title', 'subtitle', 'text', 'img']],
                },

                {
                    key: 'platform',
                    content: [['title', 'subtitle']],
                },

                {
                    key: 'platformCards',
                    content: [['title', 'text']],
                    cols: 3,
                    isDynamic: true,
                },

                {
                    key: 'benefits',
                    content: [['title']],
                },

                {
                    key: 'benefitsCards',
                    content: [['icon', 'title', 'subtitle', 'color']],
                    cols: 3,
                    isDynamic: true,
                },
            ],

            pricing: [
                {
                    key: 'promo',
                    content: [['title', 'subtitle', 'toggle']],
                },

                {
                    key: 'pricingPlans',
                    content: [
                        [
                            'title',
                            'pricePerDay',
                            'pricePerMonth',
                            'yearlyPricePerDay',
                            'yearlyPricePerMonth',
                            'button',
                            'employees',
                            'products',
                            'equipment',
                            'benefits',
                            'color',
                            'isMostPopular',
                        ],
                    ],
                    cols: 4,
                    isDynamic: true,
                },

                {
                    key: 'pricingPrompts',
                    content: [['prompt']],
                    isDynamic: true,
                },

                {
                    key: 'pricingExtensions',
                    content: [['title', 'subtitle']],
                    cols: 3,
                    isDynamic: true,
                },

                {
                    key: 'server',
                    content: [['title'], ['subtitle'], ['rich'], ['img'], ['button']],
                    cols: {
                        2: 2,
                        3: 2,
                    },
                },

                {
                    key: 'integration',
                    content: [['title', 'subtitle']],
                },

                {
                    key: 'integrationCards',
                    content: [['icon', 'title', 'subtitle', 'color']],
                    cols: 3,
                    isDynamic: true,
                },

                {
                    key: 'integrationFooter',
                    content: [['button']],
                },

                {
                    key: 'questions',
                    content: [['title']],
                },

                {
                    key: 'questionsCards',
                    content: [['title', 'text']],
                    isDynamic: true,
                },
            ],

            demo: [
                {
                    key: 'promo',
                    content: [['title', 'subtitle']],
                },
            ],

            contact: [
                {
                    key: 'promo',
                    content: [['title']],
                },
            ],

            'privacy-policy': [
                {
                    key: 'promo',
                    content: [['title', 'updatedAt', 'subtitle']],
                },

                {
                    key: 'list',
                    content: [['title', 'rich']],
                    isDynamic: true,
                },

                {
                    key: 'footer',
                    content: [['title']],
                },
            ],

            terms: [
                {
                    key: 'promo',
                    content: [['title']],
                },

                {
                    key: 'list',
                    content: [['title', 'rich']],
                    isDynamic: true,
                },
            ],

            guide: [
                {
                    key: 'promo',
                    content: [['title', 'subtitle']],
                },
            ],
        },

        FEATURE_PAGE_PATTERN: [
            {
                key: 'promo',
                content: [
                    ['title', 'subtitle', 'button'],
                    ['optionTop', 'optionMid', 'optionBot'],
                ],
                cols: 2,
            },

            {
                key: 'reasons',
                content: [['title']],
            },

            {
                key: 'reasonsList',
                content: [['text']],
                cols: 2,
                isDynamic: true,
            },

            {
                key: 'article',
                content: [['title']],
            },

            {
                key: 'articleBlocks',
                content: [['title', 'text'], ['img']],
                cols: 2,
                isDynamic: true,
                isGun: true,
            },

            {
                key: 'benefits',
                content: [['title']],
            },

            {
                key: 'benefitsOptions',
                content: [['title', 'text']],
                isDynamic: true,
            },

            {
                key: 'benefitsFooter',
                content: [['button']],
            },

            {
                key: 'recs',
                content: [['title']],
            },

            {
                key: 'recsCards',
                content: [['icon', 'title', 'link', 'color']],
                cols: 3,
                isDynamic: true,
            },
        ],

        FEATURE_SLUGS_MAP: [
            'print-house-crm-orders',
            'print-house-crm-clients',
            'task-manager',
            'print-house-web2print',
            'manufacturing-calculation',
            'manage-products-and-catalogs',
            'storage',
            'konica-minolta-application',
        ],

        COMPONENT_MAP: {
            title: 'form-input',
            subtitle: 'form-input',
            img: 'form-img-loader',
            button: 'form-input',
            text: 'form-textarea',
            rich: 'form-editor',
            link: 'form-input',
            color: 'form-color-map-picker',
            icon: 'form-input',
            name: 'form-input',
            countries: 'druk-form-select-countries',
            toggle: 'form-input',
            pricePerDay: 'form-input',
            pricePerMonth: 'form-input',
            yearlyPricePerDay: 'form-input',
            yearlyPricePerMonth: 'form-input',
            employees: 'form-input',
            products: 'form-input',
            equipment: 'form-input',
            benefits: 'form-editor',
            isMostPopular: 'form-switch',
            prompt: 'form-textarea',
            optionTop: 'form-input',
            optionMid: 'form-input',
            optionBot: 'form-input',
            updatedAt: 'form-input',
        },

        PROMPT_MAP: ['icon', 'link', 'benefits', 'employees', 'products', 'equipment'],

        KEY_COLOR: 'color',

        COLORS_MAP: ['#c60f23', '#f1172f', '#9e0c1c', '#14a5ee', '#0385ce', '#b956b8', '#4906a7', '#35078e'],
    },

    getters: {
        currentPagePattern(state, getters, rootState) {
            const alias = rootState.partnerPages.item?.alias;
            const pattern = state.FEATURE_SLUGS_MAP.includes(alias) ? state.FEATURE_PAGE_PATTERN : state.PATTERN_MAP[alias];

            return pattern?.map((item) => ({
                ...item,
                hasWholeContent: item.isDynamic && item.content.length === 1,
                hasSplitContent: item.isDynamic && item.content.length > 1,
            }));
        },
    },
};
