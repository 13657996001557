import Vue from 'vue';

const DEFAULT_FILTERS = {
    title: null,
    type: null,
    state: null,
    amount_gear_teeth: null,
    is_deleted: 0,
    page: null,
    limit: 30,
    sort: 'new',
    direction: 'desc',
};

export default {
    namespaced: true,

    state: {
        INACTIVE_FILTERING_KEYS: ['target'],

        TYPE_FLAT: 'flat',
        TYPE_ROTARY: 'rotary',

        STATE_NEW: 'new',
        STATE_WORKING: 'working',
        STATE_REPLACEMENT: 'replacement',

        STATE_COLOR_MAP: {
            new: 'topaz',
            working: 'dark-aqua',
            replacement: 'gamboge',
        },

        list: [],
        listPagination: {},
        listFilter: { ...DEFAULT_FILTERS },
        listFilters: {},

        types: [],
        states: [],

        item: {},
        activeItem: null,

        loadingGetList: false,
        loadingGetTypes: false,
        loadingGetStates: false,
        loadingGetItem: false,
        loadingSaveItem: false,
        loadingChangeItem: false,
        loadingDeleteItem: false,
    },

    getters: {
        listActiveFilter(state) {
            return Object.keys(state.listFilter)
                .filter((key) => state.listFilter[key] !== null)
                .reduce((resp, key) => ((resp[key] = state.listFilter[key]), resp), {});
        },

        isFilterNotEmpty(state, getters, rootState) {
            return !!Object.keys(getters.listActiveFilter).filter(
                (key) => !rootState.constant.INACTIVE_FILTERING_KEYS.concat(state.INACTIVE_FILTERING_KEYS).includes(key),
            ).length;
        },
    },

    actions: {
        GET_LIST: async ({ state, rootGetters, getters, commit }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                const path = rootGetters.hasTypographyAccess
                    ? `/api/typographies/${rootGetters[`currentTypography`].id}/knives`
                    : `/api/knives`;

                const resp = await $axios.get(path, {
                    params: getters.listActiveFilter,
                });

                commit('SET_LIST', resp.list);
                commit('SET_PAGINATION', resp.pagination);
                commit('SET_FILTERS', resp.filters);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        GET_TYPES: async ({ state, rootGetters, getters, commit }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetTypes', status: true });

                const path = rootGetters.hasTypographyAccess
                    ? `/api/typographies/${rootGetters[`currentTypography`].id}/knives/type`
                    : `/api/knives/type`;

                const resp = await $axios.get(path);

                commit('SET_TYPES', resp.list);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetTypes', status: false });
            }
        },

        GET_STATES: async ({ state, rootGetters, getters, commit }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetStates', status: true });

                const path = rootGetters.hasTypographyAccess
                    ? `/api/typographies/${rootGetters[`currentTypography`].id}/knives/state`
                    : `/api/knives/state`;

                const resp = await $axios.get(path);

                commit('SET_STATES', resp.list);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetStates', status: false });
            }
        },

        GET_ITEM: async ({ state, getters, rootGetters, commit }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: true });

                const path = rootGetters.hasTypographyAccess
                    ? `/api/typographies/${rootGetters[`currentTypography`].id}/knives/${id}`
                    : `/api/knives/${id}`;

                const resp = await $axios.get(path);

                commit('SET_ITEM', resp.single);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: false });
            }
        },

        SAVE_ITEM: async ({ state, rootGetters, commit, dispatch }, { formData, without_fetch }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

                const path = rootGetters.hasTypographyAccess
                    ? `/api/typographies/${rootGetters[`currentTypography`].id}/knives`
                    : `/api/knives`;

                const resp = await $axios.post(path, formData);

                if (!without_fetch) dispatch('GET_ITEM', resp.single.id);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
            }
        },

        CHANGE_ITEM: async ({ state, rootGetters, commit, dispatch }, { id, formData, without_fetch }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingChangeItem', status: true });

                const path = rootGetters.hasTypographyAccess
                    ? `/api/typographies/${rootGetters[`currentTypography`].id}/knives/${id}/change`
                    : `/api/knives/${id}/change`;

                const resp = await $axios.post(path, formData);

                if (!without_fetch) dispatch('GET_LIST');

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingChangeItem', status: false });
            }
        },

        DELETE_ITEM: async ({ commit, rootGetters, dispatch }, { id, without_fetch }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: true });

                const path = rootGetters.hasTypographyAccess
                    ? `/api/typographies/${rootGetters[`currentTypography`].id}/knives/${id}`
                    : `/api/knives/${id}`;

                const resp = await $axios.delete(path);

                if (!without_fetch) dispatch('GET_LIST');

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: false });
            }
        },
    },

    mutations: {
        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },

        SET_LIST(state, list) {
            state.list = list;
        },

        SET_LIST_ITEM_PROP(state, { index, key, value }) {
            Vue.set(state.list[index], key, value);
        },

        SET_PAGINATION(state, pagination) {
            state.listPagination = pagination;
        },

        SET_FILTERS(state, filters) {
            state.listFilters = filters;
        },

        SET_TYPES(state, types) {
            state.types = types;
        },

        SET_STATES(state, states) {
            state.states = states;
        },

        SET_ITEM(state, item) {
            state.item = item;
        },

        SET_ACTIVE_ITEM(state, activeItem) {
            state.activeItem = activeItem;
        },

        SET_ACTIVE_ITEM_PROP(state, { key, value }) {
            if (state.activeItem) Vue.set(state.activeItem, key, value);
        },

        UPDATE_LIST_FILTER(state, newFilterlist) {
            Object.entries(newFilterlist).forEach(([key, value]) => Vue.set(state.listFilter, key, value));
            Vue.set(state.listFilter, 'page', newFilterlist.page || 1);
        },

        RESET_LIST(state) {
            state.list = [];
        },

        RESET_ITEM(state) {
            state.item = {};
        },

        RESET_ACTIVE_ITEM(state) {
            state.activeItem = null;
        },

        RESET_STORE(state) {
            state.listFilter = { ...DEFAULT_FILTERS };
        },
    },
};
