export default {
    alias: {
        uk: (field) => `Поле ${field} може містити тільки літери латинського алфавіту, цифри, знак підкреслення або крапку`,
        en: (field) => `The field ${field} can contains only latin letters, numbers, dashes or dots`,
        pl: (field) => `Pole ${field} może zawierać tylko litery alfabetu łacińskiego, cyfry, podkreślenie lub kropkę`,
        ru: (field) => `Поле ${field} может содержать только буквы латинского алфавита, цифры, знак подчёркивания или точки`,
    },

    alphabets: {
        uk: (field, args) => {
            return `Поле ${field} може містити тільки літери ${args.replace(
                /, ([^,]*)$/,
                ` та $1`,
            )} абетки(-ок), одне тире, пробіл та апостроф. Мінімальна довжина поля - 2 знаки`;
        },

        en: (field, args) => {
            return `The field ${field} can only contain letters of the ${args.replace(
                /, ([^,]*)$/,
                ` and $1`,
            )} alphabet(s) and one dash, space and an apostrophe. The minimum field length is 2 characters`;
        },

        pl: (field, args) => {
            return `Pole ${field} może zawierać tylko litery ${args.replace(
                /, ([^,]*)$/,
                ` lub $1`,
            )} alfabety oraz jeden myślnik, spacja i apostrof. Minimalna długość pola to 2 znaki`;
        },

        ru: (field, args) => {
            return `Поле ${field} может содержать только буквы ${args.replace(
                /, ([^,]*)$/,
                ` и $1`,
            )} алфавита(-ов), одно тире, пробел и апостроф. Минимальная длина поля – 2 знака`;
        },
    },

    decimal: {
        uk: (arg) => `Максимальна кількість цифр після коми – ${arg}`,
        en: (arg) => `The maximum number of digits after the comma – ${arg}`,
        pl: (arg) => `Maksymalna liczba cyfr po przecinku – ${arg}`,
        ru: (arg) => `Максимальное количество цифр после запятой – ${arg}`,
    },

    float: {
        uk: (field) => `Поле ${field} може містити тільки цілі числа та десяткові дроби`,
        en: (field) => `The filed ${field} can contain only even numbers`,
        pl: (field) => `Pole ${field} może zawierać całkowite liczb naturalnych i ułamków dziesiętnych`,
        ru: (field) => `Поле ${field} может содержать только целые числа и десятичные дроби`,
    },

    even_numbers: {
        uk: (field) => `Поле ${field} може містити тільки парні числа`,
        en: (field) => `The filed ${field} can contain only even numbers`,
        pl: (field) => `Pole ${field} może zawierać tylko liczby parzyste`,
        ru: (field) => `Поле ${field} может содержать только четные числа`,
    },

    int: {
        uk: (field) => `Поле ${field} може містити тільки цілі числа`,
        en: (field) => `The field ${field} can only contain integers`,
        pl: (field) => `Pole ${field} może zawierać tylko liczby całkowite`,
        ru: (field) => `Поле ${field} может содержать только целые числа`,
    },

    link: {
        uk: (field) => `Поле ${field} має бути посиланням`,
        en: (field) => `Field ${field} must be a link`,
        pl: (field) => `Pole ${field} musi być linkiem`,
        ru: (field) => `Поле ${field} должно быть ссылкой`,
    },

    max_value: {
        uk: (arg) => `Значення має бути ${arg} або менше`,
        en: (arg) => `The value must be ${arg} or less`,
        pl: (arg) => `Wymagana ilość to ${arg} lub mniej`,
        ru: (arg) => `Значение должно быть ${arg} или меньше`,
    },

    min_value: {
        uk: (arg) => `Значення має бути ${arg} або більше`,
        en: (arg) => `The value must be ${arg} or more`,
        pl: (arg) => `Wymagana ilość to ${arg} lub więcej`,
        ru: (arg) => `Значение должно быть ${arg} или более`,
    },

    not_in_array: {
        uk: (field, args) => `Поле "${field}" не може містити наступні значення: ${args.join(', ')}`,
        en: (field, args) => `The field "${field}" cannot contain the following values: ${args.join(', ')}`,
        pl: (field, args) => `Pole "${field}" nie może zawierać następujących wartości: ${args.join(', ')}`,
        ru: (field, args) => `Поле "${field}" не может содержать следующие значения: ${args.join(', ')}`,
    },

    phone_number: {
        uk: () => `Ви ввели невалідний номер телефону`,
        en: () => `Is not a valid phone number`,
        pl: () => `Podałeś nieprawidłowy numer telefonu`,
        ru: () => `Вы ввели невалидный номер телефона`,
    },

    positive_float: {
        uk: (field) => `Поле ${field} може містити тільки натуральні числа та додатні десяткові дроби`,
        en: (field) => `The filed ${field} can contain only natural numbers and positive decimal fractions`,
        pl: (field) => `Pole ${field} może zawierać wyłącznie liczb naturalnych i dodatnich ułamków dziesiętnych`,
        ru: (field) => `Поле ${field} может содержать только натуральные числа и десятичные дроби`,
    },
};
