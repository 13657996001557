import Vue from 'vue';

const DEFAULT_FILTERS = {
    page: 1,
    limit: 30,
    type: 'standard',
};

export default {
    namespaced: true,

    state: {
        SETTINGS_TYPES: [
            {
                translation_alias: 'common.settings_standard',
                value: 'standard',
            },
            {
                translation_alias: 'product.title.singl',
                value: 'product',
            },
            {
                translation_alias: 'common.settings_label',
                value: 'label',
            },
        ],

        STANDARD_SETTINGS_KEY: 'standard',
        PRODUCT_SETTINGS_KEY: 'product',
        LABEL_SETTINGS_KEY: 'label',

        AUDIENCE_PRIORITY_KEY: 'audience_priority',

        DEFAULT_VALUE_KEY: 'is_add_default_value',

        INTEGER_TYPE_ITEMS: ['default_angle_corner_radius', 'min_angle_corner_radius', 'max_angle_corner_radius'],
        BOOLEAN_TYPE_ITEMS: ['is_add_default_value'],
        RADIO_TYPE_ITEMS: ['audience_priority'],

        RADIO_ITEMS_OPTIONS: {
            audience_priority: [
                {
                    value: 'client',
                    translation_alias: 'settings.audience_priority.client',
                },
                {
                    value: 'company',
                    translation_alias: 'settings.audience_priority.company',
                },
            ],
        },

        PROMPTS_MAP: {
            audience_priority: 'settings.audience_priority.prompt',
        },

        CLIENT_AUDIENCE_PRIORITY: 'client',
        COMPANY_AUDIENCE_PRIORITY: 'company',

        list: [],
        listPagination: {},
        listFilter: { ...DEFAULT_FILTERS },

        productTypeList: [],
        labelTypeList: [],

        item: {},

        loadingGetList: false,
        loadingGetLabelTypeList: false,
        loadingGetProductTypeList: false,
        loadingGetItem: false,
        loadingSaveItem: false,
        loadingDeleteItem: false,
    },

    getters: {
        listActiveFilter(state) {
            return Object.keys(state.listFilter)
                .filter((key) => state.listFilter[key] !== null)
                .reduce((resp, key) => ((resp[key] = state.listFilter[key]), resp), {});
        },

        formattedList(state) {
            return Object.fromEntries(state.list.map((item) => [item.alias, item.value]));
        },

        formattedLabelFormatSettings(state) {
            return Object.fromEntries(state.labelTypeList.map((item) => [item.alias, item.value]));
        },

        formattedProductSettings(state) {
            const parameters = $fn.paramsParser(state.productTypeList);
            const { default_height, default_width, max_height, max_width, min_height, min_width, ...resp } = parameters;

            return {
                label: resp,
                format: {
                    default_height: default_height,
                    default_width: default_width,
                    max_height: max_height,
                    max_width: max_width,
                    min_height: min_height,
                    min_width: min_width,
                },
            };
        },

        productLabelSettings(state, getters) {
            return getters.formattedProductSettings.label;
        },

        productFormatSettings(state, getters) {
            return getters.formattedProductSettings.format;
        },

        isFilterNotEmpty(state, getters, rootState) {
            return !!Object.keys(getters.listActiveFilter).filter(
                (key) => !rootState.constant.INACTIVE_FILTERING_KEYS.concat(state.INACTIVE_FILTERING_KEYS).includes(key),
            ).length;
        },
    },

    mutations: {
        SET_LIST(state, list) {
            state.list = list;
        },

        SET_PAGINATION(state, pagination) {
            state.listPagination = pagination;
        },

        SET_PRODUCT_TYPE_LIST(state, list) {
            state.productTypeList = list;
        },

        SET_LABEL_TYPE_LIST(state, list) {
            state.labelTypeList = list;
        },

        SET_ITEM(state, item) {
            state.item = item;
        },

        UPDATE_LIST_FILTER(state, newFilterlist) {
            Object.entries(newFilterlist).forEach(([key, value]) => Vue.set(state.listFilter, key, value));
            Vue.set(state.listFilter, 'page', newFilterlist.page || 1);
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },

        RESET_STORE(state) {
            state.listFilter = { ...DEFAULT_FILTERS };
        },
    },

    actions: {
        GET_LIST: async ({ state, rootGetters, getters, commit }, filters) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let path = rootGetters.hasTypographyAccess
                    ? `/api/typographies/${rootGetters.currentTypography.id}/settings`
                    : `/api/settings`;

                let params = filters || getters.listActiveFilter;

                let resp = await $axios.get(path, { params });

                commit('SET_LIST', resp.list);
                commit('SET_PAGINATION', resp.pagination);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        GET_LABEL_TYPE_LIST: async ({ state, rootGetters, getters, commit }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetLabelTypeList', status: true });

                let path = rootGetters.hasTypographyAccess
                    ? `/api/typographies/${rootGetters.currentTypography.id}/settings`
                    : `/api/settings`;

                let params = { type: state.LABEL_SETTINGS_KEY };

                let resp = await $axios.get(path, { params });

                commit('SET_LABEL_TYPE_LIST', resp.list);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetLabelTypeList', status: false });
            }
        },

        GET_PRODUCT_TYPE_LIST: async ({ state, rootGetters, getters, commit }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetProductTypeList', status: true });

                let path = rootGetters.hasTypographyAccess
                    ? `/api/typographies/${rootGetters.currentTypography.id}/settings`
                    : `/api/settings`;

                let params = { type: state.PRODUCT_SETTINGS_KEY };

                let resp = await $axios.get(path, { params });

                commit('SET_PRODUCT_TYPE_LIST', resp.list);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetProductTypeList', status: false });
            }
        },

        GET_ITEM: async ({ state, rootGetters, getters, commit }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: true });

                let path = rootGetters.hasTypographyAccess
                    ? `/api/typographies/${rootGetters.currentTypography.id}/settings/${id}`
                    : `/api/settings${id}`;

                let resp = await $axios.get(path);

                commit('SET_ITEM', resp.list);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: false });
            }
        },

        SAVE_ITEM: async ({ commit, rootGetters, dispatch }, formData) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

                let path = rootGetters.hasTypographyAccess
                    ? `/api/typographies/${rootGetters.currentTypography.id}/settings`
                    : `/api/settings`;

                let resp = await $axios.post(path, formData);
                dispatch('GET_LIST');

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
            }
        },

        DELETE_ITEM: async ({ commit, rootGetters, dispatch }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: true });

                let path = rootGetters.hasTypographyAccess
                    ? `/api/typographies/${rootGetters.currentTypography.id}/settings/${id}`
                    : `/api/settings${id}`;

                let resp = await $axios.delete(path);
                dispatch('GET_LIST');

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: false });
            }
        },
    },
};
