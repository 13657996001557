<template>
    <div class="druk-c-clr-map-pckr">
        <div class="druk-c-clr-map-pckr__wrapper" :class="{ [`druk-l-surface-${surface}`]: surface }">
            <div class="druk-c-clr-map-pckr__header">
                <div class="druk-c-clr-map-pckr__label" :class="{ [`druk-l-surface-${surface}`]: surface }">{{ label }}</div>
            </div>

            <div class="druk-c-clr-map-pckr__body">
                <div class="druk-c-clr-map-pckr__colors">
                    <compact v-model="model" :palette="colors" @input="$emit('input', model.hex)" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Compact } from 'vue-color';

    export default {
        name: 'form-color-map-picker',

        components: {
            Compact,
        },

        props: {
            // @Explanation: Model,
            value: String,

            // @Explanation: Options,
            colors: Array,

            // @Explanation: Component building blocks
            label: String,

            // @Explanation: Validation
            name: String,

            // @Explanation: Styling
            surface: {
                type: String,
                default: 'tint-pale',
            },
            surfaceVariant: {
                type: String,
                default: 'tint-bright',
            },
        },

        data() {
            return {
                model: this.value || [...this.colors].shift(),
            };
        },
    };
</script>

<style lang="scss" scoped>
    .druk-c-clr-map-pckr {
        cursor: pointer;
        &:hover &__wrapper {
            border-color: var(--druk-on-surface);
            transition: var(--druk-duration-short-4) var(--druk-easing-emphasized-decelerate);
        }
        &:hover &__label {
            color: var(--druk-on-surface);
            transition: var(--druk-duration-short-4) var(--druk-easing-emphasized-decelerate);
        }
        &__wrapper {
            position: relative;
            padding: var(--druk-gap-s) var(--druk-gap-s-a) var(--druk-gap-xs-a);
            border: 1px solid var(--druk-outline);
            border-radius: 4px;
            transition: var(--druk-duration-short-4) var(--druk-easing-emphasized-accelerate);
        }
        &__header {
            position: absolute;
            top: 0;
            left: var(--druk-gap-s-a);
            transform: translate(0, -50%);
        }
        &__label {
            height: 16px;
            padding: 0px 3px;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.4px;
            font-weight: 400;
            color: var(--druk-on-surface-variant);
            transition: var(--druk-duration-short-4) var(--druk-easing-emphasized-accelerate);
        }
    }

    .vc-compact {
        padding: 0;
        width: auto;
        box-shadow: initial;
        background-color: transparent;
        &::v-deep &-colors {
            display: flex;
            align-items: center;
            margin: 0 var(--druk-n-gap-step);
        }
        &::v-deep &-color-item {
            margin: 0;
            width: 24px;
            height: 24px;
            margin: 0 var(--druk-gap-step);
            transition: var(--druk-duration-short-3) var(--druk-duration-short-1) var(--druk-easing-emphasized-accelerate);
            &[aria-selected='true'] {
                width: 28px;
                height: 28px;
                transition: var(--druk-duration-short-1) var(--druk-easing-emphasized-decelerate);
            }
        }
        &::v-deep &-dot {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            border: 1px solid var(--druk-on-surface);
            border-radius: initial;
            background: transparent;
        }
    }
</style>
