import Vue from 'vue';

import groups from './groups';
import materials from './materials';
import equipments from './equipments';

const DEFAULT_FILTERS = {
    page: null,
    limit: 30,
    title: null,
};

export default {
    namespaced: true,

    modules: {
        groups,
        materials,
        equipments,
    },

    state: {
        ITEM_VARIETIES: [
            {
                value: 'manufacturing',
                key: 'common.manufacturing',
                title: null,
            },
            {
                value: 'prepress',
                key: 'common.pre-press',
                title: null,
            },
            {
                value: 'assembly',
                key: 'common.post-press',
                title: null,
            },
        ],

        ITEM_MATERIAL_USAGE_TYPES: [
            {
                value: 'own_material',
                key: 'process.usage_types.own_material',
                title: null,
                support: 'process.usage_types.own_material.support',
            },
            {
                value: 'other_process_material',
                key: 'process.usage_types.other_process_material',
                title: null,
                support: 'process.usage_types.other_process_material.support',
            },
            {
                value: 'dependent_material',
                key: 'process.usage_types.dependent_material',
                title: null,
                support: 'process.usage_types.dependent_material.support',
            },
        ],

        USAGE_TYPE_OWN_MATERIAL: 'own_material',
        USAGE_TYPE_OTHER_PROCESS_MATERIAL: 'other_process_material',
        USAGE_TYPE_DEPEND_MATERIAL: 'dependent_material',

        ITEM_COMMON_DATA: {
            title: null,
            group: null,

            variety: 'manufacturing',
            material_usage_type: 'own_material',

            manufacturing_time: null,
            makeready_time: null,
            min_material_amount: null,
            is_used_knife: false,

            equipments: [],
            equipment_restrictions: [],
            materials: [],
            materials_restrictions: [],

            formula: [],
            time_formula: [],
            weight_formula: [],
            material_formula: [],
            setup_material_formula: ['0'],
        },

        list: [],
        listPagination: {},
        listFilter: { ...DEFAULT_FILTERS },

        item: {},

        listWorks: [],

        formData: {},

        loadingGetList: false,
        loadingGetItem: false,
        loadingSaveItem: false,
        loadingDeleteItem: false,
    },

    getters: {
        formattedList(state) {
            return state.list
                .map((group) =>
                    group.operations.map((operation) => ({
                        id: operation.id,
                        title: operation.title,
                    })),
                )
                .flat();
        },

        listActiveFilter(state) {
            return Object.keys(state.listFilter)
                .filter((key) => state.listFilter[key] !== null)
                .reduce((resp, key) => ((resp[key] = state.listFilter[key]), resp), {});
        },

        listWorksByGroups(state) {
            return $fn.map(state.listWorks, (el) => {
                return {
                    title: el.title,
                    libs: el.operations,
                };
            });
        },

        listWorksTree(state) {
            let operationsList = $fn.map(state.listWorks, (el) => {
                return el.operations;
            });
            return operationsList.flat();
        },
    },

    mutations: {
        SET_LIST(state, list) {
            state.list = list;
        },

        SET_LIST_WORKS(state, list) {
            state.listWorks = list;
        },

        SET_ITEM(state, item) {
            state.item = item;
        },

        SET_PAGINATION(state, pagination) {
            state.listPagination = pagination;
        },

        SET_FILTER_LIST(state, { key, val }) {
            state.listFilter[key] = val;
        },

        SET_FORM_DATA(state, formData) {
            Vue.set(state, 'formData', formData);
        },

        SET_FORM_DATA_PROP(state, { key, value }) {
            Vue.set(state.formData, key, value);
        },

        SET_FORM_DATA_PROP_VALUE(state, { key, valueKey, value }) {
            Vue.set(state.formData[key], valueKey, value);
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },

        PUSH_TO_FORM_DATA(state, { key, value }) {
            state.formData[key].push(value);
        },

        SLICE_FROM_FORM_DATA(state, { index, key }) {
            state.formData[key] = state.formData[key].slice(0, index).concat(state.formData[key].slice(index + 1));
        },

        UPDATE_LIST_FILTER(state, newFilterlist) {
            Object.entries(newFilterlist).forEach(([key, value]) => Vue.set(state.listFilter, key, value));
            Vue.set(state.listFilter, 'page', newFilterlist.page || 1);
        },

        RESET_ITEM(state) {
            state.item = {};
        },

        RESET_FORM_DATA(state) {
            state.formData = {};
        },

        RESET_STORE(state) {
            state.listFilter = { ...DEFAULT_FILTERS };
        },
    },

    actions: {
        GET_LIST: async ({ state, rootGetters, getters, commit }, filters) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let resp = await $axios.get(`/api/typographies/${rootGetters.currentTypography.id}/operations/groups/tree`, {
                    params: filters || getters.listActiveFilter,
                });

                commit('SET_LIST', resp.list);
                commit('SET_PAGINATION', resp.pagination);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        GET_LIST_WORKS: async ({ state, rootGetters, getters, commit }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let resp = await $axios.get(`/api/typographies/${rootGetters.currentTypography.id}/operations/groups/tree`);

                commit('SET_LIST_WORKS', resp.list);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        GET_ITEM: async ({ state, rootGetters, getters, commit }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: true });

                let resp = await $axios.get(`/api/typographies/${rootGetters.currentTypography.id}/operations/${id}`);

                commit('SET_ITEM', resp.single);

                commit(
                    'works/materials/SET_LIST',
                    {
                        materials: resp.single.materials,
                        banlistMaterials: resp.single.materials_restrictions,
                    },
                    { root: true },
                );

                commit(
                    'works/equipments/SET_LIST',
                    {
                        equipments: resp.single.equipment,
                        banlistEquipments: resp.single.equipment_restrictions,
                    },
                    { root: true },
                );

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: false });
            }
        },

        SAVE_ITEM: async ({ state, rootGetters, commit, dispatch }, { formData, without_fetch }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

                let resp = await $axios.post(`/api/typographies/${rootGetters.currentTypography.id}/operations`, formData);

                if (!without_fetch) dispatch('GET_LIST');

                if (formData.id && Object.keys(state.item).length) dispatch('GET_ITEM', formData.id);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
            }
        },

        SAVE_ITEM_GROUP: async ({ state, rootGetters, commit, dispatch }, formData) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

                let resp = await $axios.post(`/api/typographies/${rootGetters.currentTypography.id}/operations/groups`, formData);

                dispatch('GET_LIST');

                if (formData.id && Object.keys(state.item).length) dispatch('GET_ITEM', formData.id);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
            }
        },

        DELETE_ITEM: async ({ commit, rootGetters, dispatch }, { id, without_fetch, without_noty }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: true });

                let path = `/api/typographies/${rootGetters.currentTypography.id}/operations/${id}`;

                let resp = await $axios.delete(path, {
                    headers: {
                        'Without-Noty': without_noty,
                    },
                });

                if (!without_fetch) dispatch('GET_LIST');

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: false });
            }
        },
    },
};
