<template>
    <div class="druk-c-form-s-cos">
        <div class="druk-c-form-s-cos__base">
            <form-select
                v-model="model"
                :options="options"
                :option_id="optionId"
                :option_label="optionLabel"
                :label="label"
                :rules="rules"
                :name="name"
                :surface="surface"
                :surfaceVariant="surfaceVariant"
                :multiple="multiple" />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'druk-form-select-countries',

        props: {
            // @Explanation: Model,
            value: [String, Array],

            // @Explanation: Options
            optionId: {
                type: String,
                default: 'code',
            },
            optionLabel: {
                type: String,
                default: 'title',
            },
            optionLocale: String,

            // @Explanation: Component building blocks
            label: String,

            // @Explanation: Multiselect API
            multiple: {
                type: Boolean,
                default: true,
            },

            // @Explanation: Validation
            rules: [String, Object, Array],
            name: String,

            // @Explanation: Styling
            surface: {
                type: String,
                default: 'tint-pale',
            },
            surfaceVariant: {
                type: String,
                default: 'tint-bright',
            },
        },

        data() {
            return {
                list: [
                    'AF',
                    'AL',
                    'AM',
                    'AT',
                    'AZ',
                    'BA',
                    'BD',
                    'BE',
                    'BG',
                    'BT',
                    'CH',
                    'CN',
                    'CY',
                    'CZ',
                    'DE',
                    'DK',
                    'EE',
                    'FI',
                    'FR',
                    'GB',
                    'GE',
                    'GR',
                    'HR',
                    'HU',
                    'IE',
                    'IL',
                    'IN',
                    'IQ',
                    'IR',
                    'IT',
                    'JO',
                    'KG',
                    'KW',
                    'KZ',
                    'LB',
                    'LT',
                    'LV',
                    'MD',
                    'ME',
                    'MK',
                    'MN',
                    'NL',
                    'NO',
                    'NP',
                    'PK',
                    'PL',
                    'RO',
                    'RS',
                    'SE',
                    'SI',
                    'SK',
                    'SY',
                    'TJ',
                    'TM',
                    'TR',
                    'UA',
                    'UZ',
                    'XK',
                ],

                ukList: new Intl.DisplayNames(['uk'], { type: 'region' }),
                enList: new Intl.DisplayNames(['en'], { type: 'region' }),
                plList: new Intl.DisplayNames(['pl'], { type: 'region' }),
                ruList: new Intl.DisplayNames(['ru'], { type: 'region' }),
            };
        },

        computed: {
            model: {
                get() {
                    return this.value?.filter((item) => this.list.includes(item.code)) || [];
                },
                set(value) {
                    this.$emit('input', value);
                },
            },

            options() {
                return this.formattedList
                    .map((item) => ({
                        code: item.code,
                        title: item.title_translations[this.locale],
                    }))
                    .sort((prev, next) => prev.title.localeCompare(next.title));
            },

            formattedList() {
                return this.list.map((code) => ({
                    code,
                    title_translations: {
                        uk: this.ukList.of(code),
                        en: this.enList.of(code),
                        pl: this.plList.of(code),
                        ru: this.ruList.of(code),
                    },
                }));
            },

            locale() {
                return this.optionLocale || this.$store.state.selectedLang;
            },
        },
    };
</script>

<style lang="scss" scoped></style>
