import Vue from 'vue';

import groups from './groups';
import variables from './variables';
import params from './params';
import options from './options';

const DEFAULT_FILTERS = {
    page: null,
    limit: 30,
    title: null,
};

const DEFAULT_ITEM_FILTERS = {
    format_id: null,
    page: null,
    limit: null,
};

export default {
    namespaced: true,

    modules: {
        groups,
        variables,
        params,
        options,
    },

    state: {
        ITEM_COMMON_DATA: {
            group: null,
            title: null,
        },

        ITEM_KIND_FORM_DATA: {
            profile: null,
            format: null,
            dimension: null,

            price: null,
            weight: null,
            count: 0,
            min_count: 1,

            pickedOptions: [],
            pickedVariables: [],

            options: [],
            variables: [],

            calc_type: 'formula',

            ranges: [],
            curve: null,
            formula: null,

            data: {
                optionsList: [],
                variablesList: [],
            },
        },

        list: [],
        listPagination: {},
        listFilter: { ...DEFAULT_FILTERS },

        item: {},
        itemFilter: { ...DEFAULT_ITEM_FILTERS },
        itemFilters: {},

        materialsList: [],

        formData: {},
        trFormData: {},

        loadingGetList: false,
        loadingGetItem: false,
        loadingGetItemTR: false,
        loadingGetItemFilters: false,
        loadingSaveItem: false,
        loadingSaveItemTR: false,
        loadingDeleteItem: false,
    },

    getters: {
        listActiveFilter(state) {
            return Object.keys(state.listFilter)
                .filter((key) => state.listFilter[key] !== null)
                .reduce((resp, key) => ((resp[key] = state.listFilter[key]), resp), {});
        },

        itemActiveFilter(state) {
            return Object.keys(state.itemFilter)
                .filter((key) => state.itemFilter[key] !== null)
                .reduce((resp, key) => ((resp[key] = state.itemFilter[key]), resp), {});
        },

        isItemFilterNotEmpty(state, getters, rootState) {
            return !!Object.keys(getters.itemActiveFilter).filter(
                (key) => !rootState.constant.INACTIVE_FILTERING_KEYS.concat(state.INACTIVE_FILTERING_KEYS).includes(key),
            ).length;
        },

        listMaterialsByGroups(state) {
            return $fn.map(state.list, (el) => {
                return {
                    title: el.title,
                    libs: el.materials,
                };
            });
        },
    },

    mutations: {
        SET_LIST(state, list) {
            state.list = list;
        },

        SET_PAGINATION(state, pagination) {
            state.listPagination = pagination;
        },

        SET_FILTER_LIST(state, { key, val }) {
            state.listFilter[key] = val;
        },

        SET_MATERIALS_LIST(state, list) {
            state.materialsList = list;
        },

        SET_ITEM(state, item) {
            state.item = item;
        },

        SET_ITEM_FILTERS(state, filters) {
            state.itemFilters = filters;
        },

        SET_FORM_DATA(state, formData) {
            state.formData = formData;
        },

        SET_FORM_DATA_PROP(state, { key, value }) {
            Vue.set(state.formData, key, value);
        },

        SET_TR_FORM_DATA(state, formData) {
            state.trFormData = formData;
        },

        SET_TR_FORM_DATA_PROP(state, { key, value }) {
            Vue.set(state.trFormData, key, value);
        },

        SET_TR_FORM_DATA_PROP_VALUE(state, { index, key, valueKey, value }) {
            Vue.set(state.trFormData[key][index], valueKey, value);
        },

        SET_TR_FORM_DATA_DATA_PROP(state, { key, value }) {
            Vue.set(state.trFormData.data, key, value);
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },

        ADD_CLONE_TR_TO_ITEM(state, item) {
            item.hasClone = true;
            state.item.list.push(item);
        },

        PUSH_TO_TR_FORM_DATA_PROP(state, { key, value }) {
            let map = state.trFormData[key].concat({ ...value });
            Vue.set(state.trFormData, key, map);
        },

        REPLACE_TR_FORM_DATA_PROP(state, { index, key, value }) {
            let map = state.trFormData[key];

            map[index] = { ...value };
            Vue.set(state.trFormData, key, map);
        },

        REPLACE_TR_FORM_DATA_DATA_PROP(state, { index, key, value }) {
            let map = state.trFormData.data[key];

            map[index] = { ...value };
            Vue.set(state.trFormData.data, key, map);
        },

        SLICE_FROM_TR_FORM_DATA(state, { index, key }) {
            state.trFormData[key] = state.trFormData[key].slice(0, index).concat(state.trFormData[key].slice(index + 1));
        },

        UPDATE_LIST_FILTER(state, newFilterlist) {
            Object.entries(newFilterlist).forEach(([key, value]) => Vue.set(state.listFilter, key, value));
            Vue.set(state.listFilter, 'page', newFilterlist.page || 1);
        },

        UPDATE_ITEM_FILTER(state, newFilterlist) {
            Object.entries(newFilterlist).forEach(([key, value]) => Vue.set(state.itemFilter, key, value));
            Vue.set(state.itemFilter, 'page', newFilterlist.page || 1);
        },

        REPLACE_ITEM_FILTER(state, itemFilter) {
            Vue.set(state, 'itemFilter', itemFilter);
        },

        RESET_ITEM(state) {
            state.item = {};
        },

        RESET_FORM_DATA(state) {
            state.formData = {};
        },

        RESET_TR_FORM_DATA(state) {
            state.trFormData = {};
        },

        RESET_STORE(state) {
            state.listFilter = { ...DEFAULT_FILTERS };
        },
    },

    actions: {
        GET_LIST: async ({ state, rootGetters, getters, commit }, filters) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let resp = await $axios.get(`/api/typographies/${rootGetters.currentTypography.id}/materials/groups/tree`, {
                    params: filters || getters.listActiveFilter,
                });

                commit('SET_LIST', resp.list);
                commit('SET_PAGINATION', resp.pagination);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        GET_MATERIAL_LIST: async ({ state, rootGetters, getters, commit }, params = {}) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let resp = await $axios.get(`/api/typographies/${rootGetters.currentTypography.id}/materials`, { params });

                commit('SET_MATERIALS_LIST', resp.list);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        GET_MATERIAL_PARAMS: async ({ state, rootGetters, getters, commit }, materialId) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let resp = await $axios.get(
                    `/api/typographies/${rootGetters.currentTypography.id}/materials/${materialId}/tables/params`,
                );

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        GET_ITEM: async ({ state, rootGetters, getters, commit }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: true });

                let resp = await $axios.get(`/api/typographies/${rootGetters.currentTypography.id}/materials/${id}/tables`, {
                    params: $fn.assign(getters.itemActiveFilter, {
                        limit: null,
                    }),
                });

                commit('SET_ITEM', { list: resp.list, material: resp.material });

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: false });
            }
        },

        GET_ITEM_FILTERS: async ({ state, rootGetters, getters, commit }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItemFilters', status: true });

                let resp = await $axios.get(`/api/typographies/${rootGetters.currentTypography.id}/materials/${id}/filters`, {
                    params: $fn.assign(getters.itemActiveFilter, {
                        limit: null,
                    }),
                });

                commit('SET_ITEM_FILTERS', resp);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItemFilters', status: false });
            }
        },

        GET_ITEM_TR: async ({ state, rootGetters, getters, commit }, { materialId, id }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItemTR', status: true });

                let resp = await $axios.get(
                    `/api/typographies/${rootGetters.currentTypography.id}/materials/${materialId}/tables/${id}`,
                    {
                        params: {
                            limit: null,
                        },
                    },
                );

                commit(
                    'materials/variables/SET_VARIABLE',
                    {
                        type: 'set',
                        variables: resp.single.variables,
                    },
                    { root: true },
                );

                commit('materials/params/SET_REQUEST_PARAMS', resp.single.options, {
                    root: true,
                });

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItemTR', status: false });
            }
        },

        SAVE_ITEM: async ({ state, rootGetters, commit, dispatch }, formData) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

                let resp = await $axios.post(`/api/typographies/${rootGetters.currentTypography.id}/materials`, formData);

                dispatch('GET_LIST');

                if (formData.id && Object.keys(state.item).length) dispatch('GET_ITEM', formData.id);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
            }
        },

        SAVE_ITEM_TR: async ({ state, rootGetters, commit, dispatch }, { id, formData, hasClone, without_noty }) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingSaveItemTR',
                    status: true,
                });

                let path = `/api/typographies/${rootGetters.currentTypography.id}/materials/${id}/tables`;

                let resp = await $axios.post(path, formData, {
                    headers: {
                        'Without-Noty': without_noty,
                    },
                });

                hasClone ? commit('ADD_CLONE_TR_TO_ITEM', resp.single) : dispatch('GET_ITEM', id);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingSaveItemTR',
                    status: false,
                });
            }
        },

        SAVE_ITEM_GROUP: async ({ state, rootGetters, commit, dispatch }, formData) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

                let resp = await $axios.post(`/api/typographies/${rootGetters.currentTypography.id}/materials/groups`, formData);

                dispatch('GET_LIST');

                if (formData.id && Object.keys(state.item).length) dispatch('GET_ITEM', formData.id);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
            }
        },

        DELETE_ITEM: async ({ commit, rootGetters, dispatch }, { id, without_fetch, without_noty }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: true });

                let path = `/api/typographies/${rootGetters.currentTypography.id}/materials/${id}`;

                let resp = await $axios.delete(path, {
                    headers: {
                        'Without-Noty': without_noty,
                    },
                });

                if (!without_fetch) dispatch('GET_LIST');

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: false });
            }
        },

        DELETE_ITEM_TR: async ({ commit, rootGetters, dispatch }, { materialId, id, without_fetch, without_noty }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: true });

                let path = `/api/typographies/${rootGetters.currentTypography.id}/materials/${materialId}/tables/${id}`;

                let resp = await $axios.delete(path, {
                    headers: {
                        'Without-Noty': without_noty,
                    },
                });

                if (!without_fetch) dispatch('GET_ITEM', materialId);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: false });
            }
        },
    },
};
