import Vue from 'vue';

import axios from 'axios';

const CancelToken = axios.CancelToken;

let cancel;

let getFomrattedItem = (item, index, group_index) => {
    return {
        ...item,
        index,
        group_index,

        preview:
            item.product?.mockup_with_preview?.preview ||
            item.product?.mockup_with_preview?.preview_original ||
            item.product?.preview ||
            null,
        placeholder: !item.creator ? 'placeholder.png' : `${item.stage.id}.png`,

        has_loading: false,
    };
};

export default {
    namespaced: true,

    state: {
        INACTIVE_FILTERING_KEYS: ['group'],

        list: [],
        listPagination: {},
        listFilters: {},

        activeItem: null,

        listFilter: {
            page: null,
            limit: 30,
            order: null,
            urgency_id: null,
            client_id: null,
            manager_id: null,
            assignee_id: null,
            status_id: null,
            operation_type: null,
            material_id: null,
            equipment_id: null,
            amount_gear_teeth: null,
            'created_at[from]': null,
            'created_at[to]': null,
            'deadline[from]': null,
            'deadline[to]': null,
            'completed_at[from]': null,
            'completed_at[to]': null,
            group: 'default',
            sort: 'new',
            direction: 'desc',
        },

        loadingGetList: false,
        loadingGetFilters: false,
        loadingExportList: false,
    },

    getters: {
        listActiveFilter(state) {
            return Object.keys(state.listFilter)
                .filter((key) => state.listFilter[key] !== null)
                .reduce((resp, key) => ((resp[key] = state.listFilter[key]), resp), {});
        },

        isFilterNotEmpty(state, getters, rootState) {
            return !!Object.keys(getters.listActiveFilter).filter(
                (key) => !rootState.constant.INACTIVE_FILTERING_KEYS.concat(state.INACTIVE_FILTERING_KEYS).includes(key),
            ).length;
        },
    },

    actions: {
        GET_LIST: async ({ state, rootState, getters, rootGetters, commit, dispatch }, filters = {}) => {
            try {
                if (cancel !== undefined) cancel();

                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingGetFilters',
                    status: true,
                });

                let path = `/api/typographies/${rootGetters.currentTypography.id}/orders/productions/operations/postpress/list`,
                    filterPath = `api/typographies/${rootGetters.currentTypography.id}/orders/productions/operations/postpress/filters`;

                let config = {
                    params: !!Object.keys(filters).length ? filters : getters.listActiveFilter,

                    cancelToken: new CancelToken(function executor(c) {
                        cancel = c;
                    }),
                };

                let [resp, filterResp] = await Promise.all([axios.get(path, config), axios.get(filterPath, config)]);

                commit('SET_LIST', resp.list);
                commit('SET_PAGINATION', resp.pagination);
                commit('SET_FILTERS', filterResp.filters);

                return [resp, filterResp];
            } catch (e) {
                if (!e.response) return;
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingGetFilters',
                    status: false,
                });
            }
        },

        EXPORT_LIST: async ({ state, commit, rootGetters, dispatch }, params) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingExportList',
                    status: true,
                });

                let path = `/api/typographies/${rootGetters.currentTypography.id}/orders/productions/operations/postpress/export`;

                let resp = await axios.get(path, { params });

                const url = resp.link,
                    link = document.createElement('a');

                link.href = url;
                link.click();

                URL.revokeObjectURL(link.href);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingExportList',
                    status: false,
                });
            }
        },
    },

    mutations: {
        SET_LIST(state, list) {
            state.list = Object.entries(list).map((item, index) => ({
                id: item[0],
                operations: item[1].map((operation, operationIndex) => getFomrattedItem(operation, operationIndex, index)),
            }));
        },

        SET_PAGINATION(state, pagination) {
            state.listPagination = pagination;
        },

        SET_FILTERS(state, filters) {
            state.listFilters = filters;
        },

        SET_LIST_ITEM_DATA(state, { operation, data, isProductionMode }) {
            Vue.set(state[isProductionMode ? 'production' : 'list'][operation.group_index].operations, operation.index, {
                ...operation,
                ...data,
            });
        },

        SET_LIST_ITEM_PROP(state, { operation, key, value, isProductionMode }) {
            Vue.set(state[isProductionMode ? 'production' : 'list'][operation.group_index].operations[operation.index], key, value);
        },

        SET_ACTIVE_ITEM(state, item) {
            Vue.set(state, 'activeItem', item || null);
        },

        SET_ACTIVE_ITEM_PROP(state, { key, value }) {
            if (state.activeItem) Vue.set(state.activeItem, key, value);
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },

        UPDATE_LIST_FILTER(state, newFilterlist) {
            Object.entries(newFilterlist).forEach(([key, value]) => Vue.set(state.listFilter, key, value));
            Vue.set(state.listFilter, 'page', newFilterlist.page || 1);
        },

        RESET_ACTIVE_ITEM(state) {
            Vue.delete(state, 'activeItem');
        },

        RESET_STORE(state) {
            state.listFilter = {
                page: null,
                limit: 30,
                order: null,
                urgency_id: null,
                client_id: null,
                manager_id: null,
                assignee_id: null,
                status_id: null,
                operation_type: null,
                material_id: null,
                equipment_id: null,
                amount_gear_teeth: null,
                'created_at[from]': null,
                'created_at[to]': null,
                'deadline[from]': null,
                'deadline[to]': null,
                'completed_at[from]': null,
                'completed_at[to]': null,
                group: 'default',
                sort: 'new',
                direction: 'desc',
            };
        },
    },
};
