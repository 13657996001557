import { Validator } from 'vee-validate';

import errors from './errors';

export const parseRules = (args) => {
    if (!args.length) return {};

    return Object.fromEntries(
        args.map((arg) => {
            let rule = arg.split(':');
            return [[...rule].shift(), [...rule].pop()];
        }),
    );
};

export const toFloat = (value) => {
    return parseFloat(value.replace(',', '.'));
};

export const decimal = (value, length) => {
    return !length ? true : +value.length <= +length;
};

export const min_value = (value, min) => {
    return isNotExist(min) ? true : toFloat(value) >= +toFloat(min);
};

export const max_value = (value, max) => {
    return isNotExist(max) ? true : toFloat(value) <= +toFloat(max);
};

export const cyrillicA = (value) => {
    return value === null || value === undefined ? '' : 'а-яА-ЯӘәҐґҒғЄєЁёҖҗІіЇїҚқҢңҲҳӨөҰұҮүӮӯЎўҺһҲҳ ';
};

export const latinA = (value) => {
    return value === null || value === undefined
        ? ''
        : 'a-zA-ZĄąĀāĄąÄäĆćČčÇçĘęĒēĖėĢģĞğƢƣĪīĮįĶķŁłĻļŃńŅņŊŋŇňÑñÓóÖöÕõƟɵÕõŚśŠšŞşŞşŪūŲųÜüÝýŹźŻżŽžƵƶЬь ';
};

export const georgianA = (value) => {
    return value === null || value === undefined ? '' : 'აბგდევზთიკლმნოპჟრსტუფქღყშჩცძწჭხჯჰჱჲჳჴჵჷჸჶჹჺჽჾჿ ';
};

export const getMessage = (errors, rules) => {
    return Object.fromEntries(
        Object.keys(rules)
            .filter((key) => errors[key])
            .map((key) => [key, rules[key]]),
    );
};

export const parseMessage = (field, args, data, key) => {
    return Object.keys(data).length
        ? Object.entries(data)
              .map(([error, value]) => errors[error][Validator.locale](value))
              .join('. ')
        : errors[key][Validator.locale](field, args);
};

const isNotExist = (value) => {
    return value === null || value === undefined || value === false || value === 'null' || value === 'undefined' || value === 'false';
};
