import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        list: [],
    },

    getters: {
        urgentProducts(state, getters, rootState) {
            return state.list.filter((item) => item.calculationData.principle.value === rootState.products.PRINCIPLE_KEY_FASTEST);
        },

        warehouseList(state) {
            return state.list.filter((item) => !!Object.keys(item.warehouseData).length);
        },

        warehouseMap(state, getters) {
            const map = getters.warehouseList.map((item) => ({
                kind_id: item.productData.kind.id,
                count: $fn.toFloat(item.calculationData.parameters.count.count),
            }));

            const groupedMap = $fn.groupBy(map, 'kind_id');

            return Object.entries(groupedMap).reduce((acc, curr) => {
                const key = [...curr].shift();
                const values = [...curr].pop();

                values.forEach((value) => (acc[key] ? (acc[key] += value.count) : (acc[key] = value.count)));

                return acc;
            }, {});
        },

        restoredList(state) {
            return state.list.filter((item) => item.storageData?.hasBeenRestored);
        },

        changedRestoredItems(state, getters) {
            return getters.restoredList.filter(
                (item) =>
                    item.storageData.hasChangedPrinciple ||
                    item.storageData.hasChangedCalcType ||
                    item.storageData.hasChangedPrintType ||
                    item.storageData.hasChangedCount ||
                    item.storageData.hasChangedFormat ||
                    item.storageData.hasChangedFormatParameters ||
                    item.storageData.hasChangedLabelFormatParameters ||
                    item.storageData.hasChangedWHData ||
                    item.storageData.hasChangedKnifeData ||
                    item.storageData.hasChangedDetailsData,
            );
        },

        inaccessibilityRestoredItems(state, getters) {
            return getters.restoredList.filter(
                (item) =>
                    item.storageData.isNoLongerAvailable ||
                    // item.storageData.isNoLongerPublished ||
                    item.storageData.isKindNoLongerPublished ||
                    item.storageData.isOutOfStock,
            );
        },

        hasOnlyUrgentProducts(state, getters) {
            return getters.hasProducts && getters.urgentProducts.length === state.list.length;
        },

        hasUrgentProducts(state, getters) {
            return getters.hasProducts && getters.urgentProducts.length && getters.urgentProducts.length !== state.list.length;
        },

        hasNoUrgentProducts(state, getters) {
            return !(getters.hasOnlyUrgentProducts || getters.hasUrgentProducts);
        },

        hasProducts(state) {
            return !!state.list.length;
        },

        hasOnlyWarehouseProducts(state, getters) {
            return !!(state.list.length === getters.warehouseList.length && getters.hasProducts);
        },

        hasChangedRestoredItems(state, getters) {
            return !!getters.changedRestoredItems.length;
        },

        hasInaccessibilityRestoredItems(state, getters) {
            return !!getters.inaccessibilityRestoredItems.length;
        },

        hasInaccessibleBasket(state, getters) {
            return getters.inaccessibilityRestoredItems.length === state.list.length;
        },

        isAllItemsReaclulated(state) {
            const recalculatedItems = state.list.filter((item) => item.storageData?.hasBeenRestored === false);
            return recalculatedItems.length === state.list.length;
        },
    },

    mutations: {
        ADD_TO_LIST(state, item) {
            state.list.push($fn.getDeepClone(item));
        },

        CLONE_TO_LIST(state, { index, data }) {
            const clone = $fn.getDeepClone({
                ...state.list[index],
                ...data,
            });

            state.list.splice(state.list.length, 0, clone);
        },

        UPDATE_LIST_ITEM(state, { index, data }) {
            const item = $fn.getDeepClone({
                ...state.list[index],
                ...data,
            });

            Vue.set(state.list, index, item);
        },

        DELETE_FROM_LIST(state, index) {
            Vue.delete(state.list, index);
        },

        RESET_LIST(state) {
            state.list = [];
        },
    },
};
