import Vue from './vue_base';
import Vuex from 'vuex';

import i18n from '@/utils/i18n';

import formula from '../store/modules/technicalData/calcModes/formula.js';

const $store = new Vuex.Store({
    state: formula.state,
    getters: formula.getters,
});

Vue.filter('numberNormalize', function (value) {
    if (!value) return '';

    if (value - Math.floor(value)) {
        return value;
    } else {
        return Math.trunc(value);
    }
});

Vue.filter('numberPrice', function (number, fixed) {
    if (!number) return 0;

    number = String(number);
    let split = number.split('.');

    if (split.length > 1) {
        let left = split[0];
        let right = split[1].substr(0, !fixed ? 2 : fixed);
        return Number(left + (fixed !== 0 ? '.' + right : ''));
    } else {
        return Number(number);
    }
});

Vue.filter('dateFromString', function (value) {
    if (!value) return '...';

    let date = '';
    value.split('-').forEach((item) => (date += '.' + item));

    return date.substring(1);
});

Vue.filter('dateNormalize', (value) => {
    return value !== null && value !== undefined && [...value.split('-')].shift().length === 2 ? `20${value}` : value;
});

Vue.filter('secondsToDate', function (value) {
    if (!value) return 0;
    if (typeof value === 'string') return value;

    let h = Math.floor(value / 3600),
        m = Math.floor((value % 3600) / 60),
        s = Math.floor((value % 3600) % 60),
        z = (n) => (n < 10 ? '0' : '') + n;
    return `${z(h)} ${i18n.t('common.hours')} ${z(m)} ${i18n.t('common.mins')} ${z(s.toFixed(0))} ${i18n.t('common.secs')}`;
});

Vue.filter('formulaParser', function (value) {
    return value.map((item) => $store.getters.parsedVariables[item.replace(/\[|\]/g, '')] || item).join(' ');
});

Vue.filter('formulaReplacer', function (value) {
    Object.entries($store.getters.parsedVariables).forEach(([key, translation]) => {
        let regex = new RegExp(`\\b${key}\\b`, 'g');
        return (value = value?.replaceAll(regex, translation.replace(/\[|\]/g, '')) || value);
    });

    return value;
});

Vue.filter('getDate', (value) => {
    return [...value.split(' ')].shift().replaceAll('-', '.');
});

Vue.filter('getTime', (value) => {
    return [...value.split(' ')].pop();
});

Vue.filter('readable', (value) => {
    return value !== null && value !== undefined ? value : '...';
});

Vue.prototype.$filters = Vue.options.filters;
