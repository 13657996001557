import Vue from 'vue';

import basket from './basket';
import knives from './knives';

const DEFAULT_COMPOSITION_STAGE = {
    value: 'order-creation-composition',
    translation_alias: 'order_creation.stages.composition_title',
    is_dirty: 1,
    is_done: 0,
};

const DEFAULT_CREATION_STAGE = {
    value: 'order-creation-placing',
    translation_alias: 'order_creation.stages.placing_title',
    is_done: 0,
    is_dirty: 0,
};

const DEFAULT_FORM_DATA = {
    client: null,
    company: null,
    manager: null,
    title: null,
    payment_method: null,
    employee_comment: null,
    urgency: null,
};

const DEFAULT_PRODUCT_DATA = {
    category: null,
    product: null,
    kind: null,
    format: null,
};

const DEFAULT_DELIVERY_DATA = {
    name: null,
    surname: null,
    patronymic: null,
    phone: '',
    delivery_type: null,
    delivery_option: null,
    address: null,
};

export default {
    namespaced: true,

    modules: {
        basket,
        knives,
    },

    state: {
        DEFAULT_COMPOSITION_STAGE,
        DEFAULT_CREATION_STAGE,
        DEFAULT_FORM_DATA,
        DEFAULT_PRODUCT_DATA,
        DEFAULT_DELIVERY_DATA,

        WIZARD_STAGES: [{ ...DEFAULT_COMPOSITION_STAGE }, { ...DEFAULT_CREATION_STAGE }],

        WIZARD_ORDER_COMPOSITION_VALUE: 'order-creation-composition',
        WIZARD_ORDER_PLACING_VALUE: 'order-creation-placing',

        CARD_PAYMENT_ID: 2,

        stage: null,
        clientTab: null,

        paymentMethods: [],

        formData: { ...DEFAULT_FORM_DATA },
        productData: { ...DEFAULT_PRODUCT_DATA },
        deliveryData: { ...DEFAULT_DELIVERY_DATA },

        isStillInProgress: false,

        loadingGetPaymentMethods: false,
        loadingSaveOrder: false,
    },

    getters: {
        // @Explanation: Layout system
        compositionStage(state) {
            return state.WIZARD_STAGES.find((stage) => stage.value === state.WIZARD_ORDER_COMPOSITION_VALUE);
        },

        compositionStageIndex(state) {
            return state.WIZARD_STAGES.findIndex((stage) => stage.value === state.WIZARD_ORDER_COMPOSITION_VALUE);
        },

        placingStage(state) {
            return state.WIZARD_STAGES.find((stage) => stage.value === state.WIZARD_ORDER_PLACING_VALUE);
        },

        placingStageIndex(state) {
            return state.WIZARD_STAGES.findIndex((stage) => stage.value === state.WIZARD_ORDER_PLACING_VALUE);
        },

        hasCompositionStage(state, getters) {
            return state.stage.value === getters.compositionStage.value;
        },

        hasPlacingStage(state, getters) {
            return state.stage.value === getters.placingStage.value;
        },

        // @Explanation: Client type tabs
        clientTabs(state, getters, rootState) {
            return [
                {
                    alias: rootState['contacts/clients'].NATURAL_ENTITY,
                    translation_alias: 'clients.natural_entity_short',
                    setting: rootState.settings.CLIENT_AUDIENCE_PRIORITY,
                },
                {
                    alias: rootState['contacts/clients'].LEGAL_ENTITY,
                    translation_alias: 'clients.legal_entity_short',
                    setting: rootState.settings.COMPANY_AUDIENCE_PRIORITY,
                },
            ];
        },

        // @Explanation: Data
        filteredPaymentMethods(state) {
            return state.paymentMethods.filter((paymentMethod) => paymentMethod.id !== state.CARD_PAYMENT_ID);
        },

        contactData(state) {
            const { name, surname, patronymic, phone } = state.deliveryData;
            return { name, surname, patronymic, phone };
        },
    },

    actions: {
        GET_PAYMENT_METHODS: async ({ state, rootGetters, getters, commit }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetPaymentMethods', status: true });

                const path = `/api/typographies/${rootGetters.currentTypography.id}/payment-methods`;

                let resp = await $axios.get(path);

                commit('SET_PAYMENT_METHODS', resp.list);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetPaymentMethods', status: false });
            }
        },

        SAVE_ORDER: async ({ state, rootState, rootGetters, getters, commit }, formData) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveOrder', status: true });

                const path = `/api/typographies/${rootGetters.currentTypography.id}/orders`;

                let resp = await $axios.post(path, formData);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveOrder', status: false });
            }
        },
    },

    mutations: {
        SET_PAYMENT_METHODS(state, list) {
            state.paymentMethods = list;
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },

        SET_STAGE(state, stage) {
            Vue.set(state, 'stage', stage);
        },

        SET_CLIENT_TAB(state, clientTab) {
            Vue.set(state, 'clientTab', clientTab);
        },

        SET_CLIENT_TAB(state, clientTab) {
            Vue.set(state, 'clientTab', clientTab);
        },

        SET_FORM_DATA(state, formData) {
            Vue.set(state, 'formData', { ...DEFAULT_FORM_DATA, ...formData });
        },

        SET_FORM_DATA_PROP(state, { key, value }) {
            Vue.set(state.formData, key, value);
        },

        SET_PRODUCT_DATA(state, productData) {
            Vue.set(state, 'productData', { ...DEFAULT_PRODUCT_DATA, ...productData });
        },

        SET_PRODUCT_DATA_PROP(state, { key, value }) {
            Vue.set(state.productData, key, value);
        },

        SET_DELIVERY_DATA(state, deliveryData) {
            Vue.set(state, 'deliveryData', { ...DEFAULT_DELIVERY_DATA, ...deliveryData });
        },

        SET_DELIVERY_DATA_PROP(state, { key, value }) {
            Vue.set(state.deliveryData, key, value);
        },

        UPDATE_STAGES(state, stages) {
            state.WIZARD_STAGES = state.WIZARD_STAGES.map((stage, index) => ({
                ...stage,
                ...stages[index],
            }));
        },

        UPDATE_FORM_DATA(state, data) {
            Vue.set(state, 'formData', { ...state.formData, ...data });
        },

        UPDATE_PRODUCT_DATA(state, data) {
            Vue.set(state, 'productData', { ...state.productData, ...data });
        },

        UPDATE_DELIVERY_DATA(state, data) {
            Vue.set(state, 'deliveryData', { ...state.deliveryData, ...data });
        },

        STASH_MODULE(state) {
            state.isStillInProgress = true;
        },

        RESET_STAGES(state) {
            state.WIZARD_STAGES = [{ ...DEFAULT_COMPOSITION_STAGE }, { ...DEFAULT_CREATION_STAGE }];
            state.stage = null;
        },

        RESET_CLIENT_TAB(state) {
            Vue.set(state, 'clientTab', null);
        },

        RESET_FORM_DATA(state) {
            Vue.set(state, 'formData', { ...DEFAULT_FORM_DATA });
        },

        RESET_PRODUCT_DATA(state) {
            Vue.set(state, 'productData', { ...DEFAULT_PRODUCT_DATA });
        },

        RESET_DELIVERY_DATA(state) {
            Vue.set(state, 'deliveryData', { ...DEFAULT_DELIVERY_DATA });
        },

        DESTROY_MODULE(state) {
            state.isStillInProgress = false;
        },
    },
};
