<template>
    <div class="druk-c-img-loader" @click="onToggle">
        <div class="druk-c-img-loader__wrapper" :class="{ [`druk-l-surface-${surface}`]: surface, 'druk-is-active': isActive }">
            <div class="druk-c-img-loader__header">
                <span class="druk-c-img-loader__label" :class="{ [`druk-l-surface-${surface}`]: surface, 'druk-is-active': isActive }">
                    <label>{{ label }}</label>
                </span>
            </div>

            <div class="druk-c-img-loader__body">
                <div
                    :style="{ height }"
                    class="druk-c-img-loader__preview"
                    :class="{ [`druk-l-surface-${surfaceVariant}`]: surfaceVariant }">
                    <druk-loader v-if="isStillInProgress" :size="'l'" :is-icon="true" />

                    <template v-else>
                        <druk-icon v-if="hasLoadedLink" :name="'link'" :size="'lg'" />
                        <druk-icon v-else-if="hasNoGeneratedPreview" :name="'file-circle-check'" :size="'lg'" />

                        <img v-else :src="filePreview || require('@img/placeholder.svg')" alt="preview" @error="$fn.fixImageError" />
                    </template>
                </div>

                <div class="druk-c-img-loader__ctrl">
                    <div class="druk-c-img-loader__upload">
                        <component
                            :id="ID_DOWNLOAD"
                            :is="hasLinkLoad ? 'druk-icon-button' : 'druk-button'"
                            :type="'outlined'"
                            :icon="'file-arrow-down'"
                            :label="$t('btn.download')"
                            :isDisabled="hasLoadedLink || hasNoGeneratedPreview || !value"
                            @click="onDownload" />
                    </div>

                    <div v-if="hasLinkLoad" class="druk-c-img-loader__upload">
                        <druk-icon-button
                            :id="ID_LINK"
                            :type="'outlined'"
                            :icon="'paperclip-vertical'"
                            :label="$t('btn.link')"
                            @click="onLink" />
                    </div>

                    <div v-if="isFilled" class="druk-c-img-loader__name">{{ fileName }}</div>

                    <div v-if="isStillInProgress" class="druk-c-img-loader__loader">
                        <druk-loader :is-icon="true" />
                    </div>

                    <div v-else class="druk-c-img-loader__trail">
                        <druk-icon-button
                            :id="ID_CLEAR"
                            :type="'standard'"
                            :icon="'trash'"
                            :isHighlighted="true"
                            :isDisabled="!isFilled"
                            @click="onClear" />
                    </div>
                </div>

                <input
                    v-validate="rules || ''"
                    :data-vv-name="name"
                    :data-vv-as="label"
                    ref="input"
                    :id="name"
                    :name="name"
                    type="file"
                    tabindex="-1"
                    title=""
                    :accept="accept"
                    class="druk-c-img-loader__input"
                    @input="onLoad"
                    @click="isActive = !isActive"
                    @cancel="isActive = false" />
            </div>

            <div v-if="support" class="druk-c-img-loader__footer">
                <div class="selector__support">
                    <druk-support :isTextField="true">{{ support }}</druk-support>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';

    export default {
        name: 'form-img-loader',

        inject: ['$validator'],

        props: {
            // @Explanation: Model,
            value: [String, File],

            // @Explanation: Component building blocks
            label: String,
            preview: String,
            support: String,
            hasLoader: Boolean,

            // @Explanation: Loading logic
            accept: String,
            hasInjectLoad: Boolean,
            hasLinkLoad: Boolean,
            hasLoadedLink: Boolean,

            // @Explanation: Validation
            rules: [String, Object],
            name: String,

            // @Explanation: Styling
            height: {
                type: String,
                default: '320px',
            },
            surface: {
                type: String,
                default: 'tint-pale',
            },
            surfaceVariant: {
                type: String,
                default: 'tint-bright',
            },
        },

        data() {
            return {
                ID_DOWNLOAD: 'download',
                ID_LINK: 'link',
                ID_CLEAR: 'clear',

                isActive: false,
            };
        },

        computed: {
            ...mapState({
                loadingSaveFile: (state) => state.loadingSaveFile,
            }),

            filePreview() {
                return this.preview || this.value;
            },

            fileName() {
                return this.hasInjectLoad ? this.value?.name || this.value : this.value;
            },

            isFilled() {
                return this.value;
            },

            hasNoGeneratedPreview() {
                return this.hasInjectLoad && !this.preview && this.value;
            },

            isStillInProgress() {
                return this.loadingSaveFile || this.hasLoader;
            },
        },

        methods: {
            ...mapActions({
                SAVE_FILE: 'SAVE_FILE',
            }),

            onToggle(e) {
                if (
                    e.target.closest(`#${this.ID_DOWNLOAD}`) ||
                    e.target.closest(`#${this.ID_LINK}`) ||
                    e.target.closest(`#${this.ID_CLEAR}`)
                )
                    return;

                this.$refs.input.click();
            },

            async onLoad(e) {
                if (!e.target.files[0] && !this.hasLoadedLink) return this.onClear();

                let resp = { file: e.target.files[0] };

                if (!this.hasInjectLoad) {
                    const formData = new FormData();
                    formData.append('file', e.target.files[0]);
                    resp = await this.SAVE_FILE(formData);
                }

                this.$emit('input', resp.file);
                this.isActive = false;
            },

            onClear() {
                this.$refs.input.value = null;
                this.$emit(this.hasLoadedLink ? 'delete-link' : 'input', null);

                this.isActive = false;
            },

            onDownload() {
                const link = document.createElement('a');

                link.href = this.value;
                link.target = '_blank';
                link.download = true;
                link.click();

                document.remove(link);

                this.isActive = false;
            },

            onLink() {
                this.$emit('link');
                this.isActive = false;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .druk-c-img-loader {
        cursor: pointer;
        &:hover &__wrapper {
            outline-color: var(--druk-on-surface);
            transition: var(--druk-duration-short-4) var(--druk-easing-emphasized-decelerate);
        }
        &:hover &__label {
            color: var(--druk-on-surface);
            transition: var(--druk-duration-short-4) var(--druk-easing-emphasized-decelerate);
        }
        &__wrapper {
            position: relative;
        }
        &__header {
            position: absolute;
            top: -2px;
            left: var(--druk-gap-s-a);
            transform: translate(0, -50%);
        }
        &__label {
            height: 16px;
            padding: 0px 3px;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.4px;
            font-weight: 400;
            color: var(--druk-on-surface-variant);
            transition: var(--druk-duration-short-4) var(--druk-easing-emphasized-accelerate);
            &.druk-is-active {
                color: var(--druk-primary);
            }
        }
        &__body {
            display: flex;
            flex-direction: column;
            padding: var(--druk-gap-l);
            outline: 1px solid var(--druk-outline);
            border: 2px solid transparent;
            border-radius: 4px;
            transition: var(--druk-duration-short-4) var(--druk-easing-emphasized-accelerate);
            &.druk-is-active {
                outline-color: var(--druk-primary);
                border-color: var(--druk-primary);
            }
        }
        &__preview {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: var(--druk-gap-m);
            margin-bottom: var(--druk-gap-xl);
            border-radius: 8px;
            &:hover img {
                max-height: 96%;
                transition: var(--druk-duration-medium-4) var(--druk-easing-emphasized-decelerate);
            }
            img {
                display: block;
                max-height: 100%;
                transition: var(--druk-duration-short-4) var(--druk-easing-emphasized-accelerate);
            }
        }
        &__ctrl {
            display: flex;
            align-items: center;
        }
        &__upload {
            &:not(:first-child) {
                margin-left: var(--druk-gap-s);
            }
        }
        &__name {
            display: inline-block;
            padding: 0 var(--druk-gap-3xl) 0 var(--druk-gap-m);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.25px;
            font-weight: 400;
            color: var(--druk-on-surface);
        }
        &__input {
            position: absolute;
            top: 0;
            opacity: 0;
        }
        &__loader,
        &__trail {
            margin-left: auto;
        }
    }
</style>
