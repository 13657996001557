<template>
    <div :id="`mdp-${hash}`" class="druk-c-mdp druk-l-modal-c">
        <div class="druk-c-mdp__wrapper druk-l-modal-c__wrapper">
            <div :id="`mdp-area-${hash}`" class="druk-c-mdp__area">
                <form-input
                    ref="area"
                    v-model="areaValue"
                    :label="label"
                    :trail-icon="'calendar'"
                    :rules="rules"
                    :name="`${name}-area`"
                    :surface="surface"
                    :readonly="true"
                    :is-filter-type="true"
                    :has-pseudo-active="isShown"
                    @clear="onClear" />
            </div>

            <div
                class="druk-c-mdp__modal druk-l-modal-c__modal"
                :class="{ [`druk-l-surface-${surface}`]: surface, 'druk-is-shown': isShown }">
                <div class="druk-c-mdp__modal-wrapper druk-l-modal-c__modal-wrapper druk-is-wider">
                    <div class="druk-c-mdp__header druk-l-modal-c__header">
                        <div class="druk-c-mdp__title druk-u-text-label-m">{{ $t('componens.modal_date_picker.title') }}</div>
                    </div>

                    <div class="druk-c-mdp__body druk-l-modal-c__body">
                        <div class="druk-c-mdp__dates">
                            <div class="druk-c-mdp__dates-item druk-c-mdp__from">
                                <form-date-picker
                                    v-model="fromDateRange"
                                    ref="from"
                                    :label="$t('componens.modal_date_picker.from')"
                                    :max-date="maxFromDate"
                                    :surface="surface"
                                    :surface-variant="surfaceVariant"
                                    :single-date-picker="true"
                                    :ranges="false"
                                    :is-filter-type="true"
                                    @own-toggle="isFromActive = $event">
                                </form-date-picker>
                            </div>

                            <div v-if="hasRangeMode" class="druk-c-mdp__dates-item druk-c-mdp__to">
                                <form-date-picker
                                    v-model="toDateRange"
                                    ref="to"
                                    :label="$t('componens.modal_date_picker.to')"
                                    :min-date="minToDate"
                                    :surface="surface"
                                    :surface-variant="surfaceVariant"
                                    :single-date-picker="true"
                                    :ranges="false"
                                    :is-filter-type="true"
                                    @own-toggle="isToActive = $event">
                                </form-date-picker>
                            </div>
                        </div>
                    </div>

                    <div class="druk-c-tp__footer druk-l-modal-c__footer">
                        <div class="druk-c-tp__footer-group druk-l-modal-c__footer-group druk-is-full">
                            <div class="druk-c-tp__footer-group-item druk-l-modal-c__footer-group-item">
                                <druk-button
                                    :type="'text'"
                                    :label="$t('admin.btn.cancel')"
                                    :isDisabled="isFromActive || isToActive"
                                    @click="onCancel" />
                            </div>

                            <div class="druk-c-tp__footer-group-item druk-l-modal-c__footer-group-item">
                                <druk-button
                                    :type="'text'"
                                    :label="$t('status.accept')"
                                    :isDisabled="isFromActive || isToActive"
                                    @click="onApply" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        name: 'druk-modal-date-picker',

        props: {
            // @Explanation: Model,
            value: Object,

            // @Explanation: Component building blocks
            label: String,

            // @Explanation: Model
            mode: {
                type: String,
                default: 'range', // @Explanation: Possible values - range, single
            },

            // @Explanation: Validation
            rules: [String, Object, Array],
            name: String,

            // @Explanation: Styling
            surface: {
                type: String,
                default: 'tint-pale',
            },
            surfaceVariant: {
                type: String,
                default: 'tint-bright',
            },
        },

        data() {
            return {
                MODE_RANGE: 'range',
                DATE_FORMAT: 'YYYY-MM-DD',

                fromDateRange: {
                    from: null,
                    to: null,
                },

                toDateRange: {
                    from: null,
                    to: null,
                },

                hash: null,
                stash: null,

                isShown: false,
                isFromActive: false,
                isToActive: false,
            };
        },

        created() {
            this.hash = $fn.generateHash();
            document.addEventListener('click', this.onToggle);
        },

        beforeDestroy() {
            document.removeEventListener('click', this.onToggle);
        },

        watch: {
            isShown(value) {
                if (value) this.setDateRanges();
            },
        },

        computed: {
            areaValue() {
                return this.isFull
                    ? `${this.$filters.readable(this.value.from.from)} – ${this.$filters.readable(this.value.to.to)}`
                    : null;
            },

            maxFromDate() {
                return this.toDateRange.to ? moment(this.toDateRange.to).format(this.DATE_FORMAT) : null;
            },

            minToDate() {
                return this.fromDateRange.from ? moment(this.fromDateRange.from).format(this.DATE_FORMAT) : null;
            },

            hasRangeMode() {
                return this.mode === this.MODE_RANGE;
            },

            isFull() {
                return this.hasFromValue || this.hasToValue;
            },

            hasFromValue() {
                return this.value.from.from || this.value.from.to;
            },

            hasToValue() {
                return this.value.to.from || this.value.to.to;
            },
        },

        methods: {
            setDateRanges() {
                this.fromDateRange = { ...this.fromDateRange, ...this.value.from };
                this.toDateRange = { ...this.toDateRange, ...this.value.to };

                this.onStash();
            },

            onStash() {
                this.stash = {
                    from: { ...this.fromDateRange },
                    to: { ...this.toDateRange },
                };
            },

            onClear() {
                this.fromDateRange = { from: null, to: null };
                this.toDateRange = { from: null, to: null };

                this.onApply();
            },

            onApply() {
                this.value.from = this.fromDateRange;
                this.value.to = this.toDateRange;

                this.$emit('input', this.value);
                this.onClose();
            },

            onClose() {
                this.isShown = false;
                this.$refs.area.$el.blur();
            },

            onToggle(e) {
                if (e?.target?.closest(`#mdp-area-${this.hash}`)) {
                    if (e?.target?.closest('.selector__trail')) return;

                    this.isShown = !this.isShown;

                    this.isShown ? this.onStash() : this.onClose();
                    return;
                }

                if (!e?.target?.closest(`#mdp-${this.hash}`)) this.onClose();
            },

            onCancel() {
                this.value.from = this.stash.from;
                this.value.to = this.stash.to;

                this.onClose();
            },
        },
    };
</script>

<style lang="scss" scoped>
    .druk-c-mdp {
        &__dates {
            display: flex;
            align-items: center;
            margin: 0 var(--druk-n-gap-m);
            &-item {
                cursor: pointer;
                flex-grow: 1;
                flex-shrink: 1;
                width: 50%;
                height: 100%;
                padding: 0 var(--druk-gap-m);
                @media (max-width: $druk-breakpoints-duo) {
                    width: 100%;
                    padding: 0 0 var(--druk-gap-xl);
                }
            }
            @media (max-width: $druk-breakpoints-duo) {
                display: block;
                margin: 0 0 var(--druk-n-gap-xl);
            }
        }
    }
</style>
