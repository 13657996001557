import { library } from '@fortawesome/fontawesome-svg-core';

import {
    faArrowDown,
    faPenNibSlash,
    faCheck,
    faCaretDown,
    faBolt,
    faCircleSmall,
    faCircleXmark,
    faStarOfLife,
    faGear,
    faPlus,
    faMinus,
    faXmark,
    faDivide,
    faBracketsRound,
    faBracketRound,
    faBracketRoundRight,
    faArrowRotateLeft,
    faArrowRotateRight,
    faCopy,
    faPaste,
    faTrash,
    faSwatchbook,
    faCircleInfo,
    faListCheck,
    faMessages,
    faListTree,
    faImage,
    faPaintbrushPencil,
    faDrawSquare,
    faMapLocationDot,
    faAddressBook,
    faBars,
    faGlobe,
    faBell,
    faEllipsisVertical,
    faEllipsisStrokeVertical,
    faBarsFilter,
    faCircleQuestion,
    faPen,
    faFloppyDisk,
    faSquareRootVariable,
    faAddressCard,
    faBuildingUser,
    faUser,
    faPersonSimple,
    faNote,
    faCoins,
    faCartFlatbedBoxes,
    faCode,
    faFolderMagnifyingGlass,
    faWindow,
    faBrowser,
    faTruckFast,
    faSquareCheck,
    faSquareMinus,
    faCrown,
    faFileMagnifyingGlass,
    faUserPen,
    faClock,
    faKeyboard,
    faClockRotateLeft,
    faLockKeyhole,
    faPaperclipVertical,
    faAlbumCollection,
    faBroomWide,
    faBasketShopping,
    faBasketShoppingPlus,
    faP,
    faFlask,
    faFlaskVial,
    faFlaskGear,
    faGears,
    faLightbulbGear,
    faGearComplex,
    faGearComplexCode,
    faBox,
    faBoxTaped,
    faBoxDollar,
    faWindowRestore,
    faScalpelLineDashed,
    faFileArrowUp,
    faFileCircleCheck,
} from '@fortawesome/pro-solid-svg-icons';

library.add(
    faArrowDown,
    faPenNibSlash,
    faCheck,
    faCaretDown,
    faBolt,
    faCircleSmall,
    faCircleXmark,
    faStarOfLife,
    faGear,
    faPlus,
    faMinus,
    faXmark,
    faDivide,
    faBracketsRound,
    faBracketRound,
    faBracketRoundRight,
    faArrowRotateLeft,
    faArrowRotateRight,
    faCopy,
    faPaste,
    faTrash,
    faSwatchbook,
    faCircleInfo,
    faListCheck,
    faMessages,
    faListTree,
    faImage,
    faPaintbrushPencil,
    faDrawSquare,
    faMapLocationDot,
    faAddressBook,
    faBars,
    faGlobe,
    faBell,
    faEllipsisVertical,
    faEllipsisStrokeVertical,
    faBarsFilter,
    faCircleQuestion,
    faPen,
    faFloppyDisk,
    faSquareRootVariable,
    faAddressCard,
    faBuildingUser,
    faUser,
    faPersonSimple,
    faNote,
    faCoins,
    faCartFlatbedBoxes,
    faCode,
    faFolderMagnifyingGlass,
    faWindow,
    faBrowser,
    faTruckFast,
    faSquareCheck,
    faSquareMinus,
    faCrown,
    faFileMagnifyingGlass,
    faUserPen,
    faClock,
    faKeyboard,
    faClockRotateLeft,
    faLockKeyhole,
    faPaperclipVertical,
    faAlbumCollection,
    faBroomWide,
    faBasketShopping,
    faBasketShoppingPlus,
    faP,
    faFlask,
    faFlaskVial,
    faFlaskGear,
    faGears,
    faLightbulbGear,
    faGearComplex,
    faGearComplexCode,
    faBox,
    faBoxTaped,
    faBoxDollar,
    faWindowRestore,
    faScalpelLineDashed,
    faFileArrowUp,
    faFileCircleCheck,
);
