import Vue from 'vue';

const DEFAULT_FILTERS = {
    page: null,
    limit: null,
};

export default {
    namespaced: true,

    state: {
        list: [],
        listPagination: {},
        listFilter: { ...DEFAULT_FILTERS },

        loadingGetList: false,
    },

    getters: {
        // @Explanation: Base
        formattedList(state) {
            return state.list.map((item, index) => {
                const sameItems = state.list.filter((existed) => existed.detail.id === item.detail.id);

                return sameItems.length === 1
                    ? item
                    : Object.assign(item, {
                          same_index: sameItems.findIndex((same) => same.id === item.id) + 1,
                      });
            });
        },

        hasCustomCountDetail(state) {
            return !!state.list.filter((item) => item.is_custom_count).length;
        },

        // @Explanation: Filters
        listActiveFilter(state) {
            return Object.keys(state.listFilter)
                .filter((key) => state.listFilter[key] !== null)
                .reduce((resp, key) => ((resp[key] = state.listFilter[key]), resp), {});
        },

        isFilterNotEmpty(state, getters, rootState) {
            return !!Object.keys(getters.listActiveFilter).filter(
                (key) => !rootState.constant.INACTIVE_FILTERING_KEYS.concat(state.INACTIVE_FILTERING_KEYS).includes(key),
            ).length;
        },
    },

    actions: {
        GET_LIST: async ({ state, getters, rootState, rootGetters, commit }, { filters, calculationId, productId }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let path = `/api/typographies/${rootGetters.currentTypography.id}/orders/calculations/${calculationId}/products/${productId}/details/tree`;

                let resp = await $axios.get(path, {
                    params: filters || getters.listActiveFilter,
                });

                commit('SET_LIST', resp.list);
                commit('SET_PAGINATION', resp.pagination);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },
    },

    mutations: {
        SET_LOADING_STATUS(state, { value_key, status }) {
            Vue.set(state, value_key, status);
        },

        SET_LIST(state, list) {
            Vue.set(state, 'list', list);
        },

        SET_PAGINATION(state, pagination) {
            state.listPagination = pagination;
        },

        RESET_LIST(state) {
            state.list = [];
        },

        RESET_STORE(state) {
            state.list = [];
        },
    },
};
