export default {
    namespaced: true,

    state: {
        PATTERN_MAP: {
            demo: [
                {
                    key: 'main',
                    content: [['title', 'text', 'button'], ['img']],
                    cols: 2,
                },
            ],
        },
    },

    getters: {
        currentEntityPattern(state, getters, rootState) {
            const alias = rootState.partnerPages.entities.item?.alias;
            const pattern = state.PATTERN_MAP[alias];

            return pattern?.map((item) => ({
                ...item,
                hasWholeContent: item.isDynamic && item.content.length === 1,
                hasSplitContent: item.isDynamic && item.content.length > 1,
            }));
        },
    },
};
