<template>
    <div :id="`tp-${hash}`" class="druk-c-tp druk-l-modal-c">
        <div class="druk-c-tp__wrapper druk-l-modal-c__modal__wrapper">
            <div :id="`tp-area-${hash}`" class="druk-c-tp__area">
                <form-input
                    ref="area"
                    v-model="areaValue"
                    :label="label"
                    :lead-icon="'clock'"
                    :rules="rules"
                    :name="`${name}-area`"
                    :surface="surface"
                    :readonly="true"
                    :hasPseudoActive="isShown" />
            </div>

            <div
                class="druk-c-tp__modal druk-l-modal-c__modal"
                :class="{ [`druk-l-surface-${surface}`]: surface, 'druk-is-shown': isShown }">
                <div class="druk-c-tp__modal-wrapper druk-l-modal-c__modal-wrapper">
                    <div class="druk-c-tp__header druk-l-modal-c__header">
                        <div class="druk-c-tp__title druk-u-text-label-m">{{ $t('components.time_picker.title') }}</div>
                    </div>

                    <div class="druk-c-tp__body druk-l-modal-c__body">
                        <div class="druk-c-tp__time">
                            <div class="druk-c-tp__hours">
                                <div
                                    class="druk-c-tp__input"
                                    :class="{ 'druk-has-dial-mode': hasDialMode, 'druk-is-active': hasActiveHours }">
                                    <input
                                        v-model="value.hours"
                                        v-bind:readonly="hasDialMode"
                                        v-validate="`included:${validHours.join(',')}`"
                                        :data-vv-name="`${name}-hours`"
                                        :data-vv-as="`${name}-hours`"
                                        class="druk-u-text-display-m"
                                        :class="{ [`druk-l-surface-${surfaceVariant}`]: surfaceVariant }"
                                        type="text"
                                        :name="`${name}-hours`"
                                        @click="hasActiveHours = true"
                                        @input="onCheckHours({ event: $event })"
                                        @blur="onParseTime(value.hours, 'hours')" />

                                    <div class="druk-c-tp__input-state"></div>
                                </div>

                                <div class="druk-c-tp__label druk-u-text-body-s">{{ $t('components.time_picker.hours') }}</div>
                            </div>

                            <div class="druk-c-tp__separator druk-u-text-display-lg">{{ ':' }}</div>

                            <div class="druk-c-tp__minutes">
                                <div
                                    class="druk-c-tp__input"
                                    :class="{ 'druk-has-dial-mode': hasDialMode, 'druk-is-active': !hasActiveHours }">
                                    <input
                                        v-model="value.minutes"
                                        v-bind:readonly="hasDialMode"
                                        v-validate="`included:${validMinutes.join(',')}`"
                                        :data-vv-name="`${name}-minutes`"
                                        :data-vv-as="`${name}-minutes`"
                                        class="druk-u-text-display-m"
                                        :class="{ [`druk-l-surface-${surfaceVariant}`]: surfaceVariant }"
                                        type="text"
                                        :name="`${name}-minutes`"
                                        @click="hasActiveHours = false"
                                        @input="onCheckMinutes({ event: $event })"
                                        @blur="onParseTime(value.minutes, 'minutes')" />

                                    <div class="druk-c-tp__input-state"></div>
                                </div>

                                <div class="druk-c-tp__label druk-u-text-body-s">{{ $t('components.time_picker.minutes') }}</div>
                            </div>

                            <div v-if="!has24HoursFormat" class="druk-c-tp__period">
                                <div
                                    class="druk-c-tp__period-button druk-u-text-body-lg druk-is-am"
                                    :class="{ 'druk-is-active': hasAMPeriod }"
                                    @click="setAMPeriod">
                                    <div class="druk-c-tp__period-button-label">{{ 'AM' }}</div>
                                    <div class="druk-c-tp__period-button-state"></div>
                                </div>

                                <div
                                    class="druk-c-tp__period-button druk-u-text-body-lg druk-is-pm"
                                    :class="{ 'druk-is-active': hasPMPeriod }"
                                    @click="setPMPeriod">
                                    <div class="druk-c-tp__period-button-label">{{ 'PM' }}</div>
                                    <div class="druk-c-tp__period-button-state"></div>
                                </div>
                            </div>
                        </div>

                        <div v-if="hasDialMode || !hasStaticTimeFormat" class="druk-c-tp__clock druk-c-clock">
                            <div class="druk-c-clock__wrapper">
                                <div
                                    ref="clock"
                                    v-if="hasDialMode"
                                    class="druk-c-clock__body"
                                    :class="{ [`druk-l-surface-${surfaceVariant}`]: surfaceVariant }"
                                    @mousedown="onMouseDown"
                                    @mouseup="onMouseUp"
                                    @touchstart="onMouseDown"
                                    @touchend="onMouseUp"
                                    @mousemove="onDragMove"
                                    @touchmove="onDragMove"
                                    @mouseleave="(e) => isDragging && onMouseUp(e)">
                                    <div ref="dial" class="druk-c-clock__dial">
                                        <div class="druk-c-clock__dial-center"></div>

                                        <div class="druk-c-clock__dial-track" :style="getTrackStyles()"></div>

                                        <div
                                            v-for="(digit, index) in hasActiveHours ? hourDigits : minuteDigits"
                                            :key="index"
                                            class="druk-c-clock__dial-digit"
                                            :class="{ 'druk-is-active': $fn.toTime(activeDigit) === $fn.toTime(digit) }"
                                            :style="getDigitStyles(digit)"
                                            @click="setActiveDigit(digit)">
                                            <div class="druk-c-clock__dial-digit-label druk-u-text-body-lg">
                                                {{ getDigitLabel(digit) }}
                                            </div>

                                            <div class="druk-c-clock__dial-digit-state"></div>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="!hasStaticTimeFormat" class="druk-c-clock__options">
                                    <div class="druk-c-clock__options-item">
                                        <form-switch
                                            v-model="has24HoursFormat"
                                            :label="$t('components.time_picker.use_24_hours_format')"
                                            name="has24HoursFormat"
                                            @change="onChangeFormat" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="druk-c-tp__footer druk-l-modal-c__footer">
                        <div class="druk-c-tp__footer-group druk-l-modal-c__footer-group">
                            <div class="druk-c-tp__footer-group-item druk-l-modal-c__footer-group-item">
                                <druk-icon-button :type="'standard'" :icon="hasInputMode ? 'clock' : 'keyboard'" @click="changeMode" />
                            </div>
                        </div>

                        <div class="druk-c-tp__footer-group druk-l-modal-c__footer-group">
                            <div class="druk-c-tp__footer-group-item druk-l-modal-c__footer-group-item">
                                <druk-button :type="'text'" :label="$t('admin.btn.cancel')" @click="onCancel" />
                            </div>

                            <div class="druk-c-tp__footer-group-item druk-l-modal-c__footer-group-item">
                                <druk-button :type="'text'" :label="$t('status.accept')" @click="onClose" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'druk-time-picker',

        inject: ['$validator'],

        props: {
            value: {
                type: Object,
                default: () => {
                    return {
                        hours: '00',
                        minutes: '00',
                        period: null,
                    };
                },
            },

            timeFormat: [Number, String], // @Explanation: Possible values: 12, 24

            label: String,
            rules: [String, Object],
            name: String,

            surface: {
                type: String,
                default: 'tint-mild',
            },
            surfaceVariant: {
                type: String,
                default: 'tint-brighter',
            },

            isModal: Boolean,
        },

        data() {
            return {
                INNER_RADIUS_SCALE: 0.62,
                MODES_MAP: {
                    dial: 0,
                    input: 1,
                },

                MODE_INPUT: 'input',
                MODE_DIAL: 'dial',

                HOURS_TIME_FORMAT_12: 12,
                HOURS_TIME_FORMAT_24: 24,

                PERIOD_AM: 'AM',
                PERIOD_PM: 'PM',

                hash: null,
                stash: null,

                mode: 'input',

                oldDigit: null,
                newDigit: null,

                isShown: false,

                hasActiveHours: true,
                has24HoursFormat: true,

                isDragging: false,
            };
        },

        created() {
            this.hash = $fn.generateHash();
            document.addEventListener('click', this.onToggle);

            this.setTimeFormat();
        },

        beforeDestroy() {
            document.removeEventListener('click', this.onToggle);
        },

        computed: {
            areaValue() {
                return `${$fn.toTime(this.value.hours)}:${$fn.toTime(this.value.minutes)}${this.value.period ? ` ${this.value.period}` : ''}`;
            },

            activeDigit() {
                return this.hasActiveHours ? this.value.hours : this.value.minutes;
            },

            minDigit() {
                return 1;
            },

            maxDigit() {
                return this.hoursCount;
            },

            hoursCount() {
                return this.has24HoursFormat ? 24 : 12;
            },

            hourDigits() {
                return Array.from({ length: this.hoursCount }, (_, i) => i++);
            },

            validHours() {
                return this.getValidValues(this.hourDigits);
            },

            minuteDigits() {
                return Array.from({ length: 60 }, (_, i) => i++);
            },

            validMinutes() {
                return this.getValidValues(this.minuteDigits);
            },

            sectorCount() {
                return this.hasActiveHours ? 12 : 60;
            },

            sectorDegree() {
                return 360 / this.sectorCount;
            },

            degrees() {
                return (this.sectorDegree * Math.PI) / 180;
            },

            rotateRadians() {
                return Math.PI / 180;
            },

            hasInputMode() {
                return this.mode === this.MODE_INPUT;
            },

            hasDialMode() {
                return this.mode === this.MODE_DIAL;
            },

            hasStaticTimeFormat() {
                return this.timeFormat;
            },

            hasAMPeriod() {
                return this.value.period === this.PERIOD_AM;
            },

            hasPMPeriod() {
                return this.value.period === this.PERIOD_PM;
            },

            hasLongTracker() {
                return !this.hasActiveHours && !this.isMinuteHasLabel(this.activeDigit);
            },
        },

        methods: {
            setTimeFormat() {
                this.has24HoursFormat = +this.timeFormat === this.HOURS_TIME_FORMAT_24 || !this.value.period;
            },

            onToggle(e) {
                if (e?.target?.closest(`#tp-area-${this.hash}`)) {
                    this.isShown = !this.isShown;

                    this.isShown ? this.onStash() : this.onClose();
                    return;
                }

                if (!e?.target?.closest(`#tp-${this.hash}`)) this.onClose();
            },

            onStash() {
                this.stash = { ...this.value };
            },

            onClose() {
                this.isShown = false;
                this.$refs.area.$el.blur();
            },

            onCancel() {
                this.value.hours = this.stash.hours;
                this.value.minutes = this.stash.minutes;
                this.value.pediod = this.stash.pediod;

                this.onClose();
            },

            onCheckHours({ event, value }) {
                this.value.hours = this.onCheckValue(event || value, this.validHours, 'hours_is_not_valid');
            },

            onCheckMinutes({ event, value }) {
                this.value.minutes = this.onCheckValue(event || value, this.validMinutes, 'minutes_is_not_valid');
            },

            onCheckValue(event, valid, messageKey) {
                if (!event.target.value || valid.includes(event.target.value)) return event.target.value;

                let range = `${[...valid].shift()} – ${[...valid].pop()}`;
                this.$noty.warning($fn.tShift(this.$t(`components.time_picker.${messageKey}`), { range }));

                return [...valid].pop();
            },

            onParseTime(value, key) {
                this.value[key] = $fn.toTime(value);
            },

            setAMPeriod() {
                this.value.period = this.PERIOD_AM;
            },

            setPMPeriod() {
                this.value.period = this.PERIOD_PM;
            },

            getDigitStyles(digit) {
                const { x, y } = this.getDigitPosition(digit);

                const isFull = this.hasActiveHours || this.isMinuteHasLabel(digit);

                const side = isFull ? 48 : 16;

                return {
                    top: `calc(${50 + y * 50}% - ${side / 2}px)`,
                    left: `calc(${50 + x * 50}% - ${side / 2}px)`,
                    width: `${side}px`,
                    height: `${side}px`,
                    zIndex: isFull ? 1 : 2,
                };
            },

            getDigitPosition(digit) {
                return {
                    x: Math.sin(digit * this.degrees + this.rotateRadians) * this.getScale(digit),
                    y: -Math.cos(digit * this.degrees + this.rotateRadians) * this.getScale(digit),
                };
            },

            getScale(digit) {
                return this.isInner(digit) ? this.INNER_RADIUS_SCALE : 1;
            },

            isInner(digit) {
                return this.hasActiveHours && digit >= 12;
            },

            isMinuteHasLabel(minute) {
                return $fn.toFloat(minute) % 5 === 0 || !$fn.toFloat(minute);
            },

            getTrackStyles() {
                return {
                    height: this.hasLongTracker ? `102px` : `82px`,
                    transform: `rotate(${this.sectorDegree * this.activeDigit + +this.hasLongTracker || 360}deg)`,
                };
            },

            setActiveDigit(digit) {
                this.hasActiveHours ? (this.value.hours = $fn.toTime(digit)) : (this.value.minutes = $fn.toTime(digit));
            },

            getDigitLabel(digit) {
                return this.hasActiveHours ? $fn.toTime(digit) : this.isMinuteHasLabel(digit) ? $fn.toTime(digit) : '';
            },

            onChangeFormat(value) {
                value ? this.set24HoursFormatData() : this.set12HoursFormatData();
            },

            set24HoursFormatData() {
                if (this.hasPMPeriod) this.value.hours = $fn.toTime($fn.toFloat(this.value.hours) + 12);

                if (this.hasActiveHours) this.setActiveDigit(this.value.hours);
                this.value.period = null;
            },

            set12HoursFormatData() {
                if ($fn.toFloat(this.value.hours) >= 12) {
                    this.value.hours = $fn.toTime($fn.toFloat(this.value.hours) - 12);
                    this.setPMPeriod();
                } else this.setAMPeriod();

                if (this.hasActiveHours) this.setActiveDigit(this.value.hours);
            },

            changeMode() {
                this.mode = this.hasInputMode ? this.MODE_DIAL : this.MODE_INPUT;
                this.setActiveDigit(this.hasActiveHours ? this.value.hours : this.value.minutes);
            },

            getValidValues(array) {
                return array
                    .map((item) => (item.toString().length === 1 ? [$fn.toTime(item), item.toString()] : item.toString()))
                    .flat();
            },

            // @Explanation: Iteractions
            onMouseDown(e) {
                e.preventDefault();
                this.oldDigit = this.activeDigit;
                this.newDigit = null;
                this.isDragging = true;

                this.onDragMove(e);
            },

            onMouseUp(e) {
                e.stopPropagation();
                this.isDragging = false;

                if (this.newDigit !== null) this.setActiveDigit(this.newDigit);
            },

            onDragMove(e) {
                e.preventDefault();
                if ((!this.isDragging && e.type !== 'click') || !this.$refs.clock) return;

                const { width, top, left } = this.$refs.clock?.getBoundingClientRect();
                const { width: dialWidth } = this.$refs.dial?.getBoundingClientRect();
                const { clientX, clientY } = 'touches' in e ? e.touches[0] : e;

                const center = { x: width / 2, y: -width / 2 };
                const coords = { x: clientX - left, y: top - clientY };

                const trackAngle = Math.round(this.getAngle(center, coords) - 0 + 360) % 360;

                const isInsideClick =
                    this.hasActiveHours &&
                    this.has24HoursFormat &&
                    this.activeDigit &&
                    this.getEuclidean(center, coords) < (dialWidth + dialWidth * this.INNER_RADIUS_SCALE) / 4;

                const checksCount = Math.ceil(15 / this.sectorDegree);

                let value;

                for (let i = 0; i < checksCount; i++) {
                    value = this.parseAngle(trackAngle + i * this.sectorDegree, isInsideClick);
                    return this.setOldDigit(value);
                }
            },

            getAngle(center, point) {
                const dx = point.x - center.x;
                const dy = point.y - center.y;

                const value = 2 * Math.atan2(dy - this.getEuclidean(center, point), dx);

                return Math.abs((value * 180) / Math.PI);
            },

            getEuclidean(point1, point2) {
                const dx = point2.x - point1.x;
                const dy = point2.y - point1.y;

                return Math.sqrt(dx * dx + dy * dy);
            },

            parseAngle(angle, isInsideClick) {
                const count = this.hasActiveHours && this.has24HoursFormat ? this.sectorCount * 2 : this.sectorCount;
                const value = (Math.round(angle / this.sectorDegree) + (isInsideClick ? this.sectorCount : 0)) % count;

                if (angle < 360 - this.sectorDegree / 2) return value;

                return isInsideClick ? this.maxDigit - this.sectorCount : 0;
            },

            setOldDigit(value) {
                if (this.oldDigit === null) this.oldDigit = value;

                this.newDigit = value;
                this.onUpdate(value);
            },

            onUpdate(value) {
                if (this.activeDigit !== value) this.setActiveDigit(value);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .druk-c-tp {
        &__time {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            padding-bottom: 24px;
            &:last-child {
                padding-bottom: 0;
            }
        }
        &__separator {
            height: 72px;
            width: 24px;
            text-align: center;
            color: var(--druk-on-surface);
        }
        &__input {
            position: relative;
            &:hover &-state {
                background-color: var(--druk-state-layers-on-surface-0-08);
            }
            &:active &-state,
            &:focus &-state {
                background-color: var(--druk-state-layers-on-surface-0-12);
            }
            &.druk-is-active:hover &-state {
                background-color: var(--druk-state-layers-primary-0-08);
            }
            &.druk-is-active:active &-state,
            &.druk-is-active:focus &-state {
                background-color: var(--druk-state-layers-primary-0-12);
            }
            .druk-is-active &-state {
                transition: background-color var(--druk-duration-long-2) var(--druk-easing-emphasized-decelerate);
            }
            &.druk-is-active input {
                border-color: var(--druk-primary);
                color: var(--druk-tokens-time-picker-hours-input-active-color);
                background-color: var(--druk-tokens-time-picker-hours-input-active-background);
            }
            &.druk-is-active.druk-has-dial-mode input {
                border-color: transparent;
            }
            input {
                padding: 0 16px;
                width: 96px;
                height: 72px;
                text-align: center;
                border: 2px solid;
                border-color: transparent;
                border-radius: 8px;
                color: var(--druk-on-surface);
                caret-color: var(--druk-primary);
            }
            &-state {
                pointer-events: none;
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 8px;
                background-color: transparent;
                transition: background-color var(--druk-duration-short-2) var(--druk-easing-emphasized-accelerate);
            }
        }
        &__label {
            margin-top: 4px;
        }
        &__period {
            margin-left: 12px;
            width: 56px;
            height: 72px;
            border-radius: 8px;
            border: 1px solid var(--druk-outline);
            &-button {
                cursor: pointer;
                position: relative;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 50%;
                line-height: 1;
                font-weight: 500;
            }
            &-button:hover &-button-state {
                background-color: var(--druk-state-layers-on-surface-variant-0-08);
            }
            &-button:active &-button-state,
            &-button:focus &-button-state {
                background-color: var(--druk-state-layers-on-surface-variant-0-12);
            }
            &-button.druk-is-am {
                border-radius: 8px 8px 0 0;
                border-bottom: 1px solid var(--druk-outline);
            }
            &-button.druk-is-am &-button-state {
                border-radius: 8px 8px 0 0;
            }
            &-button.druk-is-pm {
                border-radius: 0 0 8px 8px;
            }
            &-button.druk-is-pm &-button-state {
                border-radius: 0 0 8px 8px;
            }
            &-button.druk-is-active {
                color: var(--druk-tokens-time-picker-period-button-active-color);
                background-color: var(--druk-tokens-time-picker-period-button-active-background);
            }
            &-button.druk-is-active:hover &-button-state {
                background-color: var(--druk-state-layers-on-tertiary-container-0-08);
            }
            &-button.druk-is-active:active &-button-state,
            &-button.druk-is-active:focus &-button-state {
                background-color: var(--druk-state-layers-on-tertiary-container-0-12);
            }
            &-button.druk-is-active &-button-state {
                transition: background-color var(--druk-duration-long-2) var(--druk-easing-emphasized-decelerate);
            }
            &-button-state {
                pointer-events: none;
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: transparent;
                transition: background-color var(--druk-duration-short-2) var(--druk-easing-emphasized-accelerate);
            }
        }
    }

    .druk-c-clock {
        &__wrapper {
            display: flex;
            flex-direction: column;
        }
        &__body {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            width: 256px;
            height: 256px;
            border-radius: 50%;
        }
        &__dial {
            position: relative;
            width: 212px;
            height: 212px;

            &-center {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 8px;
                height: 8px;
                border-radius: 100px;
                background-color: var(--druk-primary);
            }
            &-track {
                content: '';
                position: absolute;
                bottom: 50%;
                left: calc(50% - 1px);
                transform-origin: bottom center;
                width: 2px;
                height: 82px;
                background-color: var(--druk-primary);
                z-index: 1;
            }
            &-digit {
                cursor: pointer;
                user-select: none;
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 100px;
                z-index: 1;
            }
            &-digit:hover &-digit-state {
                background-color: var(--druk-state-layers-on-surface-0-08);
            }
            &-digit:active &-digit-state,
            &-digit:focus &-digit-state {
                background-color: var(--druk-state-layers-on-surface-0-12);
            }
            &-digit.druk-is-active {
                background-color: var(--druk-primary);
            }
            &-digit.druk-is-active &-digit-label {
                color: var(--druk-on-primary);
            }
            &-digit.druk-is-active &-digit-state {
                transition: background-color var(--druk-duration-long-2) var(--druk-easing-emphasized-decelerate);
            }
            &-digit.druk-is-active:hover &-digit-state {
                background-color: var(--druk-state-layers-on-primary-0-08);
            }
            &-digit.druk-is-active:active &-digit-state,
            &-digit.druk-is-active:focus &-digit-state {
                background-color: var(--druk-state-layers-on-primary-0-12);
            }
            &-digit-label {
                text-align: center;
                line-height: 1;
                color: var(--druk-on-surface);
            }
            &-digit-state {
                position: absolute;
                width: 100%;
                height: 100%;
                border-radius: 100px;
                transition: background-color var(--druk-duration-short-2) var(--druk-easing-emphasized-accelerate);
            }
        }
        &__options {
            margin: 0 auto;
            padding: 12px 0 0;
        }
    }
</style>
