<template>
    <div class="menu" ref="menu" :class="{ 'has-hide': !hasActiveDrawer, 'druk-has-inverse-styles': hasInverseDrawer }">
        <template v-for="(menuGroup, index) in selectedViewMenu">
            <ul class="menu__list" v-if="hasShowGroup(menuGroup)" :key="index">
                <li
                    v-for="menu in menuGroup"
                    :key="menu.title"
                    v-show="hasShowGroupItem(menu)"
                    class="menu__item"
                    :class="{ 'has-sub': !menu.name }"
                    :name="menu.name">
                    <template>
                        <div
                            class="menu__head druk-u-text-label-lg"
                            :class="{
                                'has-active ': checkRoute(menu.name),
                                'is-sub': !menu.name,
                            }"
                            @click="menu.name ? onHideMenu() : onToggle($event)">
                            <component
                                :is="menu.name ? 'router-link' : 'div'"
                                :to="{ name: menu.name, params: menu.params }"
                                :exact-path="true"
                                class="menu__link">
                                <druk-icon
                                    class="menu__icon"
                                    :class="{ 'has-active ': checkRoute(menu.name) }"
                                    :name="menu.icon"
                                    :color="checkRoute(menu.name) ? (!!menu.name ? activeIconColor : shownIconColor) : iconColor" />

                                <div class="menu__label">
                                    <span>{{ menu.title }}</span>
                                </div>

                                <druk-icon
                                    v-if="!menu.name"
                                    class="menu__trail"
                                    :name="'caret-down'"
                                    :color="checkRoute(menu.name) ? shownIconColor : iconColor" />
                            </component>
                        </div>
                    </template>

                    <div class="menu__sub" v-if="menu.subMenu && menu.subMenu.length">
                        <ul class="sub">
                            <li
                                v-for="sub in menu.subMenu"
                                :key="sub.title"
                                class="sub__item druk-u-text-label-lg"
                                :class="{ 'has-active ': sub.name === $route.name }"
                                :name="sub.name"
                                v-show="hasShowSubItem(sub)">
                                <tool-tip
                                    v-if="sub.tooltip"
                                    :from="'top'"
                                    :text="sub.tooltip"
                                    :maxWidth="'initial'"
                                    :hasInverseStyles="hasInverseDrawer">
                                    <router-link :to="{ name: sub.name, params: sub.params }" class="sub__link">
                                        <div class="sub__name">{{ sub.title }}</div>
                                    </router-link>
                                </tool-tip>

                                <router-link v-else :to="{ name: sub.name, params: sub.params }" class="sub__link">
                                    <div class="sub__name">{{ sub.title }}</div>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </template>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
    import { isMobile } from 'mobile-device-detect';

    export default {
        name: 'header-menu',

        computed: {
            ...mapState({
                PERM_MODULE_DASHBOARD: (state) => state.permissions.PERM_MODULE_DASHBOARD,
                PERM_MODULE_EMPLOYEES: (state) => state.permissions.PERM_MODULE_EMPLOYEES,
                PERM_MODULE_ROLES: (state) => state.permissions.PERM_MODULE_ROLES,
                PERM_MODULE_CATALOG: (state) => state.permissions.PERM_MODULE_CATALOG,
                PERM_MODULE_PRODUCTS: (state) => state.permissions.PERM_MODULE_PRODUCTS,
                PERM_MODULE_TECHNICAL_MAP: (state) => state.permissions.PERM_MODULE_TECHNICAL_MAP,
                PERM_MODULE_DETAILS: (state) => state.permissions.PERM_MODULE_DETAILS,
                PERM_MODULE_FORMATS: (state) => state.permissions.PERM_MODULE_FORMATS,
                PERM_MODULE_SETTINGS: (state) => state.permissions.PERM_MODULE_SETTINGS,
                PERM_MODULE_TYPOGRAPHIES: (state) => state.permissions.PERM_MODULE_TYPOGRAPHIES,
                PERM_MODULE_PAGES: (state) => state.permissions.PERM_MODULE_PAGES,
                PERM_MODULE_TRANSLATES: (state) => state.permissions.PERM_MODULE_TRANSLATES,
                PERM_MODULE_CLIENT_MODULES: (state) => state.permissions.PERM_MODULE_CLIENT_MODULES,
                PERM_MODULE_ROLE_TEMPLATES: (state) => state.permissions.PERM_MODULE_ROLE_TEMPLATES,
                PERM_MODULE_PARTNERS: (state) => state.permissions.PERM_MODULE_PARTNERS,
                PERM_MODULE_PARTNER_PAGES: (state) => state.permissions.PERM_MODULE_PARTNER_PAGES,
                PERM_MODULE_TRANSLATE_SCOPES: (state) => state.permissions.PERM_MODULE_TRANSLATE_SCOPES,
                PERM_MODULE_CURRENCY: (state) => state.permissions.PERM_MODULE_CURRENCY,
                PERM_MODULE_ORDERS: (state) => state.permissions.PERM_MODULE_ORDERS,
                PERM_MODULE_ORDER_COMMENTS: (state) => state.permissions.PERM_MODULE_ORDER_COMMENTS,
                PERM_MODULE_ORDER_MOCKUP: (state) => state.permissions.PERM_MODULE_ORDER_MOCKUP,
                PERM_MODULE_CLIENTS: (state) => state.permissions.PERM_MODULE_CLIENTS,
                PERM_MODULE_FEEDBACK: (state) => state.permissions.PERM_MODULE_FEEDBACK,
                PERM_MODULE_TRANSACTIONS: (state) => state.permissions.PERM_MODULE_TRANSACTIONS,
                PERM_MODULE_TM_OPERATIONS: (state) => state.permissions.PERM_MODULE_TM_OPERATIONS,
                PERM_MODULE_TM_OPERATIONS_PRE: (state) => state.permissions.PERM_MODULE_TM_OPERATIONS_PRE,
                PERM_MODULE_TM_OPERATIONS_PRINT: (state) => state.permissions.PERM_MODULE_TM_OPERATIONS_PRINT,
                PERM_MODULE_TM_OPERATIONS_POST: (state) => state.permissions.PERM_MODULE_TM_OPERATIONS_POST,
                PERM_MODULE_TM_OPERATIONS_LOGISTIC: (state) => state.permissions.PERM_MODULE_TM_OPERATIONS_LOGISTIC,
                PERM_MODULE_TM_OPERATIONS_COMMENTS: (state) => state.permissions.PERM_MODULE_TM_OPERATIONS_COMMENTS,
                PERM_MODULE_STORE: (state) => state.permissions.PERM_MODULE_STORE,
                PERM_MODULE_CONTRACTORS: (state) => state.permissions.PERM_MODULE_CONTRACTORS,
                PERM_MODULE_OPERATIONS: (state) => state.permissions.PERM_MODULE_OPERATIONS,
                PERM_MODULE_ALGORITHMS: (state) => state.permissions.PERM_MODULE_ALGORITHMS,
                PERM_MODULE_MATERIALS: (state) => state.permissions.PERM_MODULE_MATERIALS,
                PERM_MODULE_EQUIPMENTS: (state) => state.permissions.PERM_MODULE_EQUIPMENTS,
                PERM_MODULE_LIBRARY_PARAMS: (state) => state.permissions.PERM_MODULE_LIBRARY_PARAMS,
                PERM_MODULE_BLOGS: (state) => state.permissions.PERM_MODULE_BLOGS,
                PERM_MODULE_SEO: (state) => state.permissions.PERM_MODULE_SEO,
                PERM_MODULE_MAIL_TEMPLATE: (state) => state.permissions.PERM_MODULE_MAIL_TEMPLATE,
                PERM_MODULE_TYPOGRAPHY_CURRENCY: (state) => state.permissions.PERM_MODULE_TYPOGRAPHY_CURRENCY,

                PERM_ACTION_VIEW: (state) => state.permissions.PERM_ACTION_VIEW,
                PERM_ACTION_GET: (state) => state.permissions.PERM_ACTION_GET,

                BASES_MAP: (state) => state.knowledge.BASES_MAP,

                MEDIUM_ADNIMATION_DURATION: (state) => state.constant.MEDIUM_ADNIMATION_DURATION,

                hasInverseDrawer: (state) => state.hasInverseDrawer,
            }),
            ...mapGetters({
                currentTypography: 'currentTypography',

                hasERPAccess: 'hasERPAccess',
                hasBlogModule: 'typographies/modules/hasBlogModule',
                hasCustomCSSModule: 'typographies/modules/hasCustomCSSModule',
                hasKnivesModule: 'typographies/modules/hasKnivesModule',
            }),

            selectedViewMenu() {
                return this.hasERPAccess ? this.menuViewErpMap : this.menuViewTypographyMap;
            },

            menuViewErpMap() {
                return [
                    [this.menuConfig.dashboard],

                    [
                        {
                            title: this.$t('menu.command'),
                            icon: 'users',
                            subMenu: [this.menuConfig.employees, this.menuConfig.roles],
                        },
                    ],

                    [
                        {
                            title: this.$t('menu.product'),
                            icon: 'print',
                            subMenu: [this.menuConfig.categories, this.menuConfig.products],
                        },

                        {
                            title: this.$t('menu.librery'),
                            icon: 'book',
                            subMenu: [this.menuConfig.details, this.menuConfig.formats, this.menuConfig.settings],
                        },
                    ],

                    [
                        {
                            title: this.$t('menu.typog'),
                            icon: 'sitemap',
                            subMenu: [
                                this.menuConfig.typographies,
                                this.menuConfig.typographiesOwners,
                                this.menuConfig.typographiesDoppler,
                                this.menuConfig.rolesTemplates,
                                this.menuConfig.typographiesModules,
                            ],
                        },

                        this.menuConfig.partners,
                    ],

                    [
                        {
                            title: this.$t('menu.website'),
                            icon: 'screwdriver-wrench',
                            subMenu: [
                                this.menuConfig.pages,
                                this.menuConfig.translates,
                                this.menuConfig.translateScopes,
                                this.menuConfig.partnerPages,
                            ],
                        },
                    ],

                    [
                        {
                            title: this.$t('menu.knowledge'),
                            icon: 'books',
                            subMenu: [this.menuConfig.engine, this.menuConfig.partner, this.menuConfig.renewals],
                        },
                    ],
                ];
            },

            menuViewTypographyMap() {
                return [
                    [this.menuConfig.dashboard],

                    [
                        this.menuConfig.orders,
                        {
                            title: this.$t('menu.clients'),
                            icon: 'address-card',
                            subMenu: [this.menuConfig.contacts, this.menuConfig.companies],
                        },
                        this.menuConfig.feedback,
                    ],

                    [this.menuConfig.balance],

                    [
                        {
                            title: this.$t('menu.task'),
                            icon: 'clipboard',
                            subMenu: [
                                this.menuConfig.operations,
                                this.menuConfig.prepress,
                                this.menuConfig.print,
                                this.menuConfig.postpress,
                                this.menuConfig.logistic,
                            ],
                        },
                        {
                            title: this.$t('menu.product'),
                            icon: 'print',
                            subMenu: [this.menuConfig.categories, this.menuConfig.products],
                        },
                        {
                            title: this.$t('menu.stock'),
                            icon: 'archive',
                            subMenu: [
                                this.menuConfig.availability,
                                this.menuConfig.stocklog,
                                this.menuConfig.readyProducts,
                                this.menuConfig.supplier,
                                this.menuConfig.supply,
                                // this.menuConfig.inventory,
                            ],
                        },
                        this.menuConfig.contractors,
                        {
                            title: this.$t('menu.librery'),
                            icon: 'book',
                            subMenu: [
                                this.menuConfig.works,
                                this.menuConfig.algorithms,
                                this.menuConfig.materials,
                                this.menuConfig.equipments,
                                this.menuConfig.details,
                                this.menuConfig.formats,
                                this.menuConfig.knives,
                                this.menuConfig.params,
                                this.menuConfig.settings,
                            ],
                        },
                    ],

                    [
                        {
                            title: this.$t('menu.website'),
                            icon: 'screwdriver-wrench',
                            subMenu: [
                                this.menuConfig.pages,
                                this.menuConfig.blogs,
                                this.menuConfig.seo,
                                this.menuConfig.mainTemplates,
                                this.menuConfig.css,
                                this.menuConfig.translates,
                            ],
                        },
                    ],

                    [
                        {
                            title: this.$t('menu.employyer'),
                            icon: 'users',
                            subMenu: [this.menuConfig.employees, this.menuConfig.roles],
                        },
                        this.menuConfig.typography,
                    ],

                    [this.menuConfig.FAQ],
                ];
            },

            menuConfig() {
                return {
                    dashboard: {
                        title: this.$t('menu.dash'),
                        name: 'dashboard',
                        icon: 'chart-line-up',
                        // @TODO: Will bee need in the future
                        // permissionModuleName: this.PERM_MODULE_DASHBOARD,
                    },
                    orders: {
                        title: this.$t('menu.orders'),
                        name: 'orders',
                        icon: 'cart-shopping',
                        permissionModuleName: this.PERM_MODULE_ORDERS,
                    },
                    operations: {
                        title: this.$t('menu.task.all'),
                        name: 'operations',
                        icon: 'clipboard',
                        permissionModuleName: this.PERM_MODULE_TM_OPERATIONS,
                    },
                    prepress: {
                        title: this.$t('menu.task.pre'),
                        name: 'prepress',
                        permissionModuleName: this.PERM_MODULE_TM_OPERATIONS_PRE,
                    },
                    print: {
                        title: this.$t('menu.task.print'),
                        name: 'print',
                        permissionModuleName: this.PERM_MODULE_TM_OPERATIONS_PRINT,
                    },
                    postpress: {
                        title: this.$t('menu.task.post'),
                        name: 'postpress',
                        permissionModuleName: this.PERM_MODULE_TM_OPERATIONS_POST,
                    },
                    logistic: {
                        title: this.$t('menu.task.logistic'),
                        name: 'logistic',
                        permissionModuleName: this.PERM_MODULE_TM_OPERATIONS_LOGISTIC,
                    },
                    balance: {
                        title: this.$t('menu.balance'),
                        name: 'balance',
                        icon: 'money-bill',
                        permissionModuleName: this.PERM_MODULE_TRANSACTIONS,
                    },
                    feedback: {
                        title: this.$t('menu.romutations'),
                        name: 'feedback',
                        icon: 'envelope',
                        permissionModuleName: this.PERM_MODULE_FEEDBACK,
                    },
                    categories: {
                        title: this.$t('menu.category'),
                        name: 'categories',
                        permissionModuleName: this.PERM_MODULE_CATALOG,
                    },
                    products: {
                        title: this.$t('menu.product.alt'),
                        name: 'products',
                        permissionModuleName: this.PERM_MODULE_PRODUCTS,
                    },
                    employees: {
                        title: this.hasERPAccess ? this.$t('menu.erp.employees') : this.$t('menu.employyers.alt'),
                        name: 'employees',
                        icon: 'user',
                        permissionModuleName: this.PERM_MODULE_EMPLOYEES,
                    },
                    contacts: {
                        title: this.$t('menu.contacts'),
                        name: 'clients',
                        icon: 'user',
                        permissionModuleName: this.PERM_MODULE_CLIENTS,
                    },
                    companies: {
                        title: this.$t('menu.companies'),
                        name: 'companies',
                        icon: 'building-user',
                        permissionModuleName: this.PERM_MODULE_CLIENTS,
                    },
                    typographies: {
                        title: this.$t('menu.typography'),
                        name: 'typographies',
                        permissionModuleName: this.PERM_MODULE_TYPOGRAPHIES,
                    },
                    typographiesOwners: {
                        title: this.$t('menu.typographies.owners'),
                        name: 'owners',
                        permissionModuleName: this.PERM_MODULE_EMPLOYEES,
                    },
                    typographiesDoppler: {
                        title: this.$t('menu.typographies.doppler'),
                        name: 'doppler',
                        permissionModuleName: this.PERM_MODULE_EMPLOYEES,
                    },
                    typographiesModules: {
                        title: this.$t('menu.clientModules'),
                        name: 'modules',
                        permissionModuleName: this.PERM_MODULE_CLIENT_MODULES,
                    },
                    typography: {
                        title: this.$t('menu.typography.settings'),
                        name: this.hasERPAccess ? 'typographies' : 'typographies_item',
                        params: { id: this.currentTypography?.id },
                        icon: 'gear',
                        permissionModuleName: this.PERM_MODULE_TYPOGRAPHIES,
                    },
                    partners: {
                        title: this.$t('menu.partners'),
                        name: 'partners',
                        icon: 'business-time',
                        permissionModuleName: this.PERM_MODULE_PARTNERS,
                    },
                    translates: {
                        title: this.$t('menu.translates'),
                        name: 'translates',
                        permissionModuleName: this.PERM_MODULE_TRANSLATES,
                    },
                    pages: {
                        title: this.$t('menu.pages'),
                        name: 'pages',
                        permissionModuleName: this.PERM_MODULE_PAGES,
                    },
                    blogs: {
                        title: this.$t('menu.blogs'),
                        name: 'blogs',
                        permissionModuleName: this.PERM_MODULE_BLOGS,
                        accessModule: this.hasBlogModule,
                    },
                    seo: {
                        title: this.$t('menu.seo'),
                        name: 'seo',
                        permissionModuleName: this.PERM_MODULE_SEO,
                    },
                    mainTemplates: {
                        title: this.$t('menu.mainTemplates'),
                        name: 'mail-templates',
                        permissionModuleName: this.PERM_MODULE_MAIL_TEMPLATE,
                    },
                    partnerPages: {
                        title: this.$t('menu.partnerPages'),
                        name: 'partner_pages',
                        permissionModuleName: this.PERM_MODULE_PARTNER_PAGES,
                    },
                    css: {
                        title: this.$t('menu.css'),
                        name: 'css',
                        permissionModuleName: this.PERM_MODULE_TYPOGRAPHIES,
                        accessModule: this.hasCustomCSSModule,
                    },
                    works: {
                        title: this.$t('menu.works'),
                        name: 'works',
                        permissionModuleName: this.PERM_MODULE_OPERATIONS,
                    },
                    contractors: {
                        title: this.$t('menu.contractors'),
                        name: 'contractors',
                        icon: 'briefcase',
                        permissionModuleName: this.PERM_MODULE_CONTRACTORS,
                    },
                    algorithms: {
                        title: this.$t('menu.algorithms'),
                        name: 'algorithms',
                        permissionModuleName: this.PERM_MODULE_ALGORITHMS,
                    },
                    details: {
                        title: this.$t('menu.details'),
                        name: 'details',
                        permissionModuleName: this.PERM_MODULE_DETAILS,
                    },
                    materials: {
                        title: this.$t('menu.materials'),
                        name: 'materials',
                        permissionModuleName: this.PERM_MODULE_MATERIALS,
                    },
                    equipments: {
                        title: this.$t('menu.equipments'),
                        name: 'equipments',
                        permissionModuleName: this.PERM_MODULE_EQUIPMENTS,
                    },
                    formats: {
                        title: this.$t('menu.formats'),
                        name: 'formats',
                        permissionModuleName: this.PERM_MODULE_FORMATS,
                    },
                    knives: {
                        title: this.$t('menu.knives'),
                        name: 'knives',
                        permissionModuleName: this.PERM_MODULE_TECHNICAL_MAP,
                        accessModule: this.hasKnivesModule,
                    },
                    roles: {
                        title: this.hasERPAccess ? this.$t('menu.erp.roles') : this.$t('menu.roles'),
                        name: 'roles',
                        permissionModuleName: this.PERM_MODULE_ROLES,
                    },
                    rolesTemplates: {
                        title: this.$t('menu.role_templates'),
                        name: 'role_templates',
                        permissionModuleName: this.PERM_MODULE_ROLE_TEMPLATES,
                    },
                    translateScopes: {
                        title: this.$t('menu.translateScopes'),
                        name: 'translate_scopes',
                        permissionModuleName: this.PERM_MODULE_TRANSLATE_SCOPES,
                    },
                    settings: {
                        title: this.$t('menu.settings'),
                        tooltip: this.$t('menu.settings.tooltip'),
                        name: 'settings',
                        permissionModuleName: this.PERM_MODULE_SETTINGS,
                    },
                    params: {
                        title: this.$t('menu.params'),
                        name: 'params',
                        permissionModuleName: this.PERM_MODULE_LIBRARY_PARAMS,
                    },
                    availability: {
                        title: this.$t('menu.availability'),
                        name: 'availability',
                        permissionModuleName: this.PERM_MODULE_STORE,
                    },
                    inventory: {
                        title: this.$t('menu.inventory'),
                        name: 'inventory',
                        permissionModuleName: this.PERM_MODULE_STORE,
                    },
                    stocklog: {
                        title: this.$t('menu.stocklog'),
                        name: 'stocklog',
                        permissionModuleName: this.PERM_MODULE_STORE,
                    },
                    readyProducts: {
                        title: this.$t('menu.ready_products'),
                        name: 'ready_products',
                        permissionModuleName: this.PERM_MODULE_STORE,
                    },
                    supplier: {
                        title: this.$t('menu.supplier'),
                        name: 'supplier',
                        permissionModuleName: this.PERM_MODULE_STORE,
                    },
                    supply: {
                        title: this.$t('menu.supply'),
                        name: 'supply',
                        permissionModuleName: this.PERM_MODULE_STORE,
                    },
                    engine: {
                        title: this.$t('menu.knowledge_engine'),
                        name: 'knowledge_engine',
                        params: { base: this.BASES_MAP.engine.alias },
                    },
                    partner: {
                        title: this.$t('menu.knowledge_partner'),
                        name: 'knowledge_partner',
                        params: { base: this.BASES_MAP.partner.alias },
                    },
                    renewals: {
                        title: this.$t('menu.knowledge_renewals'),
                        name: 'knowledge_renewals',
                        params: { base: this.BASES_MAP.renewals.alias },
                    },
                    FAQ: {
                        title: this.$t('menu.faq'),
                        name: 'FAQ',
                        icon: 'messages-question',
                    },
                };
            },

            iconColor() {
                return this.hasInverseDrawer ? 'inverse-on-surface-variant' : 'on-surface-variant';
            },

            activeIconColor() {
                return this.hasInverseDrawer ? 'inverse-on-secondary-container' : 'on-secondary-container';
            },

            shownIconColor() {
                return this.hasInverseDrawer ? 'inverse-on-surface' : 'on-surface';
            },

            hasActiveDrawer: {
                get() {
                    return this.$store.state.hasActiveDrawer;
                },
                set(status) {
                    this.SET_DRAWER_STATUS(status);

                    this.$bus.$emit('set-table-scroll', this.MEDIUM_ADNIMATION_DURATION);
                    this.$bus.$emit('set-table-loader', this.MEDIUM_ADNIMATION_DURATION);
                    this.$bus.$emit('set-fixed-drop-position', this.MEDIUM_ADNIMATION_DURATION);
                    this.$bus.$emit('update-wizard-styles', this.MEDIUM_ADNIMATION_DURATION);
                },
            },
        },

        watch: {
            hasActiveDrawer() {
                if (this.hasActiveDrawer === false) {
                    document.querySelectorAll('.menu__item').forEach((el) => {
                        el.classList.remove('has-active');
                    });
                }
            },
        },

        mounted() {
            this.initMenu();
        },

        methods: {
            ...mapMutations({
                SET_DRAWER_STATUS: 'SET_DRAWER_STATUS',
            }),

            initMenu() {
                let menuEl = this.$refs['menu'];

                this.setActiveRoute(menuEl);
            },

            onHideMenu() {
                this.resetActiveLists();
                if (isMobile) this.hasActiveDrawer = false;
            },

            resetActiveLists() {
                document.querySelectorAll('.is-sub, .has-sub').forEach((head) => head.classList.remove('has-active'));
            },

            onToggle(e) {
                let menuItem = e.target.closest('.menu__item'),
                    isCurrentActive = e.target.closest('.menu__item').classList.contains('has-active');

                this.hasActiveDrawer = true;

                if (isCurrentActive) return menuItem.classList.remove('has-active');

                this.resetActiveLists();
                menuItem.classList.add('has-active');
            },

            hasShowGroup(group) {
                let status = false;

                group.forEach((groupItem) => {
                    if (this.hasShowGroupItem(groupItem)) status = true;
                });

                return status;
            },

            hasShowGroupItem(groupItem) {
                let status = false;
                let linkList = [];

                if (groupItem.name) {
                    linkList.push(groupItem);
                } else if (groupItem.subMenu && groupItem.subMenu.length) {
                    linkList = linkList.concat(groupItem.subMenu);
                }

                linkList.forEach((link) => {
                    if (link.permissionModuleName === undefined) {
                        status = true;
                    } else if ($fn.hasAccess(link.permissionModuleName, this.PERM_ACTION_VIEW)) {
                        status = true;
                    }
                });

                return status;
            },

            hasShowSubItem(sub) {
                let status = false;

                if (sub.permissionModuleName === undefined) {
                    status = !sub.hasOwnProperty('accessModule') ? true : sub.accessModule;
                } else if ($fn.hasAccess(sub.permissionModuleName, this.PERM_ACTION_VIEW)) {
                    status = !sub.hasOwnProperty('accessModule') ? true : sub.accessModule;
                }
                return status;
            },

            setActiveRoute(menu) {
                let routes = this.$route.matched;

                menu.querySelectorAll('.menu__item').forEach((item) => {
                    const subList = item.querySelectorAll('.sub__item');
                    const isParentRoute = routes.find(
                        (route) =>
                            route.name === item.getAttribute('name') || this.$route.meta.navigation === item.getAttribute('name'),
                    );

                    if (isParentRoute) {
                        item.classList.add('has-active');
                        item.querySelector('.menu__head')?.classList.add('has-active');
                    }

                    if (subList.length)
                        subList.forEach((sub) => {
                            if (routes.find((route) => route.name === sub.getAttribute('name'))) {
                                item.classList.add('has-active');
                                item.querySelector('.menu__head')?.classList.add('has-active');
                                sub.classList.add('has-active');
                            }
                        });
                });
            },

            checkRoute(name) {
                return name && (name === this.$route.name || name === this.$route.meta.navigation);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .menu {
        &.has-hide {
            .menu__link {
                justify-content: center;
                padding: 0px;
            }
            .menu__trail {
                display: none;
                opacity: 0;
            }
            .menu__label {
                margin-left: -150px;
                opacity: 0;
            }
        }
        &__fab {
            margin-bottom: var(--druk-gap-l-a);
        }
        &__list {
            margin-bottom: var(--druk-gap-l-a);
            &:last-child {
                margin-bottom: 0;
            }
        }
        &__item {
            border-radius: 100px;
            &.has-active {
                .menu__sub {
                    max-height: 500px;
                    opacity: 1;
                    visibility: visible;
                }
                .is-sub {
                    background-color: var(--druk-state-layers-secondary-0-08);
                    .menu__icon {
                        opacity: 1;
                        &.druk-c-icon {
                            &::v-deep svg {
                                opacity: 1;
                            }
                        }
                    }
                    .menu__trail {
                        opacity: 1;
                        transform: rotate(180deg);
                    }
                    span {
                        color: var(--druk-on-surface);
                    }
                }
            }
        }
        &__link {
            cursor: pointer;
            position: relative;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 48px;
            border-radius: 100px;
            padding: 0 var(--druk-gap-l);
            transition: background 0.2s ease-in-out;
            &:hover {
                background: var(--druk-state-layers-on-surface-0-08);
            }
            &:active {
                background: var(--druk-state-layers-on-surface-0-12);
            }
        }
        &__head {
            position: relative;
            border-radius: 100px;
            transition: transform 0.2s ease-in-out;
            &.has-active {
                background: var(--druk-secondary-container);
                span {
                    font-weight: 600;
                    color: var(--druk-on-secondary-container);
                }
            }
            &.has-active.is-sub {
                background-color: var(--druk-state-layers-secondary-0-08);
                span {
                    color: var(--druk-on-surface);
                }
            }
        }
        &__icon,
        &__trail {
            &.druk-c-icon {
                &::v-deep svg {
                    opacity: 0.38;
                }
            }
        }
        &__icon.has-active {
            &.druk-c-icon {
                &::v-deep svg {
                    opacity: 1;
                }
            }
        }
        &__label {
            position: absolute;
            left: 52px;
            color: var(--druk-on-surface-variant);
            transform: scaleX(1);
            transition: transform 0.2s ease-in-out;
        }
        &__sub {
            max-height: 0;
            opacity: 0;
            visibility: hidden;
        }
        &__trail {
            opacity: 0.5;
            transform: rotate(0deg);
            transform-origin: center;
            transition:
                transform 0.2s ease-in-out,
                opacity 0.2s ease-in-out;
        }
    }

    .sub {
        &__item {
            border-radius: 100px;
            &.has-active {
                background: var(--druk-secondary-container);
                a {
                    font-weight: 600;
                    color: var(--druk-on-secondary-container);
                }
            }
        }
        &__link {
            display: flex;
            align-items: center;
            overflow: hidden;
            padding-left: var(--druk-gap-l-a);
            height: 48px;
            border-radius: 100px;
            transition: background-color 0.2s ease-in-out;
            &:hover {
                background: var(--druk-state-layers-on-surface-0-08);
            }
            &:active {
                background: var(--druk-state-layers-on-surface-0-12);
            }
        }
        &__name {
            position: relative;
            display: block;
            margin-left: var(--druk-gap-2xl);
            white-space: nowrap;
            color: var(--druk-on-surface-variant);
        }
    }

    .router-link-active {
        background: var(--druk-secondary-container);
        a,
        div {
            color: var(--druk-on-secondary-container);
        }
        .menu__icon {
            &::v-deep svg {
                opacity: 1;
                // color: var(--druk-on-secondary-container);
            }
        }
    }

    a {
        &:hover {
            color: initial;
            background-color: initial;
        }
    }

    .druk-has-inverse-styles {
        .menu__head.has-active span,
        .sub__item.has-active a,
        .router-link-active div,
        .router-link-active .menu__icon svg {
            color: var(--druk-inverse-on-secondary-container);
        }
        .menu__item.has-active .is-sub {
            background-color: var(--druk-inverse-tint-surface-container-brighter);
        }
        .menu__item.has-active .is-sub .menu__link .menu__label span {
            color: var(--druk-inverse-on-surface);
        }
        .menu__head.has-active,
        .menu__link.router-link-active,
        .router-link-active,
        .sub__item.has-active {
            background-color: var(--druk-inverse-secondary-container);
        }
        .menu__link:hover,
        .sub__link:hover,
        .router-link-active:hover {
            background: var(--druk-state-layers-inverse-on-surface-0-08);
        }
        .menu__link:active,
        .sub__link:active {
            background: var(--druk-state-layers-inverse-on-surface-0-12);
        }
        .menu__label,
        .sub__name,
        .router-link-active .menu__label {
            color: var(--druk-inverse-on-surface-variant);
        }
    }
</style>
