<template>
    <div class="vmodal">
        <div class="vmodal__container">
            <div class="vmodal__head">
                <div class="vmodal__title">
                    <div class="vmodal__title-text">
                        {{ isEditMode ? this.$t('btn.edit') : this.$t('btn.create') }}
                        {{ $t('typog.modal.title') }}
                    </div>
                </div>

                <druk-icon-button :type="'standard'" :icon="'xmark'" @click="$emit('close')" />
            </div>

            <div class="vmodal__main">
                <div class="druk-l-form">
                    <div class="druk-l-form__body">
                        <div class="druk-l-grid">
                            <div class="druk-l-grid__col-6 druk-u-def-12 druk-u-def-gap-xl">
                                <div class="druk-l-grid">
                                    <div
                                        :class="{
                                            'druk-l-grid__col-6 druk-u-md-12': hasERPAccess,
                                            'druk-l-grid__col-12': !hasERPAccess,
                                        }">
                                        <div class="druk-l-form__item">
                                            <form-input
                                                v-model="formData.title"
                                                :label="$t('form.title')"
                                                rules="required"
                                                :surface="'tint-brighter'"
                                                name="title" />
                                        </div>
                                    </div>

                                    <div class="druk-l-grid__col-6 druk-u-md-12">
                                        <div
                                            class="druk-l-form__item"
                                            :class="{ 'druk-is-before-tabs': !hasTypographyAccess, 'druk-is-disabled': isEditMode }">
                                            <form-input
                                                v-model="formData.alias"
                                                :label="$t('form.alias')"
                                                rules="required"
                                                :surface="'tint-brighter'"
                                                name="alias" />
                                        </div>
                                    </div>
                                </div>

                                <template v-if="hasTypographyAccess">
                                    <form-edit-languages :selectedLang="selectedLang" />

                                    <div class="druk-l-form__item">
                                        <form-select
                                            v-model="formData.client_account_languages"
                                            :label="$t('typographies.validator_alpabets.client_names')"
                                            :options="alphabets"
                                            :hint="{
                                                from: 'top-left',
                                                text: $t('typographies.validator_alpabets.prompt'),
                                                maxWidth: '280px',
                                                isSide: true,
                                            }"
                                            :name="'required'"
                                            :surface="'tint-brighter'"
                                            :multiple="true" />
                                    </div>

                                    <form-edit-currency />
                                </template>

                                <form-language-tabs
                                    :languages="languages"
                                    :formData="formData"
                                    :requiredFields="'city'"
                                    :selectedLang="selectedLang"
                                    @change="selectedLang = $event" />

                                <div
                                    v-for="language in languages"
                                    :key="language.code"
                                    v-show="language.code === selectedLang"
                                    class="druk-l-form__item"
                                    :class="{ 'druk-is-last': !hasERPAccess }">
                                    <form-input
                                        v-model="formData.city[language.code]"
                                        :label="$t('form.city')"
                                        rules="required"
                                        :surface="'tint-brighter'"
                                        name="city" />
                                </div>

                                <template v-if="hasERPAccess">
                                    <div class="druk-l-form__item">
                                        <form-input
                                            v-model="formData.email"
                                            :label="$t('form.email')"
                                            rules="required"
                                            :surface="'tint-brighter'"
                                            name="email" />
                                    </div>

                                    <template v-if="!isEditMode">
                                        <div class="druk-l-form__item">
                                            <form-input
                                                v-model="formData.domain"
                                                :label="$t('typog.form.domen')"
                                                rules="required"
                                                :surface="'tint-brighter'"
                                                name="domain" />
                                        </div>

                                        <div class="druk-l-form__item">
                                            <form-select
                                                v-model="formData.language"
                                                :label="$t('domains.main_website_language')"
                                                :options="clientLanguages"
                                                rules="required"
                                                :surface="'tint-brighter'"
                                                name="language" />
                                        </div>
                                    </template>

                                    <div class="druk-l-form__item" :class="{ 'has-loading': loadingGetCurrencies }">
                                        <form-select
                                            v-model="formData.currency"
                                            :label="$t('typography.save.default_currency')"
                                            :options="currencies"
                                            rules="required"
                                            :surface="'tint-brighter'"
                                            name="currency" />
                                    </div>

                                    <div class="druk-l-form__item druk-is-last">
                                        <form-select
                                            v-model="formData.pricing_plan"
                                            :label="$t('pricing_plan.title')"
                                            :options="pricingPlans"
                                            :option_label="'name'"
                                            :notEmpty="true"
                                            :rules="'required'"
                                            :surface="'tint-brighter'"
                                            name="pricing_plan" />

                                        <div class="druk-l-form__support">
                                            <druk-support>
                                                <span
                                                    >{{ $t('pricing_plan.employees_limit') }}:
                                                    <b>{{ formData.pricing_plan.employees || $t('pricing_plan.unlimited') }}</b></span
                                                >
                                            </druk-support>
                                        </div>
                                    </div>
                                </template>
                            </div>

                            <div v-if="hasERPAccess" class="druk-l-grid__col-6 druk-u-def-12">
                                <div class="druk-l-form__block druk-is-last">
                                    <div class="druk-l-card-m">
                                        <div class="druk-l-card__header">
                                            <div class="druk-l-card__title">
                                                <div class="druk-l-card__title-text">{{ $t('typog.main.sub') }}</div>
                                            </div>
                                        </div>

                                        <div class="druk-l-chips">
                                            <div v-for="(item, index) in modulesData" :key="index" class="druk-l-chips__item">
                                                <druk-chip
                                                    :index="index"
                                                    :icon="'check'"
                                                    :label="item.title || item.alias"
                                                    :isControll="true"
                                                    :isNeutral="item.disabled"
                                                    :hasDoubleColorMode="true"
                                                    @click="onToggleModule(index)" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="druk-l-form__footer">
                        <div class="druk-l-form__footer-item">
                            <druk-button
                                :icon="'floppy-disk'"
                                :label="isEditMode ? $t('btn.save') : $t('btn.create')"
                                :isWaiting="loadingSaveItem"
                                @click="onSave" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

    import FormEditLanguages from './FormEditLanguages';
    import FormEditCurrency from './FormEditCurrency';

    export default {
        name: 'typography-save',

        components: {
            FormEditLanguages,
            FormEditCurrency,
        },

        props: {
            item: Object,
        },

        created() {
            this.$bus.$on('on-set-form-language', (lang) => (this.selectedLang = lang));
            this.buildData();
        },

        destroyed() {
            this.$bus.$off('on-set-form-language');
        },

        data() {
            return {
                selectedLang: this.$store.state.selectedLang,

                formData: {
                    title: null,
                    alias: null,
                    domain: null,
                    language: null,
                    currency: null,
                    bitrix_domain: null,
                    preflight_domain: null,
                    pricing_plan: null,
                    client_account_languages: [],
                    email: null,
                    color: null,
                    city: {},

                    geo_location: '',
                    address: {},
                    phones: [],
                    socials: [],
                    schedules: [],
                },

                modulesData: [],
            };
        },

        computed: {
            ...mapState({
                TYPOGRAPHY_CLASSIC_COLOR: (state) => state.constant.TYPOGRAPHY_CLASSIC_COLOR,

                alphabets: (state) => state.typographies.domains.alphabets,
                modules: (state) => state.typographies.modules.list,
                itemModules: (state) => state.typographies.modules.typographyList,
                clientLanguages: (state) => state.languages.clientList,
                systemLanguages: (state) => state.languages.systemList,
                systemPickedLanguages: (state) => state.languages.systemPickedList,
                currencies: (state) => state.currency.list,
                systemLanguagesData: (state) => state.languages.systemFormData,
                pricingPlans: (state) => state.pricing.plans.list,

                loadingGetCurrencies: (state) => state.currency.loadingGetList,
                loadingSaveItem: (state) => state.typographies.loadingSaveItem,
                loadingSaveModule: (state) => state.typographies.modules.loadingSaveItem,
                loadingDeleteModule: (state) => state.typographies.modules.loadingDeleteItem,
            }),

            ...mapGetters({
                hasERPAccess: 'hasERPAccess',
                hasTypographyAccess: 'hasTypographyAccess',
            }),

            languages() {
                return this.hasERPAccess ? this.systemLanguages : this.systemLanguagesData.languages;
            },

            activeModules() {
                return this.modulesData.filter((module) => !module.disabled).map((module) => ({ alias: module.alias }));
            },

            disabledModules() {
                return this.modulesData.filter((module) => module.disabled).map((module) => ({ alias: module.alias }));
            },

            isEditMode() {
                return !!this.item;
            },
        },

        methods: {
            ...mapActions({
                SAVE_ITEM: 'typographies/SAVE_ITEM',
                SAVE_ITEM_MODULES: 'typographies/modules/SAVE_TYPOGRAPHY_LIST',
            }),

            buildData() {
                this.setFormData();
                this.setModulesData();
            },

            setFormData() {
                Object.assign(
                    this.formData,
                    { ...(this.item || {}) },
                    {
                        address: $fn.dataPropLocalize({ languages: this.languages, source: this.item?.address_translations }),
                        city: $fn.dataPropLocalize({ languages: this.languages, source: this.item?.city_translations }),
                        pricing_plan: this.item?.pricing_plan || [...this.pricingPlans].shift(),
                    },
                );
            },

            setModulesData() {
                this.modulesData = this.modules.map((module) => {
                    const typographyModule = this.itemModules.find((itemModule) => itemModule.typography_module_id === module.id);

                    return {
                        ...module,
                        disabled: !typographyModule || typographyModule.disabled,
                    };
                });
            },

            onToggleModule(index) {
                this.modulesData[index].disabled = !this.modulesData[index].disabled;
            },

            async onSave(e) {
                let result = await this.$validator.validate();

                if (result) {
                    try {
                        if (this.hasTypographyAccess) {
                            this.$bus.$emit('on-save-system-languages');
                            this.$bus.$emit('on-save-typography-currencies');
                        }

                        const item = (
                            await this.SAVE_ITEM({
                                ...this.formData,

                                id: this.isEditMode ? this.item.id : null,
                                color: this.item?.color || this.TYPOGRAPHY_CLASSIC_COLOR,
                                lang_id: this.formData.language?.id || null,
                                currency_id: this.formData.currency.id,
                                pricing_plan_id: this.formData.pricing_plan?.id || null,
                                client_account_languages: this.formData.client_account_languages?.map((language) => language.id) || [],
                            })
                        ).single;

                        if (!this.isEditMode) this.$noty.success(this.$t('typog.msg.create'));

                        this.updateItemModules(item);
                        this.$emit('close');
                    } catch (e) {
                        $fn.setValidateErrors(e, this.errors);
                    }
                } else {
                    $fn.showFormError(e.target.closest('.druk-c-btn'), this.$validator.errors.items);
                }
            },

            updateItemModules(item) {
                this.SAVE_ITEM_MODULES({
                    typographyId: item.id,
                    formData: {
                        activeList: { modules: this.activeModules },
                        disabledList: { modules: this.disabledModules },
                    },
                });
            },
        },
    };
</script>

<style lang="scss" scoped></style>
