import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        LAYOUT_OPTIONS: [
            {
                translation_alias: 'params.title.material.param',
                alias: 'material-params',
                icon: 'box',
            },
            {
                translation_alias: 'params.title.material.options',
                alias: 'material-param-options',
                icon: 'box-taped',
            },
            {
                translation_alias: 'params.title.material.variable',
                alias: 'material-variables',
                icon: 'box-dollar',
            },
            {
                translation_alias: 'params.title.equipment.param',
                alias: 'equipment-params',
                icon: 'gear',
            },
            {
                translation_alias: 'params.title.equipment.options',
                alias: 'equipment-param-options',
                icon: 'gear-complex',
            },
            {
                translation_alias: 'params.title.equipment.variable',
                alias: 'equipment-variables',
                icon: 'gear-complex-code',
            },
        ],

        LAYOUT_MATERIAL_PARAMS: 'material-params',
        LAYOUT_MATERIAL_PARAM_OPTIONS: 'material-param-options',
        LAYOUT_MATERIAL_VARIABLES: 'material-variables',
        LAYOUT_EQUIPMENT_PARAMS: 'equipment-params',
        LAYOUT_EQUIPMENT_PARAM_OPTIONS: 'equipment-param-options',
        LAYOUT_EQUIPMENT_VARIABLES: 'equipment-variables',

        layout: null,
    },

    getters: {
        hasMaterialParamsLayout(state) {
            return state.layout?.alias === state.LAYOUT_MATERIAL_PARAMS;
        },

        hasMaterialParamOptionsLayout(state) {
            return state.layout?.alias === state.LAYOUT_MATERIAL_PARAM_OPTIONS;
        },

        hasMaterialVariablesLayout(state) {
            return state.layout?.alias === state.LAYOUT_MATERIAL_VARIABLES;
        },

        hasEquipmentParamsLayout(state) {
            return state.layout?.alias === state.LAYOUT_EQUIPMENT_PARAMS;
        },

        hasEquipmentParamOptionsLayout(state) {
            return state.layout?.alias === state.LAYOUT_EQUIPMENT_PARAM_OPTIONS;
        },

        hasEquipmentVariablesLayout(state) {
            return state.layout?.alias === state.LAYOUT_EQUIPMENT_VARIABLES;
        },
    },

    mutations: {
        SET_LAYOUT(state, layout) {
            state.layout = layout;
        },
    },
};
