import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

import CalcRanges from '../components/CalcRanges';
import CalcCurve from '../components/CalcCurve';
import CalcFormula from '../components/CalcFormula';

export default {
    components: {
        CalcRanges,
        CalcCurve,
        CalcFormula,
    },

    data() {
        return {
            KEY_FORMULA_TRANSLATIONS_MAP: {
                formula: 'common.price_genitive_sm',
                time_formula: 'common.time_genitive_sm',
                weight_formula: 'common.weight_genitive_sm',
            },
        };
    },

    created() {
        if (!this.mode) this.setCommonData();
    },

    computed: {
        ...mapState({
            CALCULATION_MODES: (state) => state.calcModes.CALCULATION_MODES,
            CALCULATION_MODE_FORMULA: (state) => state.calcModes.CALCULATION_MODE_FORMULA,

            EQUIPMENT_EDITOR_TYPE: (state) => state.calcModes.formula.EQUIPMENT_EDITOR_TYPE,
            MATERIAL_EDITOR_TYPE: (state) => state.calcModes.formula.MATERIAL_EDITOR_TYPE,

            mode: (state) => state.calcModes.mode,
        }),
        ...mapGetters({
            hasRangesCalcMode: 'calcModes/hasRangesCalcMode',
            hasCurveCalcMode: 'calcModes/hasCurveCalcMode',
            hasFormulaCalcMode: 'calcModes/hasFormulaCalcMode',
        }),

        tModesList() {
            return $fn.tArray(this.CALCULATION_MODES);
        },

        currentMode: {
            get() {
                return this.mode;
            },
            set(value) {
                this.SET_MODE(value);
            },
        },

        isEquipmentType() {
            return this.type === this.EQUIPMENT_EDITOR_TYPE;
        },

        isMaterialType() {
            return this.type === this.MATERIAL_EDITOR_TYPE;
        },
    },

    methods: {
        ...mapMutations({
            SET_MODE: 'calcModes/SET_MODE',
        }),

        setCommonData() {
            let mode = this.isMaterialType
                ? this.tModesList.find((mode) => mode.value === this.CALCULATION_MODE_FORMULA)
                : [...this.tModesList].shift();

            this.SET_MODE(mode);
        },
    },
};
