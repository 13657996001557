<template>
    <div class="druk-l-file-ctrl">
        <div class="druk-l-file-ctrl__wrapper">
            <div
                class="druk-l-file-ctrl__body"
                :class="{
                    'druk-has-pagination': hasOneMoreMockups,
                    'druk-has-hover': isControllAvailable,
                    'druk-has-static': !hasMockupAccess || !hasActiveCtrls || isBlocked,
                    'druk-is-disabled': isDisabled,
                }"
                @mouseover="onHover(true)"
                @mouseout="onHover(false)"
                @click="hasActiveCtrls ? $emit('controll') : null">
                <swiper ref="swiper" class="druk-l-file-ctrl__swiper" :options="swiperOptions">
                    <template v-if="hasMockups">
                        <swiper-slide v-for="(mockup, index) in mockups" :key="index" class="druk-l-file-ctrl__slide">
                            <div v-if="mockup.destination_custom" class="druk-l-file-ctrl__icon">
                                <druk-icon :icon="'link'" :size="'full'" :color="'on-surface'" />
                            </div>

                            <div v-else-if="!mockup.preview_original && !mockup.destination_original" class="druk-l-file-ctrl__icon">
                                <druk-icon :name="'empty-set'" :size="'xl'" :color="'on-surface'" />
                            </div>

                            <img v-else :src="mockup.preview_original || mockup.destination_original" @error="$fn.fixImageError" />

                            <div class="druk-l-file-ctrl__slide-scaler"></div>
                        </swiper-slide>

                        <div v-if="hasOneMoreMockups" class="swiper-pagination" slot="pagination"></div>
                    </template>

                    <swiper-slide v-else>
                        <img
                            class="druk-l-file-ctrl__placeholder"
                            :src="placeholder || require('@img/placeholder.svg')"
                            @error="$fn.fixImageError" />
                    </swiper-slide>
                </swiper>

                <div
                    v-if="hasActiveCtrls && hasMockupAccess"
                    class="druk-l-file-ctrl__ctrl"
                    :class="{ 'druk-has-pagination': hasOneMoreMockups }">
                    <div class="druk-l-file-ctrl__button">
                        <druk-icon-button :type="'elevated'" :icon="'swatchbook'" />
                    </div>

                    <div v-if="label" class="druk-l-file-ctrl__prompt druk-l-card-xs">
                        <span>{{ label }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';

    export default {
        name: 'card-file-controll',

        props: {
            mockups: {
                type: Array,
                default: () => {
                    return [];
                },
            },

            placeholder: String,
            icon: String,
            label: String,

            hasActiveCtrls: Boolean,
            hasProductLinks: Boolean,
            hasNoHover: Boolean,

            isBlocked: Boolean,
            isDisabled: [Boolean, Number],
        },

        data() {
            return {
                hasHovering: false,
            };
        },

        computed: {
            ...mapState({
                PERM_MODULE_ORDER_MOCKUP: (state) => state.permissions.PERM_MODULE_ORDER_MOCKUP,
                PERM_ACTION_VIEW: (state) => state.permissions.PERM_ACTION_VIEW,
            }),

            swiperOptions() {
                return {
                    speed: 300,

                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                    },

                    slidesPerView: 1,
                    initialSlide: 0,

                    grabCursor: false,
                    perspective: false,
                    centeredSlides: true,
                    allowTouchMove: this.hasOneMoreMockups,
                };
            },

            hasMockups() {
                return this.mockups?.length;
            },

            hasOneMoreMockups() {
                return this.mockups?.length > 1;
            },

            hasMockupAccess() {
                return $fn.hasAccess(this.PERM_MODULE_ORDER_MOCKUP, this.PERM_ACTION_VIEW);
            },

            isControllAvailable() {
                return this.hasHovering && this.hasActiveCtrls && !this.isBlocked;
            },
        },

        methods: {
            onHover(value) {
                this.hasHovering = this.hasMockupAccess && !this.hasNoHover ? value : false;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .druk-l-file-ctrl {
        height: auto;
        &__wrapper {
            height: 100%;
        }
        &__body {
            cursor: pointer;
            position: relative;
            height: 100%;
            padding: var(--druk-gap-l) 0;
            border-radius: 12px;
            background-color: var(--druk-primary-tint-0-14);
            &::before {
                content: '';
                position: absolute;
                z-index: 2;
                pointer-events: none;
                width: 100%;
                height: calc(100% - var(--druk-gap-2xl));
                opacity: 0;
                background-color: var(--druk-state-layers-scrim-0-32);
                transition: var(--druk-duration-short-4) var(--druk-easing-emphasized-decelerate);
            }
            &.druk-has-pagination {
                padding: var(--druk-gap-l) 0 var(--druk-gap-xs);
            }
            &.druk-has-hover {
                .druk-l-file-ctrl__button {
                    transform: translate(0, var(--druk-n-gap-s));
                }
                .druk-l-file-ctrl__prompt {
                    opacity: 1;
                }
                &::before {
                    opacity: 1;
                }
            }
            &.druk-has-static {
                cursor: initial;
                pointer-events: none;
            }
            &.druk-is-disabled {
                opacity: 1;
            }
            &.druk-is-disabled img {
                filter: grayscale(1);
            }
        }
        &__slide {
            background-color: var(--druk-background);
            &-scaler {
                pointer-events: none;
                margin-top: 100%;
            }
        }
        &__ctrl {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 3;
            padding: var(--druk-gap-l);
            width: 100%;
            &.druk-has-invisible {
                display: none;
            }
            &.druk-has-pagination {
                transform: translate(-50%, calc(-50% - var(--druk-gap-l) + var(--druk-gap-xs)));
            }
        }
        &__button,
        &__prompt {
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.3s ease-in-out;
        }
        &__button {
            transform: translate(0, 50%);
        }
        &__prompt {
            width: auto;
            margin: 4px auto;
            padding: 4px 8px;
            text-align: center;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.4px;
            font-weight: 400;
            border-radius: 4px;
            color: var(--druk-inverse-on-surface);
            background-color: var(--druk-surface-inverse-surface);
            opacity: 0;
        }
        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }

    .swiper {
        &-slide {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100% !important;
            height: 100%;
            margin: auto;
            img {
                margin: auto;
            }
        }
        &-pagination {
            position: initial;
        }
    }
</style>
