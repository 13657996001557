import Vue from 'vue';

import axios from 'axios';

import comments from './comments';
import statuses from './statuses';
import productions from './productions/index';

const CancelToken = axios.CancelToken;

let cancel;

const DEFAULT_FILTERS = {
    page: null,
    limit: 30,
    title: null,
    client_id: null,
    company_id: null,
    status_id: null,
    is_paid: null,
    urgency_id: null,
    principle: null,
    'created_at[from]': null,
    'created_at[to]': null,
    'deadline[from]': null,
    'deadline[to]': null,
    sort: 'new',
    direction: 'desc',
};

export default {
    namespaced: true,

    modules: {
        comments,
        statuses,
        productions,
    },

    state: {
        EXTRA_SORTING: [
            {
                translation_alias: 'orders.extra_sorting.deadline_asc',
                value: 'deadline_asc',
            },
            {
                translation_alias: 'orders.extra_sorting.deadline_desc',
                value: 'deadline_desc',
            },
        ],

        list: [],
        listPagination: {},
        listFilter: { ...DEFAULT_FILTERS },
        listFilterStash: {},

        dashboard: {},

        item: {},
        copyItem: {},

        copyData: {},

        loadingGetList: false,
        loadingGetItem: false,
        loadingGetCopyItem: false,
        loadingSaveCopyItem: false,
        loadingSaveItem: false,
        loadingSaveItemClientAddress: false,
        loadingSaveItemProductWorkingName: false,
        loadingDeleteItem: false,
        loadingItemPay: false,
        loadingProductPay: false,
        loadingGetOrderLogs: false,
        loadingFile: false,
        loadingGenerateInvoice: false,
    },

    getters: {
        listActiveFilter(state) {
            return Object.keys(state.listFilter)
                .filter((key) => state.listFilter[key] !== null)
                .reduce((resp, key) => ((resp[key] = state.listFilter[key]), resp), {});
        },

        isFilterNotEmpty(state, getters, rootState) {
            return !!Object.keys(getters.listActiveFilter).filter(
                (key) => !rootState.constant.INACTIVE_FILTERING_KEYS.concat(state.INACTIVE_FILTERING_KEYS).includes(key),
            ).length;
        },
    },

    mutations: {
        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },

        SET_LIST(state, list) {
            state.list = list;
        },

        SET_PAGINATION(state, pagination) {
            state.listPagination = pagination;
        },

        SET_DASHBOARD(state, dashboard) {
            state.dashboard = dashboard;
        },

        SET_ITEM(state, item) {
            state.item = item;
        },

        SET_COPY_ITEM(state, item) {
            state.copyItem = item;
        },

        SET_COPY_DATA(state, formData) {
            Vue.set(state, 'copyData', formData);
        },

        SET_COPY_DATA_PROP(state, { key, value }) {
            Vue.set(state.copyData, key, value);
        },

        SET_COPY_DATA_MAP_PROP(state, { key, value }) {
            Vue.set(state.copyData[key], state.copyData[key].length, value);
        },

        SET_COPY_DATA_PRODUCT_EXTRA_DATA(state, { index, formData }) {
            Vue.set(
                state.copyData.order_products[index],
                'extra_data',
                Object.assign(state.copyData.order_products[index].extra_data, formData),
            );
        },

        CHANGE_COPY_DATA_MAP_PROP(state, { key, index, value }) {
            Vue.set(state.copyData[key], index, value);
        },

        UPDATE_LIST_FILTER(state, newFilterlist) {
            Object.entries(newFilterlist).forEach(([key, value]) => Vue.set(state.listFilter, key, value));
            Vue.set(state.listFilter, 'page', newFilterlist.page || 1);
        },

        CHANGE_ORDER_ITEM(state, { key, value }) {
            Vue.set(state.item, key, value);
        },

        CHANGE_COPY_ITEM_PRODUCT(state, { id, value }) {
            Vue.set(state.copyItem.products, id, value);
        },

        CHANGE_ORDER_PRODUCT_ITEM(state, { index, key, value }) {
            Vue.set(state.item.products[index], key, value);
        },

        REMOVE_COPY_DATA_PROP(state, { key, index }) {
            Vue.delete(state.copyData[key], index);
        },

        RESET_LIST(state) {
            state.list = [];
        },

        RESET_STORE(state) {
            state.listFilter = { ...DEFAULT_FILTERS };
        },
    },

    actions: {
        GET_LIST: async ({ state, rootState, rootGetters, getters, commit }, filters) => {
            try {
                if (cancel !== undefined) {
                    cancel();
                }

                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let resp = await $axios.get(`/api/typographies/${rootGetters.currentTypography.id}/orders`, {
                    params: filters || getters.listActiveFilter,

                    cancelToken: new CancelToken(function executor(c) {
                        cancel = c;
                    }),
                });

                if (resp) {
                    commit('SET_LIST', resp.list);
                    commit('SET_PAGINATION', resp.pagination);
                    commit('SET_DASHBOARD', resp.dashboard);
                }

                return resp || {};
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        GET_ITEM: async ({ state, rootState, rootGetters, getters, commit }, payload = { id, bitrix: 0 }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: true });

                let resp = await $axios.get(`/api/typographies/${rootGetters.currentTypography.id}/orders/${payload.id}`, {
                    params: {
                        bitrix: payload.bitrix,
                    },
                });

                commit('SET_ITEM', resp.single);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: false });
            }
        },

        SAVE_ITEM: async ({ state, commit, rootGetters, dispatch }, { formData, single_fecth }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

                let requestData = {
                    ...state.item,
                    ...formData,
                    status_id: formData.status?.id || state.item.status.id,
                    client_id: formData.client?.id || state.item.client.id,
                    client_address_id: formData.client_address?.id || state.item.client_address.id,
                    contact_id: formData.contact?.id || state.item.contact.id,
                    payment_method_id: formData.payment_method?.id || state.item.payment_method.id,
                    urgency_id: formData.urgency?.id || state.item.urgency?.id || 1,
                    products: (formData.products || state.item.products || []).map((product) => ({
                        ...product,
                        product_id: product.product.id,
                        kind_id: product.kind.id,
                        status_id: product.status.id,
                        hash: product.hash || null,
                    })),
                };

                let resp = await $axios.post(`/api/typographies/${rootGetters.currentTypography.id}/orders`, requestData);

                single_fecth ? dispatch('GET_ITEM', { id: requestData.id || resp.single.id }) : dispatch('GET_LIST');

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
            }
        },

        SAVE_ITEM_CLIENT_ADDRESS: async ({ state, commit, rootGetters, dispatch }, { id, formData }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItemClientAddress', status: true });

                const path = `/api/typographies/${rootGetters.currentTypography.id}/orders/${id}/address`;

                const resp = await $axios.post(path, formData);

                dispatch('GET_ITEM', { id });

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItemClientAddress', status: false });
            }
        },

        SAVE_ITEM_PRODUCT_WORKING_NAME: async ({ state, commit, rootGetters, dispatch }, { id, formData }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItemProductWorkingName', status: true });

                const path = `/api/typographies/${rootGetters.currentTypography.id}/orders/${id}/products`;

                const resp = await $axios.post(path, formData);

                dispatch('GET_ITEM', { id });

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItemProductWorkingName', status: false });
            }
        },

        GET_COPY_ITEM: async ({ state, commit, rootGetters, dispatch }, { orderId, formData }) => {
            try {
                if (cancel !== undefined) {
                    cancel();
                }

                commit('SET_LOADING_STATUS', { value_key: 'loadingGetCopyItem', status: true });

                let resp = await $axios.post(
                    `/api/typographies/${rootGetters.currentTypography.id}/orders/${orderId}/copy`,
                    $fn.assign(formData, {}),
                    {
                        cancelToken: new CancelToken(function executor(c) {
                            cancel = c;
                        }),
                    },
                );

                commit('SET_COPY_ITEM', resp.single);

                return resp;
            } catch (e) {
                if (!e.response) return;
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetCopyItem', status: false });
            }
        },

        SAVE_COPY_ITEM: async ({ state, commit, rootGetters, dispatch }, { orderId, formData }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveCopyItem', status: true });

                let resp = await $axios.post(
                    `/api/typographies/${rootGetters.currentTypography.id}/orders/${orderId}/copy`,
                    $fn.assign(formData, {}),
                );

                dispatch('GET_LIST');

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveCopyItem', status: false });
            }
        },

        DELETE_ITEM: async ({ commit, rootGetters, dispatch }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: true });

                let resp = await $axios.delete(`/api/typographies/${rootGetters.currentTypography.id}/orders/${id}`);
                dispatch('GET_LIST');

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: false });
            }
        },

        PAY_ITEM: async ({ state, rootGetters, getters, commit, dispatch }, { id }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingItemPay', status: true });

                let resp = await $axios.post(`/api/typographies/${rootGetters.currentTypography.id}/orders/${id}/pay`);

                dispatch('GET_ITEM', { id: id });

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingItemPay', status: false });
            }
        },

        // @TODO: Temporary hidden WT-2063
        // PAY_PRODUCT: async ({ state, rootGetters, getters, commit, dispatch }, { orderId, productId }) => {
        //     try {
        //         commit('SET_LOADING_STATUS', { value_key: 'loadingProductPay', status: true });

        //         let resp = await $axios.post(
        //             `/api/typographies/${rootGetters.currentTypography.id}/orders/${orderId}/products/${productId}/pay`,
        //         );

        //         dispatch('GET_ITEM', { id: orderId });

        //         return resp;
        //     } catch (e) {
        //         throw e;
        //     } finally {
        //         commit('SET_LOADING_STATUS', { value_key: 'loadingProductPay', status: false });
        //     }
        // },

        GET_FILE_HASH: async ({ state, rootGetters, getters, commit, dispatch }, clientId) => {
            try {
                let resp = await $axios.post(`/api/typographies/${rootGetters.currentTypography.id}/mockup-hashes/${clientId}`);

                return resp;
            } catch (e) {
                throw e;
            } finally {
            }
        },

        CHANGE_DESTINATION: async ({ state, rootGetters, getters, commit, dispatch }, { hash, formData }) => {
            try {
                let resp = await $axios.post(`/api/mockups/destination/${hash}`, formData);

                return resp;
            } catch (e) {
                throw e;
            } finally {
            }
        },

        LOAD_FILE: async ({ state, rootGetters, getters, commit, dispatch }, { domain, formData }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingFile', status: true });
                let resp = await $axios.post(`https://${domain}/api/process/upload`, formData);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingFile', status: false });
            }
        },

        ATTACH_FILE: async ({ state, rootGetters, getters, commit, dispatch }, { hash, formData }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingFile', status: true });
                let resp = await $axios.post(`/api/mockups/attach/${hash}`, formData);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingFile', status: false });
            }
        },

        GET_ORDER_LOGS: async ({ state, rootGetters, getters, commit, dispatch }, orderId) => {
            commit('SET_LOADING_STATUS', { value_key: 'loadingGetOrderLogs', status: true });
            try {
                let resp = await $axios.get(`/api/typographies/${rootGetters.currentTypography.id}/orders/${orderId}/logs`);
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetOrderLogs', status: false });
                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetOrderLogs', status: false });
            }
        },

        DELETE_PRODUCT_IN_ITEM: async ({ commit, rootGetters, dispatch }, { orderId, productId }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: true });

                let resp = await $axios.delete(
                    `/api/typographies/${rootGetters.currentTypography.id}/orders/${orderId}/products/${productId}/delete`,
                );
                dispatch('GET_ITEM', { id: orderId });

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: false });
            }
        },

        DELETE_MOCKUP: async ({ commit, rootGetters, dispatch }, { orderId, productId, mockupId }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: false });

                let resp = await $axios.delete(
                    `/api/typographies/${rootGetters.currentTypography.id}/orders/${orderId}/products/${productId}/mockups/${mockupId}`,
                );
                dispatch('GET_ITEM', { id: orderId });

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: false });
            }
        },

        EXPORT_LIST: async ({ state, commit, rootGetters, dispatch }, formData) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let resp = await $axios
                    .get(`/api/typographies/${rootGetters.currentTypography.id}/orders/export`, {
                        params: {
                            'created_at[from]': formData.from,
                            'created_at[to]': formData.to,
                            statuses: formData.statuses,
                            managers: formData.managers,
                            paid_statuses: formData.paid_statuses,
                            clients: formData.clients,
                        },
                    })
                    .then((resp) => {
                        const url = resp.link;
                        const link = document.createElement('a');
                        link.href = url;
                        link.click();

                        URL.revokeObjectURL(link.href);
                    });

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        GENERATE_INVOICE: async ({ commit, rootGetters, dispatch }, { orderId, language }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGenerateInvoice', status: true });

                let resp = await $axios
                    .get(`/api/typographies/${rootGetters.currentTypography.id}/orders/${orderId}/invoice`, {
                        headers: {
                            'Accept-Language': language,
                        },
                    })
                    .then((resp) => {
                        const url = resp.invoice;
                        const link = document.createElement('a');
                        link.href = url;
                        link.target = '_blank';
                        link.click();

                        URL.revokeObjectURL(link.href);
                    });

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGenerateInvoice', status: false });
            }
        },
    },
};
