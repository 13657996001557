import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        TABS: [
            {
                alias: 'orders',
                component: 'orders-index',
                translation_alias: 'dashboard.index.orders',
                icon: 'cart-shopping',
            },
            {
                alias: 'calculations',
                component: 'calculations-index',
                translation_alias: 'orders.tabs_calculations',
                icon: 'calculator-simple',
            },
        ],

        TAB_ORDERS: 'orders',
        TAB_CALCULATIONS: 'calculations',

        tab: null,
    },

    getters: {
        ordersFilter(state, getters, rootState, rootGetters) {
            return rootGetters['orders/listActiveFilter'];
        },

        calculationsFilter(state, getters, rootState, rootGetters) {
            return rootGetters['calculations/listActiveFilter'];
        },
    },

    actions: {
        //
    },

    mutations: {
        SET_TAB(state, tab) {
            Vue.set(state, 'tab', tab);
        },
    },
};
