var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"selector selector--input",class:{
            'has-focus': _vm.hasActive || _vm.hasPseudoActive,
            'has-full': _vm.hasFull,
            'has-error': _vm.errors.has(_vm.name) || _vm.hasMaxLengthError,
            'has-loading': _vm.loader,
            'druk-has-hint': _vm.hint && !_vm.hint.isSide,
            'druk-has-side-hint': _vm.hint && _vm.hint.isSide,
        }},[(_vm.label)?_c('div',{staticClass:"selector__label",class:{ [`druk-l-surface-${_vm.surface}`]: _vm.surface },on:{"click":_vm.onToggle}},[_c('span',[_c('label',[_vm._v(_vm._s(_vm.label))]),(_vm.isRequired)?[_vm._v("*")]:_vm._e()],2),(_vm.hint && !_vm.hint.isSide && !_vm.hint.isHidden)?_c('druk-hint',{attrs:{"tooltip":_vm.tooltip,"icon":_vm.icon,"isInline":true}}):_vm._e()],1):_vm._e(),_c('div',{staticClass:"selector__main",class:{ [`druk-l-surface-${_vm.surface}`]: _vm.surface }},[(_vm.beforeText)?_c('div',{staticClass:"selector__beforeText",on:{"click":_vm.onToggle}},[_vm._v(" "+_vm._s(_vm.beforeText)+" ")]):_vm._e(),(_vm.leadIcon)?_c('div',{staticClass:"selector__lead"},[_c('druk-icon',{attrs:{"name":_vm.leadIcon,"color":_vm.hasActive || _vm.hasPseudoActive ? 'primary' : 'on-surface-variant'}})],1):_vm._e(),[_c('input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.rules || ''),expression:"rules || ''"}],key:_vm.name,ref:"area",staticClass:"selector__area",class:{
                        'has-password': _vm.type === _vm.TYPE_PASSWORD,
                        'has-before': _vm.beforeText || _vm.leadIcon,
                        'has-after': _vm.afterText,
                        'druk-has-reduced-width': _vm.hasReducedWidth,
                    },attrs:{"step":"any","data-vv-name":_vm.name,"data-vv-as":_vm.validatorLabel || _vm.label,"placeholder":_vm.placeholder && typeof _vm.placeholder === 'string' ? _vm.placeholder : null,"type":_vm.type || 'text',"autocomplete":_vm.autocomplete || 'off',"readonly":_vm.readonly},domProps:{"value":_vm.value},on:{"focus":_vm.onFocus,"blur":_vm.onBlur,"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$emit('enter')},function($event){return _vm.$emit('keyup')}],"input":function($event){return _vm.$emit('input', $event.target.value)}}})],(_vm.afterText)?_c('div',{staticClass:"selector__afterText",on:{"click":_vm.onToggle}},[_vm._v(" "+_vm._s(_vm.afterText)+" ")]):_vm._e(),(_vm.isFilterType && !_vm.hasFull && !_vm.trailIcon)?_c('div',{staticClass:"selector__nav",class:{ 'has-active': _vm.hasFull || _vm.hasActive || _vm.hasPseudoActive },on:{"click":_vm.onToggle}},[_c('font-awesome-icon',{attrs:{"icon":"fa-regular fa-magnifying-glass"}})],1):_vm._e(),(_vm.trailIcon && (!_vm.hasFull || !_vm.isFilterType))?_c('div',{staticClass:"selector__trail"},[_c('druk-icon',{attrs:{"name":_vm.trailIcon,"color":_vm.hasActive || _vm.hasPseudoActive ? 'primary' : 'on-surface-variant'}})],1):_vm._e(),(_vm.isFilterType && _vm.hasFull)?_c('div',{staticClass:"selector__clear",on:{"click":_vm.onClear}},[_c('font-awesome-icon',{attrs:{"icon":"fa-regular fa-xmark"}})],1):_vm._e(),(_vm.buttonIcon)?_c('div',{staticClass:"selector__option",class:{
                    'druk-is-disabled': !_vm.hasFull && !_vm.canSaveEmpty,
                    'has-animate': !_vm.staticButton,
                },on:{"click":function($event){return _vm.$emit('submit')}}},[_c('druk-icon',{attrs:{"name":_vm.buttonIcon,"color":!_vm.hasFull && !_vm.canSaveEmpty ? 'on-surface' : 'on-surface-variant'}})],1):_vm._e(),(_vm.hasShowLength)?_c('div',{staticClass:"selector__label selector__label--bottom",class:{ [`druk-l-surface-${_vm.surface}`]: _vm.surface }},[_vm._v(" "+_vm._s(_vm.$t('common.symbols'))+": "+_vm._s(_vm.value ? _vm.value.length : '0')),(_vm.maxLength)?[_vm._v("/"+_vm._s(_vm.maxLength))]:_vm._e()],2):_vm._e()],2),(_vm.hint && _vm.hint.isSide && !_vm.hint.isHidden)?_c('druk-hint',{staticClass:"selector__hint",attrs:{"tooltip":_vm.tooltip,"icon":_vm.icon}}):_vm._e(),(_vm.errors.has(_vm.name))?_c('div',{staticClass:"selector__error"},[_c('span',{class:{ [`druk-l-surface-${_vm.surface}`]: _vm.surface }},[_vm._v(_vm._s(_vm.errorText || _vm.errors.first(_vm.name)))])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }