<template>
    <div class="druk-c-tabs">
        <div
            ref="wrapper"
            class="druk-c-tabs__wrapper"
            :class="{ 'druk-is-secondary': isSecondary, 'druk-has-no-scroll': hasNoScroll }">
            <div
                class="druk-c-tabs__body"
                :class="{
                    'druk-is-section-tabs': isSectionTabs,
                    'druk-is-secondary': isSecondary,
                    'druk-is-centered': isCentered,
                }">
                <div
                    v-for="(tab, index) in tabs"
                    :key="index"
                    class="druk-c-tabs__tab"
                    :class="{
                        'druk-is-active': activeTab === index,
                        'druk-is-section-tabs': isSectionTabs && !isSecondary,
                        'druk-is-secondary': isSecondary,
                        'druk-is-laced': hasLacedTabs,
                        'druk-is-disabled': isDisabled,
                    }"
                    @click="onTab(index)">
                    <div
                        class="druk-c-tabs__tab-state"
                        :class="{
                            'druk-is-active': activeTab === index,
                            'druk-is-section-tabs': isSectionTabs && !isSecondary,
                        }"></div>

                    <div
                        class="druk-c-tabs__tab-content"
                        :class="{ 'druk-has-icon': tab.icon, 'druk-is-laced': isSectionTabs && hasLacedTabs }">
                        <div v-if="!!tab.icon" class="druk-c-tabs__tab-icon">
                            <druk-icon :name="tab.icon" :size="'m'" :color="activeTab === index ? 'primary' : 'on-surface-variant'" />
                        </div>

                        <div
                            :id="`tab-label-${index}`"
                            class="druk-c-tabs__tab-label druk-u-text-title-s"
                            :class="{
                                'druk-is-enlarged': hasEnlargedLabels || isSectionTabs,
                            }">
                            <div class="druk-c-tabs__tab-label-text" :class="{ 'druk-is-laced': hasLacedTabs }">
                                {{ tab[tabLabel] }}
                            </div>

                            <div v-if="tab.hasBadge" class="druk-c-tabs__tab-badge">
                                <druk-badge />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="druk-c-tabs__rail">
                <div
                    class="druk-c-tabs__slider"
                    :style="{ width: sliderConfig.width, left: sliderConfig.left }"
                    :class="{ 'druk-is-secondary': isSecondary, 'druk-is-initialized': isInitialized }"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

    export default {
        name: 'druk-tabs',

        props: {
            tabs: Array,
            tabId: {
                type: String,
                default: 'index',
            },

            tabLabel: {
                type: String,
                default: 'label',
            },

            startFrom: [String, Number],

            isSectionTabs: Boolean,
            isSecondary: Boolean,
            isCentered: Boolean,
            isDisabled: Boolean,

            hasNoScroll: Boolean,
            hasEnlargedLabels: Boolean,
            hasLacedTabs: Boolean,
        },

        data() {
            return {
                TAB_LABEL_TARGET: '.druk-c-tabs__tab-label',
                TAB_TARGET: '.druk-c-tabs__tab',
                TAB_CONTENT_TARGET: '.druk-c-tabs__tab-content',

                activeTab: 0,

                sliderConfig: {
                    width: null,
                    left: null,
                },

                isInitialized: false,
            };
        },

        created() {
            this.$bus.$on('on-change-tab', (index) => this.onTab(index));
        },

        mounted() {
            window.addEventListener('resize', this.setSliderStyles);
            this.buildData();
        },

        beforeDestroy() {
            this.$bus.$off('on-change-tab');
            window.removeEventListener('resize', this.setSliderStyles);
        },

        watch: {
            startFrom() {
                this.buildData();
            },

            badgeTriggers() {
                this.setSliderStyles();
            },

            hasActiveDrawer() {
                this.setSliderStyles(this.LONG_ANIMATION_DELAY);
            },
        },

        computed: {
            ...mapState({
                SHORT_ANIMATION_DELAY: (state) => state.constant.SHORT_ANIMATION_DELAY,
                LONG_ANIMATION_DELAY: (state) => state.constant.LONG_ANIMATION_DELAY,

                hasActiveDrawer: (state) => state.hasActiveDrawer,
            }),

            badgeTriggers() {
                return this.tabs.map((tab) => tab.hasBadge).filter((trigger) => !!trigger);
            },
        },

        methods: {
            buildData() {
                this.activeTab = this.tabs.findIndex((tab) => tab[this.tabId] === this.startFrom);
                this.setSliderStyles();
            },

            onTab(index) {
                this.activeTab = index;
                this.$emit('change', this.tabs[this.activeTab][this.tabId]);

                this.setSliderStyles();
            },

            setSliderStyles(delay) {
                let timer = setTimeout(() => {
                    clearTimeout(timer);

                    let label = this.$refs.wrapper?.querySelector(`#tab-label-${this.activeTab}`);
                    if (!label) return;

                    let wrapperRect = this.$refs.wrapper?.getBoundingClientRect(),
                        labelRect = label?.getBoundingClientRect();

                    let extraSliderWidth = this.getExtraSliderWidth(label);

                    this.$set(this.sliderConfig, 'width', `${labelRect.width + extraSliderWidth}px`);
                    this.$set(
                        this.sliderConfig,
                        'left',
                        `${labelRect.left - wrapperRect.left + this.$refs.wrapper.scrollLeft - extraSliderWidth / 2}px`,
                    );

                    if (!this.isInitialized) this.isInitialized = true;
                }, delay || 1);
            },

            getExtraSliderWidth(label) {
                if (!this.isSecondary) return 0;

                let tab = label.closest(this.TAB_TARGET),
                    tabStyles = window.getComputedStyle(tab),
                    tabPadding = this.getStyleValue(tabStyles, 'padding-left') + this.getStyleValue(tabStyles, 'padding-right');

                let content = label.closest(this.TAB_CONTENT_TARGET),
                    contentStyles = window.getComputedStyle(content),
                    contentPadding =
                        this.getStyleValue(contentStyles, 'padding-left') + this.getStyleValue(contentStyles, 'padding-right');

                return tabPadding + contentPadding;
            },

            getStyleValue(styles, target) {
                return parseFloat(styles.getPropertyValue(target));
            },
        },
    };
</script>

<style lang="scss" scoped>
    .druk-c-tabs {
        &__wrapper {
            max-width: 100%;
            overflow-x: auto;
            overflow-y: hidden;
            &.druk-is-secondary {
                width: max-content;
            }
            &.druk-has-no-scroll {
                overflow-x: hidden;
            }
        }
        &__body {
            display: flex;
            align-items: center;
            justify-content: space-between;
            &.druk-is-section-tabs,
            &.druk-is-secondary {
                justify-content: flex-start;
            }
            &.druk-is-section-tabs.druk-is-secondary {
                align-items: stretch;
            }
            &.druk-is-centered {
                justify-content: center;
                margin: 0 auto;
                width: max-content;
            }
        }
        &__tab {
            cursor: pointer;
            position: relative;
            flex-grow: 1;
            padding: 0 16px;
            color: var(--druk-on-surface-variant);
            transition: color var(--druk-duration-short-2) var(--druk-easing-emphasized-accelerate);
            &:hover {
                transition: color var(--druk-duration-short-2) var(--druk-easing-emphasized-decelerate);
                color: var(--druk-on-surface);
            }
            &:not(.druk-is-active):hover &-icon .druk-c-icon {
                &::v-deep svg {
                    --icon-color: var(--druk-on-surface) !important;
                }
            }
            &:hover &-state,
            &:active &-state {
                transition: background-color var(--druk-duration-long-2) var(--druk-easing-emphasized-decelerate);
            }
            &:hover &-state {
                background-color: var(--druk-state-layers-on-surface-0-08);
            }
            &:hover &-state.druk-is-active {
                background-color: var(--druk-state-layers-primary-0-08);
            }
            &:active &-state {
                background-color: var(--druk-state-layers-on-surface-0-12);
            }
            &:active &-state.druk-is-active {
                background-color: var(--druk-state-layers-primary-0-12);
            }
            &.druk-is-active {
                color: var(--druk-on-surface);
            }
            &.druk-is-section-tabs,
            &.druk-is-secondary {
                flex-grow: 0;
            }
            &.druk-is-laced {
                flex: 1;
                max-width: 200px;
            }
            &-content {
                padding: 14px 4px;
                display: flex;
                justify-content: center;
            }
            &-content.druk-has-icon {
                align-items: center;
                flex-direction: column;
                padding: 10px 4px;
            }
            &-content.druk-is-laced {
                justify-content: flex-start;
            }
            &-icon::v-deep svg {
                transition: color var(--druk-duration-short-2) var(--druk-easing-emphasized-decelerate);
            }
            &-label-text {
                display: inline-block;
                white-space: nowrap;
            }
            &-label-text.druk-is-laced {
                white-space: initial;
                // word-break: break-all;
                text-align: center;
            }
            &-badge {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                vertical-align: middle;
                margin-left: var(--druk-gap-xs);
            }
            &-state {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: transparent;
                transition: background-color var(--druk-duration-short-2) var(--druk-easing-emphasized-accelerate);
            }
            &-state.druk-is-section-tabs {
                top: 50%;
                transform: translate(0, -50%);
                height: 48px;
                border-radius: 100px;
            }
        }
        &__rail {
            content: '';
            position: relative;
            width: 100%;
            height: 1px;
            background: var(--druk-outline-variant);
        }
        &__slider {
            content: '';
            position: absolute;
            bottom: 0;
            height: 3px;
            border-radius: 100px 100px 0 0;
            background-color: var(--druk-primary);
            transition: var(--druk-duration-medium-4) var(--druk-easing-emphasized-decelerate);
            transition-property: left;
            &.druk-is-secondary {
                height: 2px;
            }
            &.druk-is-initialized {
                transition-property: left, width;
            }
        }
    }
</style>
