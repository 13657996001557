import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        ORDER_CREATION_MODE: 'orderCreation',
        CALCULATIONS_MODE: 'calculations',

        mode: null,
    },

    getters: {
        // @Explanation: Base
        WIZARD_STAGES(state, getters, rootState) {
            return !!state.mode ? rootState[state.mode]?.WIZARD_STAGES : [];
        },

        stage(state, getters, rootState) {
            return !!state.mode ? rootState[state.mode]?.stage : null;
        },

        clientTabs(state, getters, rootState) {
            return [
                {
                    alias: rootState.clients.NATURAL_ENTITY,
                    translation_alias: 'clients.natural_entity_short',
                    setting: rootState.settings.CLIENT_AUDIENCE_PRIORITY,
                },
                {
                    alias: rootState.clients.LEGAL_ENTITY,
                    translation_alias: 'clients.legal_entity_short',
                    setting: rootState.settings.COMPANY_AUDIENCE_PRIORITY,
                },
            ];
        },

        clientTab(state, getters, rootState) {
            return !!state.mode ? rootState[state.mode]?.clientTab : null;
        },

        formData(state, getters, rootState) {
            return !!state.mode ? rootState[state.mode]?.formData : {};
        },

        productData(state, getters, rootState) {
            return !!state.mode ? rootState[state.mode]?.productData : {};
        },

        deliveryData(state, getters, rootState) {
            return !!state.mode ? rootState[state.mode]?.deliveryData : {};
        },

        contactData(state, getters, rootState, rootGetters) {
            return !!state.mode ? rootGetters[`${state.mode}/contactData`] : {};
        },

        // @Explanation: Basket
        basket(state, getters, rootState) {
            return !!state.mode ? rootState[`${state.mode}`]?.basket.list : [];
        },

        warehouseMap(state, getters, rootState, rootGetters) {
            return !!state.mode ? rootGetters[`${state.mode}/basket/warehouseMap`] : {};
        },

        hasOnlyUrgentProducts(state, getters, rootState, rootGetters) {
            return !!state.mode ? rootGetters[`${state.mode}/basket/hasOnlyUrgentProducts`] : false;
        },

        hasUrgentProducts(state, getters, rootState, rootGetters) {
            return !!state.mode ? rootGetters[`${state.mode}/basket/hasUrgentProducts`] : false;
        },

        hasNoUrgentProducts(state, getters, rootState, rootGetters) {
            return !!state.mode ? rootGetters[`${state.mode}/basket/hasNoUrgentProducts`] : false;
        },

        hasChangedRestoredItems(state, getters, rootState, rootGetters) {
            return !!state.mode ? rootGetters[`${state.mode}/basket/hasChangedRestoredItems`] : false;
        },

        hasInaccessibilityRestoredItems(state, getters, rootState, rootGetters) {
            return !!state.mode ? rootGetters[`${state.mode}/basket/hasInaccessibilityRestoredItems`] : false;
        },

        hasInaccessibleBasket(state, getters, rootState, rootGetters) {
            return !!state.mode ? rootGetters[`${state.mode}/basket/hasInaccessibleBasket`] : false;
        },

        hasOnlyWarehouseProducts(state, getters, rootState, rootGetters) {
            return !!state.mode ? rootGetters[`${state.mode}/basket/hasOnlyWarehouseProducts`] : false;
        },

        isAllItemsReaclulated(state, getters, rootState, rootGetters) {
            return !!state.mode ? rootGetters[`${state.mode}/basket/isAllItemsReaclulated`] : false;
        },

        // @Explanation: Knives
        target(state, getters, rootState) {
            return !!state.mode ? rootState[`${state.mode}`]?.knives.target : null;
        },

        pickedKnife(state, getters, rootState) {
            return !!state.mode ? rootState[`${state.mode}`]?.knives.pickedKnife : null;
        },
    },

    actions: {
        // @Explanation: Base
        SET_STAGE: async ({ state, commit }, item) => {
            commit(`${state.mode}/SET_STAGE`, item, { root: true });
        },

        SET_CLIENT_TAB: async ({ state, commit }, item) => {
            commit(`${state.mode}/SET_CLIENT_TAB`, item, { root: true });
        },

        SET_FORM_DATA: async ({ state, commit }, item) => {
            commit(`${state.mode}/SET_FORM_DATA`, item, { root: true });
        },

        SET_FORM_DATA_PROP: async ({ state, commit }, item) => {
            commit(`${state.mode}/SET_FORM_DATA_PROP`, item, { root: true });
        },

        SET_PRODUCT_DATA: async ({ state, commit }, item) => {
            commit(`${state.mode}/SET_PRODUCT_DATA`, item, { root: true });
        },

        SET_PRODUCT_DATA_PROP: async ({ state, commit }, item) => {
            commit(`${state.mode}/SET_PRODUCT_DATA_PROP`, item, { root: true });
        },

        SET_DELIVERY_DATA: async ({ state, commit }, item) => {
            commit(`${state.mode}/SET_DELIVERY_DATA`, item, { root: true });
        },

        SET_DELIVERY_DATA_PROP: async ({ state, commit }, item) => {
            commit(`${state.mode}/SET_DELIVERY_DATA_PROP`, item, { root: true });
        },

        UPDATE_FORM_DATA: async ({ state, commit }, item) => {
            commit(`${state.mode}/UPDATE_FORM_DATA`, item, { root: true });
        },

        UPDATE_PRODUCT_DATA: async ({ state, commit }, item) => {
            commit(`${state.mode}/UPDATE_PRODUCT_DATA`, item, { root: true });
        },

        UPDATE_DELIVERY_DATA: async ({ state, commit }, item) => {
            commit(`${state.mode}/UPDATE_DELIVERY_DATA`, item, { root: true });
        },

        UPDATE_STAGES: async ({ state, commit }, item) => {
            commit(`${state.mode}/UPDATE_STAGES`, item, { root: true });
        },

        RESET_STAGES: async ({ state, commit }, item) => {
            commit(`${state.mode}/RESET_STAGES`, item, { root: true });
        },

        RESET_CLIENT_TAB: async ({ state, commit }, item) => {
            commit(`${state.mode}/RESET_CLIENT_TAB`, item, { root: true });
        },

        RESET_FORM_DATA: async ({ state, commit }, item) => {
            commit(`${state.mode}/RESET_FORM_DATA`, item, { root: true });
        },

        RESET_PRODUCT_DATA: async ({ state, commit }, item) => {
            commit(`${state.mode}/RESET_PRODUCT_DATA`, item, { root: true });
        },

        RESET_DELIVERY_DATA: async ({ state, commit }, item) => {
            commit(`${state.mode}/RESET_DELIVERY_DATA`, item, { root: true });
        },

        // @Explanation: Basket
        ADD_TO_BASKET: async ({ state, commit }, item) => {
            commit(`${state.mode}/basket/ADD_TO_LIST`, item, { root: true });
        },

        CLONE_TO_BASKET: async ({ state, commit }, { index, data }) => {
            commit(`${state.mode}/basket/CLONE_TO_LIST`, { index, data }, { root: true });
        },

        UPDATE_BASKET_ITEM: async ({ state, commit }, { index, data }) => {
            commit(`${state.mode}/basket/UPDATE_LIST_ITEM`, { index, data }, { root: true });
        },

        DELETE_FROM_BASKET: async ({ state, commit }, index) => {
            commit(`${state.mode}/basket/DELETE_FROM_LIST`, index, { root: true });
        },

        RESET_BASKET: async ({ state, commit }, index) => {
            commit(`${state.mode}/basket/RESET_LIST`, index, { root: true });
        },

        // @Explanation: Knives
        SET_TARGET: async ({ state, commit }, index) => {
            commit(`${state.mode}/knives/SET_TARGET`, index, { root: true });
        },

        SET_PICKED_KNIFE: async ({ state, commit }, index) => {
            commit(`${state.mode}/knives/SET_PICKED_KNIFE`, index, { root: true });
        },

        RESET_TARGET: async ({ state, commit }, index) => {
            commit(`${state.mode}/knives/RESET_TARGET`, index, { root: true });
        },

        RESET_PICKED_KNIFE: async ({ state, commit }, index) => {
            commit(`${state.mode}/knives/RESET_PICKED_KNIFE`, index, { root: true });
        },
    },

    mutations: {
        SET_MODE(state, mode) {
            Vue.set(state, 'mode', mode);
        },
    },
};
