import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        target: null,
        pickedKnife: null,
    },

    mutations: {
        SET_TARGET(state, index) {
            Vue.set(state, 'target', index);
        },

        SET_PICKED_KNIFE(state, knife) {
            Vue.set(state, 'pickedKnife', knife);
        },

        RESET_TARGET(state) {
            state.target = null;
        },

        RESET_PICKED_KNIFE(state) {
            state.pickedKnife = null;
        },
    },
};
