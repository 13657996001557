import Vue from 'vue';
import VueRouter from 'vue-router';

import LoginIndex from '@views/LoginIndex';
import Dashboard from '@views/dashboard/DashboardIndex';

import store from '../store';

Vue.use(VueRouter);

const routes = [
    {
        path: '/login',
        name: 'login',
        component: LoginIndex,
        meta: {
            layout: store.state.KEY_LAYOUT_LOGIN,
            requiresFree: true,
        },
    },

    // Reports
    {
        path: '/',
        name: 'dashboard',
        component: Dashboard,
        meta: {
            // @TODO: Will be need in the future
            // permissionModule: store.state.permissions.PERM_MODULE_DASHBOARD,
            // permissionActions: [ store.state.permissions.PERM_ACTION_VIEW, store.state.permissions.PERM_ACTION_GET, ],
        },
    },

    // Contacts
    {
        path: '/clients',
        name: 'clients',
        component: () => import(/* webpackChunkName: "clients" */ '@views/contacts/clients/ClientsIndex'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_CLIENTS,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },
    {
        path: '/clients/client/:id?',
        name: 'clients_item',
        props: true,
        component: () => import(/* webpackChunkName: "clients_item" */ '@views/contacts/clients/ClientsItem'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_CLIENTS,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },
    {
        path: '/companies',
        name: 'companies',
        component: () => import(/* webpackChunkName: "companies" */ '@views/contacts/companies/CompaniesIndex'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_CLIENTS,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },
    {
        path: '/companies/company/:id?',
        name: 'companies_item',
        props: true,
        component: () => import(/* webpackChunkName: "companies_item" */ '@views/contacts/companies/CompaniesItem'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_CLIENTS,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },

    // employees
    {
        path: '/employees',
        name: 'employees',
        component: () => import(/* webpackChunkName: "employees" */ '@views/employees/EmployeesIndex'),
    },
    {
        path: '/employees/employee/:id?',
        name: 'employees_item',
        props: true,
        component: () => import(/* webpackChunkName: "employees_card-item" */ '@views/employees/EmployeesItem'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_EMPLOYEES,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW, store.state.permissions.PERM_ACTION_GET],
        },
    },
    {
        path: '/employees-iframe/employee/:id?',
        name: 'employees_item-iframe',
        props: true,
        component: () => import(/* webpackChunkName: "employees_item-iframe" */ '@views/employees/IframeEmployeesItem'),
        meta: {
            layout: store.state.KEY_LAYOUT_IFRAME,
            permissionModule: store.state.permissions.PERM_MODULE_EMPLOYEES,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW, store.state.permissions.PERM_ACTION_GET],
        },
    },

    // typographies
    {
        path: '/typographies',
        name: 'typographies',
        component: () => import(/* webpackChunkName: "typographies" */ '@views/typographies/TypographiesIndex'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_TYPOGRAPHIES,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
            isAvailable: store.getters.hasERPAccess,
        },
    },
    {
        path: '/typographies/:id',
        name: 'typographies_item',
        props: true,
        component: () => import(/* webpackChunkName: "typographies_item" */ '@views/typographies/TypographiesItem'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_TYPOGRAPHIES,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW, store.state.permissions.PERM_ACTION_GET],
        },
    },
    {
        path: '/owners',
        name: 'owners',
        props: true,
        component: () => import(/* webpackChunkName: "owners" */ '@views/typographies/owners/OwnersIndex'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_EMPLOYEES,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW, store.state.permissions.PERM_ACTION_GET],
        },
    },
    {
        path: '/owners/owner/:id',
        name: 'owners_item',
        props: true,
        component: () => import(/* webpackChunkName: "owners_item" */ '@views/typographies/owners/OwnersItem'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_EMPLOYEES,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW, store.state.permissions.PERM_ACTION_GET],
        },
    },
    {
        path: '/doppler',
        name: 'doppler',
        component: () => import(/* webpackChunkName: "doppler" */ '@views/typographies/doppler/DopplerIndex'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_EMPLOYEES,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW, store.state.permissions.PERM_ACTION_GET],
        },
    },
    {
        path: '/modules',
        name: 'modules',
        props: true,
        component: () => import(/* webpackChunkName: "modules" */ '@views/typographies/modules/ModulesIndex'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_CLIENT_MODULES,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },

    //orders
    {
        path: '/orders',
        name: 'orders',
        component: () => import(/* webpackChunkName: "orders" */ '@views/contracts/ContractsIndex'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_ORDERS,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
            tab: store.state.contracts.TAB_ORDERS,
        },
    },
    {
        path: '/orders/:id?',
        name: 'orders_item',
        props: true,
        component: () => import(/* webpackChunkName: "orders_item" */ '@views/contracts/orders/OrderItem'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_ORDERS,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },
    {
        path: '/orders-iframe/:id',
        name: 'orders-iframe',
        props: true,
        component: () => import(/* webpackChunkName: "orders-iframe" */ '@views/contracts/orders/IframeOrderItem'),
        meta: {
            layout: store.state.KEY_LAYOUT_IFRAME,
            permissionModule: store.state.permissions.PERM_MODULE_ORDERS,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },
    {
        path: '/orders/:id/production/:production_id',
        name: 'orders_item_production',
        props: true,
        component: () => import(/* webpackChunkName: "orders_item_production" */ '@views/contracts/orders/production/ProductionIndex'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_ORDERS,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },
    {
        path: '/orders/:id/production-iframe/:production_id',
        name: 'orders_item_production-iframe',
        props: true,
        component: () =>
            import(/* webpackChunkName: "orders_item_production-iframe" */ '@views/contracts/orders/production/IframeProductionIndex'),
        meta: {
            layout: store.state.KEY_LAYOUT_IFRAME,
            permissionModule: store.state.permissions.PERM_MODULE_ORDERS,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },
    {
        path: '/orders/:id/order-copy',
        name: 'order_copy',
        props: true,
        component: () => import(/* webpackChunkName: "order_copy" */ '@views/contracts/orders/OrderCopy'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_ORDERS,
            permissionActions: [store.state.permissions.PERM_ACTION_ADD],
        },
    },
    {
        path: '/orders/:id/order-copy-iframe',
        name: 'order_copy_iframe',
        props: true,
        component: () => import(/* webpackChunkName: "order_copy-iframe" */ '@views/contracts/orders/IframeOrderCopy'),
        meta: {
            layout: store.state.KEY_LAYOUT_IFRAME,
            permissionModule: store.state.permissions.PERM_MODULE_ORDERS,
            permissionActions: [store.state.permissions.PERM_ACTION_ADD],
        },
    },
    {
        path: '/order-creation',
        name: 'order_creation',
        component: () => import(/* webpackChunkName: "order_creation" */ '@views/orderCreation/OrderCreationIndex'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_ORDERS,
            permissionActions: [store.state.permissions.PERM_ACTION_ADD],
            hasNoFAB: true,
        },
    },
    {
        path: '/order-creation-iframe',
        name: 'order_creation_iframe',
        component: () => import(/* webpackChunkName: "order_creation_iframe" */ '@views/orderCreation/OrderCreationIframe'),
        meta: {
            layout: store.state.KEY_LAYOUT_IFRAME,
            permissionModule: store.state.permissions.PERM_MODULE_ORDERS,
            permissionActions: [store.state.permissions.PERM_ACTION_ADD],
            hasNoFAB: true,
        },
    },
    {
        path: '/order-creation/knife-pick',
        name: 'knife_pick',
        component: () => import(/* webpackChunkName: "knife_pick" */ '@views/orderCreation/knifePick/KnifePickIndex'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_TECHNICAL_MAP,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
            hasAccessModule: true,
            hasNoFAB: true,
        },
    },
    {
        path: '/order-creation-iframe/knife-pick-iframe',
        name: 'knife_pick_iframe',
        component: () => import(/* webpackChunkName: "knife_pick_iframe" */ '@views/orderCreation/knifePick/KnifePickIframe'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_TECHNICAL_MAP,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
            hasAccessModule: true,
            hasNoFAB: true,
        },
    },
    {
        path: '/order-creation/knife-creation',
        props: true,
        name: 'knife_pick_creation',
        component: () => import(/* webpackChunkName: "knife_pick_creation" */ '@views/orderCreation/knifePick/KnifePickCreation'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_TECHNICAL_MAP,
            permissionActions: [store.state.permissions.PERM_ACTION_EDIT],
            hasAccessModule: true,
            hasNoFAB: true,
        },
    },
    {
        path: '/order-creation-iframe/knife-creation-iframe',
        name: 'knife_pick_creation_iframe',
        props: true,
        component: () =>
            import(/* webpackChunkName: "knife_pick_creation_iframe" */ '@views/orderCreation/knifePick/KnifePickCreationIframe'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_TECHNICAL_MAP,
            permissionActions: [store.state.permissions.PERM_ACTION_EDIT],
            hasAccessModule: true,
            hasNoFAB: true,
        },
    },
    {
        path: '/calculations',
        name: 'calculations',
        component: () => import(/* webpackChunkName: "calculations" */ '@views/contracts/ContractsIndex'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_ORDERS,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
            tab: store.state.contracts.TAB_CALCULATIONS,
            navigation: store.state.contracts.TAB_ORDERS,
        },
    },
    {
        path: '/calculations/:id',
        name: 'calculations_item',
        props: true,
        component: () => import(/* webpackChunkName: "calculations_item" */ '@views/contracts/calculations/CalculationsItem'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_ORDERS,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
            navigation: store.state.contracts.TAB_ORDERS,
        },
    },
    {
        path: '/calculations/:id/edit',
        name: 'calculations_edit',
        props: true,
        component: () => import(/* webpackChunkName: "calculations_edit" */ '@views/contracts/calculations/CalculationsEdit'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_TECHNICAL_MAP,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
            navigation: store.state.contracts.TAB_ORDERS,
        },
    },
    {
        path: '/calculations/:id/edit/knife-pick',
        name: 'calculations_edit_knife_pick',
        props: true,
        component: () =>
            import(/* webpackChunkName: "calculations_edit_knife_pick" */ '@views/contracts/calculations/knifePick/KnifePickIndex'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_TECHNICAL_MAP,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
            hasAccessModule: true,
        },
    },
    {
        path: '/calculations/:id/edit/knife-creation',
        name: 'calculations_edit_knife_pick_creation',
        props: true,
        component: () =>
            import(
                /* webpackChunkName: "calculations_edit_knife_pick_creation" */ '@views/contracts/calculations/knifePick/KnifePickCreation'
            ),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_TECHNICAL_MAP,
            permissionActions: [store.state.permissions.PERM_ACTION_EDIT],
            hasAccessModule: true,
        },
    },
    //

    {
        path: '/partners',
        name: 'partners',
        component: () => import(/* webpackChunkName: "create_order" */ '@views/partners/PartnersIndex'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_PARTNERS,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },
    {
        path: '/partners/:id',
        name: 'partners_item',
        props: true,
        component: () => import(/* webpackChunkName: "partners_item" */ '@views/partners/PartnersItem'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_PARTNERS,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },

    // Categories
    {
        path: '/categories',
        name: 'categories',
        component: () => import(/* webpackChunkName: "categories" */ '@views/categories/CategoriesIndex'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_CATALOG,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },
    {
        path: '/categories/:id',
        name: 'categories_item',
        props: true,
        component: () => import(/* webpackChunkName: "categories_item" */ '@views/categories/CategoryItem'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_CATALOG,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },
    {
        path: '/categories-save/:id?',
        name: 'categories_item_save',
        props: true,
        component: () => import(/* webpackChunkName: "categories_item_save" */ '@views/categories/CategoryItemSave'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_CATALOG,
            permissionActions: [store.state.permissions.PERM_ACTION_EDIT],
        },
    },
    {
        path: '/categories/:id/products-import',
        name: 'categories_products_import',
        props: true,
        component: () => import(/* webpackChunkName: "products_import" */ '@views/products/modules/import/ProductsImportIndex'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_PRODUCTS,
            permissionActions: [store.state.permissions.PERM_ACTION_ADD],
        },
    },
    {
        path: '/categories/:id/products-import/:product_id',
        name: 'categories_products_import_item',
        props: true,
        component: () => import(/* webpackChunkName: "products_import_item" */ '@views/products/modules/import/ProductsImportItem'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_PRODUCTS,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },
    {
        path: '/categories/:id/products-import/:product_id/kind/:kind_id',
        name: 'categories_products_import_kind',
        props: true,
        component: () =>
            import(/* webpackChunkName: "products_import_kind" */ '@views/products/modules/import/kinds/ProductsImportKindsItem'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_PRODUCTS,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },

    // Products
    {
        path: '/products',
        name: 'products',
        component: () => import(/* webpackChunkName: "products" */ '@views/products/ProductsIndex'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_PRODUCTS,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },
    {
        path: '/products/:id',
        name: 'products_item',
        props: true,
        component: () => import(/* webpackChunkName: "products_item" */ '@views/products/ProductsItem'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_PRODUCTS,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },
    {
        path: '/products-save/:id?',
        name: 'products_item_save',
        props: true,
        component: () => import(/* webpackChunkName: "products_item_save" */ '@views/products/wizard/WizardSave'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_PRODUCTS,
            permissionActions: [store.state.permissions.PERM_ACTION_EDIT],
            nested: store.getters.hasTypographyAccess
                ? {
                      1: 'products_item_save_main',
                      2: 'products_item_save_seo',
                      3: 'products_item_save_tech',
                      4: 'products_item_save_preview',
                  }
                : {
                      1: 'products_item_save_main',
                      2: 'products_item_save_tech',
                      3: 'products_item_save_preview',
                  },
        },
        children: [
            {
                path: '/products-save/:id?/main',
                name: 'products_item_save_main',
                props: true,
                component: () => import(/* webpackChunkName: "products_item_save_main" */ '@views/products/wizard/stages/StageMain'),
                meta: {
                    stage: 1,
                },
            },
            {
                path: '/products-save/:id?/seo',
                name: 'products_item_save_seo',
                props: true,
                component: () => import(/* webpackChunkName: "products_item_save_seo" */ '@views/products/wizard/stages/StageSEO'),
                meta: {
                    stage: 2,
                },
            },
            {
                path: '/products-save/:id?/tech',
                name: 'products_item_save_tech',
                props: true,
                component: () => import(/* webpackChunkName: "products_item_save_tech" */ '@views/products/wizard/stages/StageTech'),
                meta: {
                    stage: store.getters.hasTypographyAccess ? 3 : 2,
                },
            },
            {
                path: '/products-save/:id?/preview',
                name: 'products_item_save_preview',
                props: true,
                component: () =>
                    import(/* webpackChunkName: "products_item_save_preview" */ '@views/products/wizard/stages/StagePreview'),
                meta: {
                    stage: store.getters.hasTypographyAccess ? 4 : 3,
                },
            },
        ],
    },
    {
        path: '/products-import',
        name: 'products_import',
        component: () => import(/* webpackChunkName: "products_import" */ '@views/products/modules/import/ProductsImportIndex'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_PRODUCTS,
            permissionActions: [store.state.permissions.PERM_ACTION_ADD],
        },
    },
    {
        path: '/products-import/:id',
        name: 'products_import_item',
        props: true,
        component: () => import(/* webpackChunkName: "products_import_item" */ '@views/products/modules/import/ProductsImportItem'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_PRODUCTS,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },
    {
        path: '/products-import/:id/kind/:kind_id',
        name: 'products_import_kind',
        props: true,
        component: () =>
            import(/* webpackChunkName: "products_import_kind" */ '@views/products/modules/import/kinds/ProductsImportKindsItem'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_PRODUCTS,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },
    {
        path: '/products/:id/kinds/:kind_id',
        name: 'kinds_item',
        props: true,
        component: () => import(/* webpackChunkName: "kinds_item" */ '@views/products/modules/kinds/KindsItem'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_PRODUCTS,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },
    {
        path: '/products/:id/kinds-save/:kind_id?',
        name: 'kinds_item_save',
        props: true,
        component: () => import(/* webpackChunkName: "kinds_item_save" */ '@views/products/modules/kinds/wizard/WizardSave'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_PRODUCTS,
            permissionActions: [store.state.permissions.PERM_ACTION_EDIT],
            nested: {
                1: 'kinds_item_save_main',
                2: 'kinds_item_save_tech',
                3: 'kinds_item_save_preview',
            },
        },
        children: [
            {
                path: '/products/:id/kinds-save/:kind_id?/main',
                name: 'kinds_item_save_main',
                props: true,
                component: () =>
                    import(/* webpackChunkName: "kinds_item_save_main" */ '@views/products/modules/kinds/wizard/stages/StageMain'),
                meta: {
                    stage: 1,
                },
            },
            {
                path: '/products/:id/kinds-save/:kind_id?/tech',
                name: 'kinds_item_save_tech',
                props: true,
                component: () =>
                    import(/* webpackChunkName: "kinds_item_save_tech" */ '@views/products/modules/kinds/wizard/stages/StageTech'),
                meta: {
                    stage: 2,
                },
            },
            {
                path: '/products/:id/kinds-save/:kind_id?/preview',
                name: 'kinds_item_save_preview',
                props: true,
                component: () =>
                    import(
                        /* webpackChunkName: "kinds_item_save_preview" */ '@views/products/modules/kinds/wizard/stages/StagePreview'
                    ),
                meta: {
                    stage: 3,
                },
            },
        ],
    },

    // Pages
    {
        path: '/pages',
        name: 'pages',
        component: () => import(/* webpackChunkName: "pages" */ '@views/pages/PagesIndex'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_PAGES,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },
    {
        path: '/pages/:id?',
        name: 'pages_item',
        component: () => import(/* webpackChunkName: "pages_item" */ '@views/pages/PagesItem'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_PAGES,
            permissionActions: [store.state.permissions.PERM_ACTION_EDIT],
        },
    },
    {
        path: '/partner-pages',
        name: 'partner_pages',
        component: () => import(/* webpackChunkName: "partner_pages" */ '@views/partnerPages/PartnerPagesIndex'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_PARTNER_PAGES,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },
    {
        path: '/partner-pages-new/page/:id',
        name: 'partner_pages_item',
        props: true,
        component: () => import(/* webpackChunkName: "partner_pages_item" */ '@views/partnerPages/PartnerPagesItem'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_PARTNER_PAGES,
            permissionActions: [store.state.permissions.PERM_ACTION_EDIT],
        },
    },

    {
        path: '/partner-pages/entities',
        name: 'partner_pages_entities',
        component: () => import(/* webpackChunkName: "partner_pages_entities" */ '@views/partnerPages/entities/EntitiesIndex'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_PARTNER_PAGES,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },
    {
        path: '/partner-pages/entities/entity/:id',
        name: 'partner_pages_entities_item',
        props: true,
        component: () => import(/* webpackChunkName: "partner_pages_entities_item" */ '@views/partnerPages/entities/EntitiesItem'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_PARTNER_PAGES,
            permissionActions: [store.state.permissions.PERM_ACTION_EDIT],
        },
    },
    {
        path: '/css',
        name: 'css',
        component: () => import(/* webpackChunkName: "css" */ '@views/cssEditor/CssIndex'),
        meta: {
            hasAccessModule: true,
            permissionModule: store.state.permissions.PERM_MODULE_TYPOGRAPHIES,
            permissionActions: [store.state.permissions.PERM_ACTION_EDIT],
        },
    },
    {
        path: '/blogs',
        name: 'blogs',
        component: () => import(/* webpackChunkName: "blogs" */ '@views/blogs/BlogsIndex'),
        meta: {
            hasAccessModule: true,
            permissionModule: store.state.permissions.PERM_MODULE_BLOGS,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },
    {
        path: '/translates',
        name: 'translates',
        component: () => import(/* webpackChunkName: "translates" */ '@views/translates/TranslatesIndex'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_TRANSLATES,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },
    {
        path: '/balance',
        name: 'balance',
        component: () => import(/* webpackChunkName: "balance" */ '@views/balance/BalanceIndex'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_TRANSACTIONS,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },

    {
        path: '/seo',
        name: 'seo',
        component: () => import(/* webpackChunkName: "transactions" */ '@views/seo/SeoIndex'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_SEO,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },
    {
        path: '/mail-templates',
        name: 'mail-templates',
        component: () => import(/* webpackChunkName: "transactions" */ '@views/mailTemplates/MailTemplatesIndex'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_MAIL_TEMPLATE,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },

    {
        path: '/feedback',
        name: 'feedback',
        component: () => import(/* webpackChunkName: "transactions" */ '@views/feedback/FeedbackIndex'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_FEEDBACK,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },

    //library
    {
        path: '/works',
        name: 'works',
        component: () => import(/* webpackChunkName: "works" */ '@views/library/works/WorksIndex'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_OPERATIONS,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },
    {
        path: '/works-save/:id?',
        name: 'works_item_save',
        props: true,
        component: () => import(/* webpackChunkName: "works_item_save" */ '@views/library/works/WorkItemSave'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_OPERATIONS,
            permissionActions: [store.state.permissions.PERM_ACTION_EDIT],
        },
    },
    {
        path: '/materials',
        name: 'materials',
        component: () => import(/* webpackChunkName: "materials" */ '@views/library/materials/MaterialsIndex'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_MATERIALS,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },
    {
        path: '/materials/:id',
        props: true,
        name: 'materials_item',
        component: () => import(/* webpackChunkName: "materials_item" */ '@views/library/materials/MaterialItem'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_MATERIALS,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },
    {
        path: '/materials/:id/views/:kind_id?',
        props: true,
        name: 'materials_view_save',
        component: () => import(/* webpackChunkName: "materials_view_save" */ '@views/library/materials/MaterialsViewSave'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_MATERIALS,
            permissionActions: [store.state.permissions.PERM_ACTION_EDIT],
        },
    },
    {
        path: '/equipments',
        name: 'equipments',
        component: () => import(/* webpackChunkName: "equipments" */ '@views/library/equipments/EquipmentsIndex'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_EQUIPMENTS,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },
    {
        path: '/equipments/:id',
        props: true,
        name: 'equipments_item',
        component: () => import(/* webpackChunkName: "equipments_item" */ '@views/library/equipments/EquipmentsItem'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_EQUIPMENTS,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },
    {
        path: '/equipments/:id/views/:kind_id?',
        props: true,
        name: 'equipments_view_save',
        component: () => import(/* webpackChunkName: "equipments_view_save" */ '@views/library/equipments/EquipmentsViewSave'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_EQUIPMENTS,
            permissionActions: [store.state.permissions.PERM_ACTION_EDIT],
        },
    },
    {
        path: '/formats',
        name: 'formats',
        component: () => import(/* webpackChunkName: "formats" */ '@views/library/formats/FormatsIndex'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_FORMATS,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },
    {
        path: '/knives',
        name: 'knives',
        component: () => import(/* webpackChunkName: "knives" */ '@views/library/knives/KnivesIndex'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_TECHNICAL_MAP,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
            hasAccessModule: true,
        },
    },
    {
        path: '/knives/knife/:id?',
        props: true,
        name: 'knives_item',
        component: () => import(/* webpackChunkName: "knives_item" */ '@views/library/knives/KnivesItem'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_TECHNICAL_MAP,
            permissionActions: [store.state.permissions.PERM_ACTION_EDIT],
            hasAccessModule: true,
        },
    },
    {
        path: '/contractors',
        name: 'contractors',
        component: () => import(/* webpackChunkName: "contractors" */ '@views/library/contractors/ContractorsIndex'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_CONTRACTORS,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },
    {
        path: '/contractors/:id',
        props: true,
        name: 'contractors_item',
        component: () => import(/* webpackChunkName: "contractors_item" */ '@views/library/contractors/ContractorsItem'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_CONTRACTORS,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },

    // Details
    {
        path: '/details',
        name: 'details',
        component: () => import(/* webpackChunkName: "details" */ '@views/library/details/DetailsIndex'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_DETAILS,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },
    {
        path: '/details-save/:id?',
        name: 'details_item_save',
        props: true,
        component: () => import(/* webpackChunkName: "details_item_save" */ '@views/library/details/DetailsItemSave'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_DETAILS,
            permissionActions: [store.state.permissions.PERM_ACTION_EDIT],
        },
    },

    // Algorithms
    {
        path: '/algorithms',
        name: 'algorithms',
        component: () => import(/* webpackChunkName: "algorithms" */ '@views/library/algorithms/AlgorithmsIndex'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_ALGORITHMS,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },
    {
        path: '/algorithms/:id',
        props: true,
        name: 'algorithms_item',
        component: () => import(/* webpackChunkName: "algorithms_item" */ '@views/library/algorithms/AlgorithmsItem'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_ALGORITHMS,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },

    //technicalData
    {
        path: '/roles',
        name: 'roles',
        component: () => import(/* webpackChunkName: "roles" */ '@views/technicalData/roles/RolesIndex'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_ROLES,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },
    {
        path: '/roles/role/:id?',
        props: true,
        name: 'roles_item',
        component: () => import(/* webpackChunkName: "roles_item" */ '@views/technicalData/roles/RolesItem'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_ROLES,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },
    {
        path: '/role-templates',
        name: 'role_templates',
        component: () =>
            import(/* webpackChunkName: "role_templates" */ '@views/technicalData/roles/modules/templates/TemplatesIndex'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_ROLES,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },
    {
        path: '/role-templates/role-template/:id?',
        props: true,
        name: 'role_templates_item',
        component: () =>
            import(/* webpackChunkName: "role_templates_item" */ '@views/technicalData/roles/modules/templates/TemplatesItem'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_ROLES,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },
    {
        path: '/translate-scopes',
        name: 'translate_scopes',
        component: () =>
            import(/* webpackChunkName: "/translate-scopes" */ '@views/technicalData/translateScopes/TranslateScopesIndex'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_TRANSLATE_SCOPES,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },

    // Params
    {
        path: '/params',
        name: 'params',
        component: () => import(/* webpackChunkName: "params" */ '@views/technicalData/params/ParamsIndex'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_LIBRARY_PARAMS,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },
    {
        path: '/params/material/variables/:id/dependencies',
        name: 'material_variables_deps',
        props: true,
        component: () =>
            import(/* webpackChunkName: "material_variables_deps" */ '@views/technicalData/params/materials/MaterialVariableDeps'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_LIBRARY_PARAMS,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },
    {
        path: '/params/equipment/variables/:id/dependencies',
        name: 'equipment_variables_deps',
        props: true,
        component: () =>
            import(/* webpackChunkName: "equipment_variables_deps" */ '@views/technicalData/params/equipments/EquipmentVariableDeps'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_LIBRARY_PARAMS,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },

    {
        path: '/settings',
        name: 'settings',
        component: () => import(/* webpackChunkName: "settings" */ '@views/technicalData/settings/SettingsIndex'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_SETTINGS,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },

    //operations
    {
        path: '/operations',
        name: 'operations',
        component: () => import(/* webpackChunkName: "operations" */ '@views/operations/OperationsIndex'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_TM_OPERATIONS,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },
    {
        path: '/prepress',
        name: 'prepress',
        component: () => import(/* webpackChunkName: "prepress" */ '@views/operations/modules/prepress/PrepressIndex'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_TM_OPERATIONS_PRE,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },
    {
        path: '/print',
        name: 'print',
        component: () => import(/* webpackChunkName: "print" */ '@views/operations/modules/print/PrintIndex'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_TM_OPERATIONS_PRINT,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },
    {
        path: '/postpress',
        name: 'postpress',
        component: () => import(/* webpackChunkName: "postpress" */ '@views/operations/modules/postpress/PostpressIndex'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_TM_OPERATIONS_POST,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },
    {
        path: '/logistic',
        name: 'logistic',
        component: () => import(/* webpackChunkName: "logistic" */ '@views/operations/modules/logistic/LogisticIndex'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_TM_OPERATIONS_LOGISTIC,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },

    //stock
    {
        path: '/availability',
        name: 'availability',
        component: () => import(/* webpackChunkName: "availability" */ '@views/stock/availability/AvailabilityIndex'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_STORE,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },
    {
        path: '/inventory',
        name: 'inventory',
        component: () => import(/* webpackChunkName: "inventory" */ '@views/stock/inventory/InventoryIndex'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_STORE,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },
    {
        path: '/movement/:id',
        props: true,
        name: 'movement',
        component: () => import(/* webpackChunkName: "movement" */ '@views/stock/movement/MovementIndex'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_STORE,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },
    {
        path: '/supplier',
        name: 'supplier',
        component: () => import(/* webpackChunkName: "supplier" */ '@views/stock/supplier/SupplierIndex'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_STORE,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },
    {
        path: '/stocklog',
        name: 'stocklog',
        component: () => import(/* webpackChunkName: "stocklog" */ '@views/stock/log/LogIndex'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_STORE,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },

    // Ready Products
    {
        path: '/ready-products',
        name: 'ready_products',
        component: () => import(/* webpackChunkName: "ready_products" */ '@views/stock/readyProducts/ReadyProductsIndex'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_STORE,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },
    {
        path: '/ready-products/movement/:id',
        name: 'ready_products_movement',
        props: true,
        component: () =>
            import(/* webpackChunkName: "ready_products_movement" */ '@views/stock/readyProducts/movement/ReadyProductsMovementIndex'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_STORE,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },
    {
        path: '/ready-products/booking',
        name: 'ready_products_booking',
        component: () =>
            import(/* webpackChunkName: "ready_products_booking" */ '@views/stock/readyProducts/booking/ReadyProductsBookingIndex'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_STORE,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },

    {
        path: '/supply',
        name: 'supply',
        component: () => import(/* webpackChunkName: "supply" */ '@views/stock/supply/SupplyIndex'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_STORE,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },
    {
        path: '/booking',
        name: 'booking',
        component: () => import(/* webpackChunkName: "booking" */ '@views/stock/booking/BookingIndex'),
        meta: {
            permissionModule: store.state.permissions.PERM_MODULE_STORE,
            permissionActions: [store.state.permissions.PERM_ACTION_VIEW],
        },
    },

    // knowledge
    {
        path: '/knowledge',
        name: 'knowledge',
        component: () => import(/* webpackChunkName: "knowledge" */ '@views/knowledge/KnowledgeIndex'),
        redirect: '/knowledge/engine',
        children: [
            {
                path: '/knowledge/:base',
                name: 'knowledge_engine',
                component: () => import(/* webpackChunkName: "knowledge-engine" */ '@views/knowledge/_nested/KnowledgeBase'),
                meta: {
                    is_base: true,
                },
                beforeEnter: (to, from, next) => {
                    to.params.base !== 'engine'
                        ? next({ name: `knowledge_${to.params.base}`, params: { base: to.params.base } })
                        : next();
                },
                children: [
                    {
                        path: '/knowledge/:base/results',
                        name: 'engine_results',
                        props: true,
                        component: () =>
                            import(/* webpackChunkName: "engine-results" */ '@views/knowledge/modules/results/ResultsIndex'),
                        meta: {
                            is_results: true,
                        },
                    },
                    {
                        path: '/knowledge/:base/category/:p_category',
                        name: 'engine_primary_category',
                        props: true,
                        component: () =>
                            import(/* webpackChunkName: "engine-primary-category" */ '@views/knowledge/_nested/KnowledgeCategory'),
                        meta: {
                            is_category: true,
                        },
                        children: [
                            {
                                path: '/knowledge/:base/category/:p_category/article/:article?',
                                name: 'engine_primary_article',
                                props: true,
                                component: () =>
                                    import(
                                        /* webpackChunkName: "engine-primary-article" */ '@views/knowledge/_nested/KnowledgeArticle'
                                    ),
                                meta: {
                                    is_article: true,
                                },
                            },
                            {
                                path: '/knowledge/:base/category/:p_category/category/:s_category',
                                name: 'engine_secondary_category',
                                props: true,
                                component: () =>
                                    import(
                                        /* webpackChunkName: "engine-secondary-category" */ '@views/knowledge/_nested/KnowledgeCategory'
                                    ),
                                meta: {
                                    is_category: true,
                                },
                                children: [
                                    {
                                        path: '/knowledge/:base/category/:p_category/category/:s_category/article/:article?',
                                        name: 'engine_secondary_article',
                                        props: true,
                                        component: () =>
                                            import(
                                                /* webpackChunkName: "engine-secondary-article" */ '@views/knowledge/_nested/KnowledgeArticle'
                                            ),
                                        meta: {
                                            is_article: true,
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                path: '/knowledge/:base',
                name: 'knowledge_partner',
                component: () => import(/* webpackChunkName: "knowledge-partner" */ '@views/knowledge/_nested/KnowledgeBase'),
                meta: {
                    is_base: true,
                },
                children: [
                    {
                        path: '/knowledge/:base/results',
                        name: 'partner_results',
                        props: true,
                        component: () =>
                            import(/* webpackChunkName: "partner-results" */ '@views/knowledge/modules/results/ResultsIndex'),
                        meta: {
                            is_results: true,
                        },
                    },
                    {
                        path: '/knowledge/:base/category/:p_category',
                        name: 'partner_primary_category',
                        props: true,
                        component: () =>
                            import(/* webpackChunkName: "partner-primary-category" */ '@views/knowledge/_nested/KnowledgeCategory'),
                        meta: {
                            is_category: true,
                        },
                        children: [
                            {
                                path: '/knowledge/:base/category/:p_category/article/:article?',
                                name: 'partner_primary_article',
                                props: true,
                                component: () =>
                                    import(
                                        /* webpackChunkName: "partner-primary-article" */ '@views/knowledge/_nested/KnowledgeArticle'
                                    ),
                                meta: {
                                    is_article: true,
                                },
                            },
                            {
                                path: '/knowledge/:base/category/:p_category/category/:s_category',
                                name: 'partner_secondary_category',
                                props: true,
                                component: () =>
                                    import(
                                        /* webpackChunkName: "partner-secondary-category" */ '@views/knowledge/_nested/KnowledgeCategory'
                                    ),
                                meta: {
                                    is_category: true,
                                },
                                children: [
                                    {
                                        path: '/knowledge/:base/category/:p_category/category/:s_category/article/:article?',
                                        name: 'partner_secondary_article',
                                        props: true,
                                        component: () =>
                                            import(
                                                /* webpackChunkName: "partner-secondary-article" */ '@views/knowledge/_nested/KnowledgeArticle'
                                            ),
                                        meta: {
                                            is_article: true,
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                path: '/knowledge/:base',
                name: 'knowledge_renewals',
                component: () =>
                    import(/* webpackChunkName: "knowledge-renewals" */ '@views/knowledge/modules/renewals/RenewalsIndex'),
                meta: {
                    is_base: true,
                },
                children: [
                    {
                        path: '/knowledge/:base/article/:renewal?',
                        name: 'renewals_item',
                        props: true,
                        component: () =>
                            import(/* webpackChunkName: "renewal-item" */ '@views/knowledge/modules/renewals/RenewalsItem'),
                        meta: {
                            is_article: true,
                        },
                    },
                ],
            },
        ],
    },

    {
        path: '/FAQ',
        name: 'FAQ',
        component: () => import(/* webpackChunkName: "FAQ" */ '@views/knowledge/FAQIndex'),
        meta: {
            is_FAQ: true,
        },
        children: [
            {
                path: '/FAQ/results',
                name: 'FAQ_results',
                props: true,
                component: () => import(/* webpackChunkName: "FAQ-results" */ '@views/knowledge/modules/results/ResultsIndex'),
                meta: {
                    is_results: true,
                },
            },
            {
                path: '/FAQ/categories',
                name: 'FAQ_categories',
                props: true,
                component: () => import(/* webpackChunkName: "FAQ-categories" */ '@views/knowledge/_nested/KnowledgeBase'),
                meta: {
                    is_base: true,
                },
                children: [
                    {
                        path: '/FAQ/categories/:p_category',
                        name: 'FAQ_primary_category',
                        props: true,
                        component: () =>
                            import(/* webpackChunkName: "FAQ-primary-category" */ '@views/knowledge/_nested/KnowledgeCategory'),
                        meta: {
                            is_category: true,
                        },
                        children: [
                            {
                                path: '/FAQ/categories/:p_category/article/:article',
                                name: 'FAQ_primary_article',
                                props: true,
                                component: () =>
                                    import(/* webpackChunkName: "FAQ-primary-article" */ '@views/knowledge/_nested/KnowledgeArticle'),
                                meta: {
                                    is_article: true,
                                },
                            },
                            {
                                path: '/FAQ/categories/:p_category/category/:s_category',
                                name: 'FAQ_secondary_category',
                                props: true,
                                component: () =>
                                    import(
                                        /* webpackChunkName: "FAQ-secondary-category" */ '@views/knowledge/_nested/KnowledgeCategory'
                                    ),
                                meta: {
                                    is_category: true,
                                },
                                children: [
                                    {
                                        path: '/FAQ/categories/:p_category/category/:s_category/article/:article',
                                        name: 'FAQ_secondary_article',
                                        props: true,
                                        component: () =>
                                            import(
                                                /* webpackChunkName: "FAQ-secondary-article" */ '@views/knowledge/_nested/KnowledgeArticle'
                                            ),
                                        meta: {
                                            is_article: true,
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                path: '/FAQ/renewals',
                name: 'FAQ_renewals',
                props: true,
                component: () => import(/* webpackChunkName: "FAQ-renewals" */ '@views/knowledge/modules/renewals/RenewalsIndex'),
                meta: {
                    is_base: true,
                },
                children: [
                    {
                        path: '/FAQ/article/:renewal',
                        name: 'FAQ_renewal',
                        props: true,
                        component: () =>
                            import(/* webpackChunkName: "FAQ-renewal-item" */ '@views/knowledge/modules/renewals/RenewalsItem'),
                        meta: {
                            is_article: true,
                        },
                    },
                ],
            },
        ],
    },

    // developer
    {
        path: '/druk-developer',
        name: 'druk_developer',
        component: () => import(/* webpackChunkName: "booking" */ '@views/DrukDeveloper'),
    },

    //404-------------------------/
    {
        path: '*',
        redirect: '/404',
    },
    {
        path: '/404',
        component: () => import(/* webpackChunkName: "404" */ '@views/error404'),
        meta: {
            layout: store.state.KEY_LAYOUT_ERROR,
        },
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        return new Promise((resolve, reject) => {
            if (to.name === from.name) return;

            let routeTimer = setInterval(() => {
                clearInterval(routeTimer);
                resolve({ x: 0, y: 0 });
            }, 200);
        });
    },
});

router.beforeEach((to, from, next) => {
    if (to.meta.requiresFree === true) {
        next();
    } else {
        if (store.getters.isLogged) {
            if (to.meta.permissionModule && to.meta.permissionActions) {
                let success = false;

                to.meta.permissionActions.forEach((action) => {
                    if ($fn.hasAccess(to.meta.permissionModule, action)) success = true;
                });

                if (to.meta.isAvailable !== undefined && !to.meta.isAvailable) success = false;
                success ? next() : next({ name: 'dashboard' });
            } else {
                next();
            }
        } else {
            next({ name: 'login' });
        }
    }
});

export default router;
