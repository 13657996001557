export default {
    namespaced: true,
    state: {
        INACTIVE_FILTERING_KEYS: ['page', 'limit', 'sort', 'direction'],

        ORDER_PAYMENT_ID_CAHS: 1,
        PRODUCT_STATUS_ID_NEW: 1,

        TYPOGRAPHY_CLASSIC_COLOR: '#F80416',

        MOCKUP_DELETING_PAYLOAD: {
            destination_original: [
                'destination_original',
                'preview_original',
                'destination',
                'preview',
                'destination_imposition',
                'preview_imposition',
            ],

            destination_cut_original: [
                'destination_cut_original',
                'preview_cut_original',
                'destination_cut',
                'preview_cut',
                'destination_imposition_cut',
                'preview_imposition_cut',
            ],

            destination: ['destination', 'preview', 'destination_imposition', 'preview_imposition'],

            destination_cut: ['destination_cut', 'preview_cut', 'destination_imposition_cut', 'preview_imposition_cut'],

            destination_imposition: ['destination_imposition', 'preview_imposition'],

            destination_imposition_cut: ['destination_imposition_cut', 'preview_imposition_cut'],

            destination_custom: ['destination_custom'],
        },

        ROTATION_OPTIONS: [0, 90, 180, 270],

        WINDING_OPTIONS: {
            0: {
                outside: 1,
                inside: 5,
            },
            90: {
                outside: 3,
                inside: 7,
            },
            180: {
                outside: 2,
                inside: 6,
            },
            270: {
                outside: 4,
                inside: 8,
            },
        },

        CALCULATION_ROTATION_OPTIONS: [
            {
                title: '0°',
                value: 0,
            },
            {
                title: '90°',
                value: 90,
            },
            {
                title: '180°',
                value: 180,
            },
            {
                title: '270°',
                value: 270,
            },
        ],

        DIRECTION_ASC_KEY: 'asc',
        DIRECTION_DESC_KEY: 'desc',

        MOCKUP_EXTRA_PRICE_KEY: 'mockup_extra_price',

        TRANSACTION_TYPE_REFUND: 'refund',

        REMAINS_OPTIONS: [
            {
                title: null,
                key: 'common.material_remains.ends',
                value: 'ends',
            },
            {
                title: null,
                key: 'common.material_remains.finished',
                value: 'finished',
            },
            {
                title: null,
                key: 'common.material_remains.sufficiently',
                value: 'sufficiently',
            },
        ],

        PUBLISHED_OPTIONS: [
            {
                title: null,
                key: 'common.published',
                value: 1,
            },
            {
                title: null,
                key: 'common.hide',
                value: 0,
            },
        ],

        PAID_OPTIONS: [
            {
                title: null,
                key: 'pay.status.true',
                value: 1,
            },
            {
                title: null,
                key: 'pay.status.false',
                value: 0,
            },
        ],

        PRICE_CHANGERS: [
            {
                id: 0,
                key: 'create_order.price_edit.discount',
            },

            {
                id: 1,
                key: 'create_order.price_edit.markup',
            },

            {
                id: 2,
                key: 'create_order.price_edit.fixed_price',
            },
        ],

        PERIOD_OPTIONS: [
            {
                title: null,
                key: 'tasks.modal_export.created_at',
                value: 0,
            },
            {
                title: null,
                key: 'tasks.modal_export.completed_at',
                value: 1,
            },
        ],

        DETAIL_LABELS_DEFAULT: {
            default_padding: 4,
            max_padding: 10,
            default_width: 40,
            max_width: 300,
            default_height: 40,
            max_height: 400,
        },

        ICON_MOCKUP_STATUS_EMPTY: 'fa-regular fa-circle-xmark',
        ICON_MOCKUP_STATUS_SUCCESS: 'fa-regular fa-circle-check',
        ICON_MOCKUP_STATUS_ERROR: 'fa-regular fa-circle-exclamation',

        LABEL_MOCKUP_STATUS_EMPTY: 'orders.item.product_mockups_empty',
        LABEL_MOCKUP_STATUS_SUCCESS: 'orders.item.product_mockups_success',
        LABEL_MOCKUP_STATUS_ERROR: 'orders.item.product_mockups_error',

        OPERATIONS_PERMISSIONS: [
            {
                key: 'front_tasksPre',
                id: 1,
            },
            {
                key: 'front_tasksPrint',
                id: 2,
            },
            {
                key: 'front_tasksPost',
                id: 3,
            },
            {
                key: 'front_tasksLogistic',
                id: 4,
            },
        ],

        CIRCLE_STICKER_TYPE: 0,
        RECTANGLE_ROUNDED_STICKER_TYPE: 2,
        CUSTOM_STICKER_TYPE: 3,

        PARTNERS_PRODUCTS: [
            {
                key: 'sheet_offset_products',
                translation_alias: 'common.partners_products.sheet_offset_products',
                title: null,
                value: 0,
            },
            {
                key: 'pad_printing',
                translation_alias: 'common.partners_products.pad_printing',
                title: null,
                value: 0,
            },
            {
                key: 'sheet_fed_digital_printing',
                translation_alias: 'common.partners_products.sheet_fed_digital_printing',
                title: null,
                value: 0,
            },
            {
                key: 'souvenir_products',
                translation_alias: 'common.partners_products.souvenir_products',
                title: null,
                value: 0,
            },
            {
                key: 'roll_flexo',
                translation_alias: 'common.partners_products.roll_flexo',
                title: null,
                value: 0,
            },
            {
                key: 'label_and_packing',
                translation_alias: 'common.partners_products.label_and_packing',
                title: null,
                value: 0,
            },
            {
                key: 'roll_digital_printing',
                translation_alias: 'common.partners_products.roll_digital_printing',
                title: null,
                value: 0,
            },
            {
                key: 'books_and_magazines',
                translation_alias: 'common.partners_products.books_and_magazines',
                title: null,
                value: 0,
            },
            {
                key: 'large_format_products',
                translation_alias: 'common.partners_products.large_format_products',
                title: null,
                value: 0,
            },
            {
                key: 'fabric_printing',
                translation_alias: 'common.partners_products.fabric_printing',
                title: null,
                value: 0,
            },
            {
                key: 'silkscreen',
                translation_alias: 'common.partners_products.silkscreen',
                title: null,
                value: 0,
            },
            {
                key: 'other',
                translation_alias: 'common.partners_products.other',
                title: null,
                value: 0,
            },
        ],

        PARTNERS_MODULES: [
            {
                key: 'bitrix',
                translation_alias: 'common.partners_modules.bitrix',
                title: null,
                value: 0,
            },
            {
                key: 'roll_print',
                translation_alias: 'common.partners_modules.roll_print',
                title: null,
                value: 0,
            },
            {
                key: 'personal_server',
                translation_alias: 'common.partners_modules.personal_server',
                title: null,
                value: 0,
            },
            {
                key: 'alternative_design',
                translation_alias: 'common.partners_modules.alternative_design',
                title: null,
                value: 0,
            },
            {
                key: 'personal_site',
                translation_alias: 'common.partners_modules.personal_site',
                title: null,
                value: 0,
            },
            {
                key: 'large_format_print',
                translation_alias: 'common.partners_modules.large_format_print',
                title: null,
                value: 0,
            },
            {
                key: 'offset_print',
                translation_alias: 'common.partners_modules.offset_print',
                title: null,
                value: 0,
            },
            {
                key: 'list_labels',
                translation_alias: 'common.partners_modules.list_labels',
                title: null,
                value: 0,
            },
            {
                key: 'prepress_auto',
                translation_alias: 'common.partners_modules.prepress_auto',
                title: null,
                value: 0,
            },
            {
                key: 'contactors',
                translation_alias: 'common.partners_modules.contactors',
                title: null,
                value: 0,
            },
            {
                key: 'stock',
                translation_alias: 'common.partners_modules.stock',
                title: null,
                value: 0,
            },
            {
                key: 'team_management',
                translation_alias: 'common.partners_modules.team_management',
                title: null,
                value: 0,
            },
        ],

        DEFAULT_SORTED_AREA_OPTIONS: [
            {
                translation_alias: 'sort.to_new',
                value: 'new_asc',
            },
            {
                translation_alias: 'sort.to_old',
                value: 'new_desc',
            },
            {
                translation_alias: 'sort.by_alpha',
                value: 'title_asc',
            },
        ],

        CATEGORIES_SORTED_AREA_OPTIONS: [
            {
                translation_alias: 'sort.by_default',
                value: 'sort_asc',
            },
        ],

        SORT_KEY_TITLE: 'title',
        SORT_KEY_SORT: 'sort',
        SORT_DIRECTION_ASC: 'asc',
        SORT_DIRECTION_DESC: 'desc',

        CHANGER_DISCOUNT: 0,
        CHANGER_MARKUP: 1,
        CHANGER_FIXED_PRICE: 2,

        RELATION_COMMENT_KEY_OPERATION: 'operation',
        RELATION_COMMENT_KEY_PRODUCT: 'orderProduct',

        DEFAULT_SORTING: {
            sort: null,
            direction: null,
        },

        MAX_LENGTH_SEO_TITLE: 60,
        MAX_LENGTH_SEO_DESCRIPTION: 150,

        SHORT_ANIMATION_DURATION: 200,
        MEDIUM_ADNIMATION_DURATION: 300,
        LONG_ANIMATION_DURATION: 650,

        SHORT_ANIMATION_DELAY: 100,
        MEDIUM_ANIMATION_DELAY: 200,
        LONG_ANIMATION_DELAY: 300,
        EXTRA_LONG_ANIMATION_DELAY: 450,

        RIGTH_MOUSE_BUTTON_CODE: 2,
        LEFT_ARROW_KEY: 37,
        TOP_ARROW_KEY: 38,
        RIGHT_ARROW_KEY: 39,
        BOTTOM_ARROW_KEY: 40,
        BACKSPACE_KEY: 8,
        DELETE_KEY: 46,
        MAC_DELETE_KEY: 8,

        LG_SCREEN_WIDTH: 1280,
        DEF_SCREEN_WIDTH: 1024,
        MD_SCREEN_WIDTH: 768,
        SM_SCREEN_WIDTH: 480,

        SYSTEM_COLORS_MAP: [
            'azul',
            'water-blue',
            'topaz',
            'dusk',
            'iris',
            'rouge',
            'rose-red',
            'pale-magenta',
            'warm-pink',
            'guardsman-red',
            'scarlet',
            'coral-red',
            'gamboge',
            'sunglow',
            'candlelight',
            'dark-aqua',
            'shamrock-green',
            'atlantis',
            'neutral',
        ],

        SYSTEM_ALT_COLOR_MAP: [
            'primary',
            'secondary',
            'tertiary',
            'azul',
            'water-blue',
            'topaz',
            'dusk',
            'iris',
            'rouge',
            'rose-red',
            'pale-magenta',
            'warm-pink',
            'guardsman-red',
            'scarlet',
            'coral-red',
            'gamboge',
            'sunglow',
            'candlelight',
            'dark-aqua',
            'shamrock-green',
            'atlantis',
        ],

        SYSTEM_DRUK_COLOR: '#EC2D3C',

        EMPTY_IMG: 'https://druk.s3.amazonaws.com/static/not-images.svg',
    },
};
