<template>
    <div
        :id="editorId"
        :style="{
            [`--editor-surface`]: contentBackground,
        }"
        class="editor selector"
        :class="{ 'has-active': hasActive, 'has-full': hasFull }">
        <div class="editor__title selector__label" :class="{ [`druk-l-surface-${surface}`]: surface }" @click="onToggle">
            <span>
                <label>{{ label }}</label>
                <template v-if="isRequired">*</template>
            </span>

            <druk-hint v-if="hint && !hint.isSide && !hint.isHidden" :tooltip="tooltip" :icon="icon" :isInline="true" />
        </div>

        <ckeditor v-model="editorValue" ref="area" :editor="editor" :config="editorConfig" @input="$emit('input', $event)"></ckeditor>
    </div>
</template>

<script>
    import CKEditor from '@ckeditor/ckeditor5-vue2';
    import UploadAdapter from './ckeditor5/UploadAdapter.js';
    import * as customEditor from './ckeditor5/build/ckeditor';

    import './ckeditor5/build/translations/uk';
    import './ckeditor5/build/translations/en';
    import './ckeditor5/build/translations/pl';
    import './ckeditor5/build/translations/ru';

    export default {
        name: 'form-editor',

        components: {
            ckeditor: CKEditor.component,
        },

        props: {
            value: {
                required: true,
            },

            label: String,
            language: String,
            hint: [String, Object],

            surface: {
                type: String,
                default: 'tint-pale',
            },

            rules: [String, Object],
        },

        data() {
            return {
                editor: customEditor,
                editorData: this.value,

                toogleView: false,
                hasActive: false,
            };
        },

        mounted() {
            window.addEventListener('click', (e) => {
                this.onDetectClick(e);
            });
        },

        computed: {
            editorId() {
                return `editor-${$fn.generateHash()}`;
            },

            editorIds() {
                return [this.editorId];
            },

            editorValue: {
                get() {
                    return this.value || '';
                },
                set(data) {
                    this.editorData = data;
                },
            },

            editorConfig() {
                const groups = {
                    fonts: {
                        label: this.$t('ckeditor.fonts'),
                        icon: 'text',
                        items: ['fontFamily', 'fontSize', '|', 'fontColor', 'fontBackgroundColor', 'highlight'],
                    },

                    textAdditional: {
                        label: this.$t('ckeditor.additional'),
                        icon: 'threeVerticalDots',
                        items: ['strikethrough', 'subscript', 'superscript', 'specialCharacters'],
                    },
                };

                const baseColors = [
                    {
                        color: 'rgb(0, 0, 0)',
                        label: 'Black',
                    },
                    {
                        color: 'rgb(60, 59, 60)',
                        label: 'Dim grey',
                    },
                    {
                        color: 'rgb(120, 119, 120)',
                        label: 'Grey',
                    },
                    {
                        color: 'rgb(222, 216, 225)',
                        label: 'Light grey',
                    },
                    {
                        color: 'rgb(255, 255, 255)',
                        label: 'White',
                    },
                    {
                        color: 'rgb(249, 58, 45)',
                        label: 'Red',
                    },
                    {
                        color: 'rgb(255, 196, 89)',
                        label: 'Orange',
                    },
                    {
                        color: 'rgb(253, 219, 34)',
                        label: 'Yellow',
                    },
                    {
                        color: 'rgb(146, 205, 73)',
                        label: 'Light green',
                    },
                    {
                        color: 'rgb(0, 166, 62)',
                        label: 'Green',
                    },
                    {
                        color: 'rgb(146, 204, 255)',
                        label: 'Aquamarine',
                    },
                    {
                        color: 'rgb(144, 235, 207)',
                        label: 'Turquoise',
                    },
                    {
                        color: 'rgb(0, 143, 214)',
                        label: 'Light blue',
                    },
                    {
                        color: 'rgb(0, 110, 217)',
                        label: 'Blue',
                    },
                    {
                        color: 'rgb(111, 82, 195)',
                        label: 'Purple',
                    },
                ];

                return {
                    editor: customEditor,
                    editorData: this.editorData,
                    sourceData: this.editorData,
                    toogleView: false,

                    heading: {
                        options: [
                            {
                                model: 'paragraph',
                                title: this.$t('editor.p'),
                                class: 'ck-heading_paragraph',
                            },
                            {
                                model: 'heading1',
                                view: 'h1',
                                title: this.$t('editor.h1'),
                                class: 'ck-heading_heading1',
                            },
                            {
                                model: 'heading2',
                                view: 'h2',
                                title: this.$t('editor.h2'),
                                class: 'ck-heading_heading2',
                            },
                            {
                                model: 'heading3',
                                view: 'h3',
                                title: this.$t('editor.h3'),
                                class: 'ck-heading_heading3',
                            },
                            {
                                model: 'heading4',
                                view: 'h4',
                                title: this.$t('editor.h4'),
                                class: 'ck-heading_heading4',
                            },
                        ],
                    },

                    image: {
                        toolbar: [
                            'imageTextAlternative',
                            '|',
                            'imageStyle:alignLeft',
                            'imageStyle:alignCenter',
                            'imageStyle:alignRight',
                            'linkImage',
                        ],
                    },

                    fontFamily: {
                        options: [
                            'Montserrat, sans-serif',
                            'Arial, sans-serif',
                            'Courier New, serif',
                            'Georgia, serif',
                            'Lucida Sans Unicode, sans-serif',
                            'Tahoma, sans-serif',
                            'Times New Roman, sans-serif',
                            'Trebuchet MS, sans-serif',
                            'Verdana, sans-serif',
                        ],
                    },

                    fontSize: {
                        options: [
                            'default',
                            { title: '8', model: '8px' },
                            { title: '12', model: '12px' },
                            { title: '14', model: '14px' },
                            { title: '16', model: '16px' },
                            { title: '18', model: '18px' },
                            { title: '24', model: '24px' },
                            { title: '28', model: '28px' },
                            { title: '36', model: '36px' },
                            { title: '48', model: '48px' },
                            { title: '60', model: '60px' },
                        ],
                    },

                    fontColor: {
                        colors: baseColors,
                    },

                    fontBackgroundColor: {
                        colors: baseColors,
                    },

                    table: {
                        contentToolbar: [
                            'tableColumn',
                            'tableRow',
                            'tablecaprion',
                            'tableCellProperties',
                            'tableColumnResize',
                            'tableProperties',
                            'mergeTableCells',
                        ],

                        tableProperties: {
                            defaultProperties: {
                                alignment: 'center',
                                borderColor: '#bdbdbd',
                                borderWidth: '1px',
                                borderStyle: 'solid',
                            },
                        },
                    },

                    sourceEditing: {
                        title: '',
                        name: '',
                        label: '',
                        styles: [{ name: '' }],
                    },

                    toolbar: {
                        items: [
                            'undo',
                            'redo',
                            '|',
                            'heading',
                            '|',
                            groups.fonts,
                            '|',
                            'findAndReplace',
                            'alignment',
                            '|',
                            'bold',
                            'italic',
                            'underline',
                            groups.textAdditional,
                            'removeFormat',
                            '|',
                            'link',
                            '|',
                            'horizontalLine',
                            '|',
                            'bulletedList',
                            'numberedList',
                            '|',
                            'insertTable',
                            'blockQuote',
                            '|',
                            'outdent',
                            'indent',
                            '|',
                            'imageUpload',
                            'mediaEmbed',
                            '|',
                            'sourceEditing',
                            'showBlocks',
                            'style',
                        ],

                        shouldNotGroupWhenFull: false,
                    },

                    htmlSupport: {
                        allow: [
                            {
                                name: /.*/,
                                attributes: true,
                                classes: true,
                                styles: true,
                            },
                        ],
                    },

                    mediaEmbed: {
                        previewsInData: true,
                    },

                    language: this.language || this.selectedLang,

                    extraPlugins: [this.uploader],
                    removePlugins: ['Base64UploadAdapter'],
                };
            },

            selectedLang() {
                return this.$store.state.selectedLang;
            },

            contentBackground() {
                let base = this.surface.split('-');
                return `var(--druk-${[...base].shift()}-surface-container-${[...base].pop()})`;
            },

            tooltip() {
                if (!this.hint) return null;

                return {
                    icon: this.hint.icon?.name ? this.hint.icon : { name: this.hint.icon || 'circle-info' },
                    text: this.hint.text || this.hint,
                    from: this.hint.from || 'top',
                    maxWidth: this.hint.maxWidth,
                    isNotCollapse: this.hint.isNotCollapse,
                };
            },

            icon() {
                return {
                    name: this.hint.icon?.name || this.hint.icon || 'circle-info',
                    color: this.hint.icon?.color || 'primary',
                    size: this.hint.icon?.size || 'xs',
                };
            },

            isRequired() {
                if (this.rules && (typeof this.rules === 'string' ? this.rules.indexOf('required') !== -1 : this.rules.required))
                    return true;
                return false;
            },

            hasFull() {
                return this.value !== null && this.value !== '';
            },
        },

        methods: {
            uploader: function (editor) {
                editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
                    return new UploadAdapter(loader);
                };
            },

            onToggle() {
                this.$refs.area.focus();
            },

            onDetectClick(e) {
                this.hasActive = this.editorIds.includes(e.target.closest('.editor')?.getAttribute('id')) ? true : false;
            },
        },
    };
</script>

<style lang="scss">
    .editor {
        height: inherit !important;
        --ck-font-size-base: 14px; /* @TODO: Try resetting the default variables of stylesmap instead a css-selectors */
        --ck-color-toolbar-background: var(--editor-surface);
        --ck-show-blocks-border-color: var(--druk-outline-variant);
        --ck-disabled-opacity: 0.38;
        &.has-full,
        &:hover {
            .selector__label {
                color: var(--druk-on-surface);
            }
            .ck .ck-toolbar {
                outline: 1px solid var(--druk-on-surface);
            }
            .ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable {
                outline: 1px solid var(--druk-on-surface);
            }
            .ck.ck-toolbar .ck.ck-toolbar__separator {
                background: var(--druk-on-surface) !important;
            }
        }
        &__head {
            display: flex;
            justify-content: space-between;
        }
        &__title {
        }
        &__textarea {
            position: relative;
            min-height: 500px;
            width: 100%;
            resize: none;
        }
        &.has-hidden {
            :only-child {
                display: none;
            }
        }
        &.has-active {
            .selector__label {
                color: var(--druk-primary);
            }
            .ck .ck-sticky-panel__content {
                border: none;
            }
            .ck .ck-toolbar {
                border-color: var(--druk-primary) !important;
                outline: 1px solid var(--druk-primary);
            }
            .ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable {
                border-color: var(--druk-primary) !important;
                outline: 1px solid var(--druk-primary);
            }
            .ck.ck-toolbar .ck.ck-toolbar__separator {
                outline: initial !important;
                background: var(--druk-primary) !important;
            }
        }
        ul li {
            list-style-type: disc;
        }
        ol li {
            list-style-type: decimal;
        }
        li {
            padding-left: 10px;
            margin-left: 20px;
        }
        .ck .ck-editor__editable,
        .ck-source-editing-area {
            min-height: 250px;
        }
    }

    .ck .ck-powered-by-balloon {
        display: none !important;
    }

    .ck .ck-content,
    .ck-source-editing-area textarea {
        line-height: 1.2;
    }

    .ck-source-editing-area textarea {
        padding: 10px 10px;
    }

    .ck .ck-button:not(.ck-insert-table-dropdown-grid-box) {
        cursor: pointer;
        padding: 4px !important;
        margin: 0px !important;
        height: 36px !important;
    }

    .ck .ck-toolbar {
        margin-bottom: 1px;
        border-top-left-radius: 4px !important;
        border-top-right-radius: 4px !important;
        background-color: var(--editor-surface);
    }

    .ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable {
        border-bottom-left-radius: 4px !important;
        border-bottom-right-radius: 4px !important;
    }

    .ck .ck-toolbar,
    .ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable {
        border-width: 2px;
        border-color: transparent;
        outline: 1px solid var(--druk-outline);
        background-color: var(--editor-surface);
        transition:
            border-color 0.2s ease-in-out,
            outline 0.2s ease-in-out;
    }

    .ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
        box-shadow: none !important;
    }

    .ck .ck-editor__editable {
        min-height: 150px;
    }

    .ck.ck-toolbar .ck.ck-toolbar__separator {
        margin: 3px 0px !important;
        background-color: var(--druk-outline);
        outline: initial;
        transition:
            background 0.2s ease-in-out,
            outline 0.2s ease-in-out;
    }

    .ck .ck-dropdown {
        &:focus-visible {
            outline: none !important;
        }
    }

    .ck .ck-button__label {
        // font-family: $f_main;
        font-size: 14px !important;
    }

    .ck .ck-button:not(.ck-insert-table-dropdown-grid-box),
    .ck .ck-splitbutton {
        min-width: 36px !important;
        color: var(--druk-on-surface-variant);
        border-radius: 8px !important;
        font-weight: 500 !important;
        transition: background-color 0.2s ease-in-out;
        &:focus-visible {
            border: none;
            outline: none;
            background-color: initial !important;
        }
        &:not(:hover) {
            border: none;
            outline: none;
        }
    }

    .ck > .ck-button:not(.ck-color-grid__tile):not(.ck-insert-table-dropdown-grid-box),
    .ck > .ck.ck-color-table .ck.ck-button:not(.ck-color-grid__tile),
    .ck .ck-splitbutton {
        background-color: transparent !important;
        &:hover {
            border: none;
            background-color: var(--druk-state-layers-on-surface-0-08) !important;
        }
        :active {
            background-color: var(--druk-state-layers-on-surface-0-12) !important;
        }
        &:active,
        &:focus {
            border: none;
            box-shadow: initial !important;
        }
    }

    .ck > .ck-button:not(.ck-color-grid__tile):not(.ck-insert-table-dropdown-grid-box):not(.ck-button_with-text).ck-on {
        color: var(--druk-primary) !important;
        &:hover {
            background-color: var(--druk-state-layers-primary-0-08) !important;
        }
        &:active {
            background-color: var(--druk-state-layers-primary-0-12) !important;
        }
    }

    .ck .ck-button.ck-button_with-text {
        flex-direction: row-reverse;
    }
    .ck .ck-button.ck-disabled {
        pointer-events: none;
        opacity: 0.38;
    }

    .ck > .ck .ck-button .ck.ck-button__label,
    .ck > .ck .ck-button .ck.ck-button__icon,
    .ck > .ck.ck-color-table .ck.ck-button:not(.ck-color-grid__tile) .ck.ck-button__label {
        &:active,
        &:focus {
            outline: initial;
            background-color: initial !important;
        }
    }

    .ck .ck-splitbutton:hover .ck-button {
        background-color: transparent !important;
    }

    .ck .ck-splitbutton .ck-splitbutton__action,
    .ck .ck-splitbutton .ck-splitbutton__arrow {
        min-width: initial !important;
    }

    .ck .ck-splitbutton .ck-splitbutton__action {
        border-radius: 8px 0px 0px 8px !important;
    }

    .ck .ck-splitbutton .ck-splitbutton__arrow {
        border-radius: 0px 8px 8px 0px !important;
    }

    .ck .ck-splitbutton .ck-file-dialog-button {
        padding: 0px !important	;
    }

    .ck .ck-splitbutton:hover .ck-splitbutton__arrow::after {
        background-color: var(--druk-outline-variant) !important;
    }

    .ck-rounded-corners .ck.ck-dropdown .ck-dropdown__panel .ck-toolbar .ck-button.ck-insert-table-dropdown-grid-box {
        background-color: transparent !important;
        border: 1px solid var(--druk-outline-variant);
        &:hover {
            background-color: var(--druk-state-layers-secondary-0-08) !important;
        }
        &:active {
            background-color: var(--druk-state-layers-secondary-0-12) !important;
        }
    }

    .ck-rounded-corners .ck.ck-dropdown .ck-dropdown__panel .ck-toolbar .ck-button.ck-insert-table-dropdown-grid-box.ck-on {
        background-color: var(--druk-secondary-container) !important;
        &:hover {
            background-color: var(--druk-secondary-container) !important;
        }
        &:active {
            background-color: var(--druk-secondary-container) !important;
        }
    }

    .ck.ck-dropdown .ck-dropdown__arrow {
        margin-left: 0px;
        margin-right: 4px;
    }

    .ck.ck-dropdown .ck-button.ck-dropdown__button.ck-on {
        border-radius: 8px;
    }

    .ck.ck-dropdown.ck-heading-dropdown .ck-dropdown__button .ck-button__label {
        padding-right: 12px;
    }

    .ck.ck-button .ck.ck-button__icon {
        &:active,
        &:focus {
            background-color: initial !important;
        }
    }

    .ck .ck.ck-button.ck-button-save,
    .ck .ck.ck-button-replace {
        margin-right: 4px !important;
    }

    .ck .ck.ck-button.ck-button-cancel,
    .ck .ck.ck-button-replaceall {
        margin-left: 4px !important;
    }

    .ck .ck.ck-button.ck-button-find {
        margin: 0px 8px !important;
    }

    .ck .ck.ck-button.ck-button-save,
    .ck .ck.ck-button.ck-button-cancel,
    .ck .ck.ck-button.ck-button-find,
    .ck .ck.ck-button-replace,
    .ck .ck.ck-button-replaceall {
        flex-direction: row;
        border: 1px solid var(--druk-outline) !important;
        &:hover {
            background-color: var(--druk-state-layers-primary-0-08) !important;
        }
        &:active,
        &:focus {
            background-color: var(--druk-state-layers-primary-0-12) !important;
        }
        .ck.ck-button__label,
        .ck.ck-button__icon {
            color: var(--druk-primary);
        }
        .ck.ck-button__icon {
            width: 16px;
            height: 16px;
            color: var(--druk-primary);
        }
    }

    .ck-rounded-corners .ck.ck-dropdown .ck-dropdown__panel {
        border-radius: 4px !important;
        background-color: var(--druk-tint-surface-container-brighter);
        border: none;
        box-shadow: var(--druk-elevation-2);
    }

    .ck-rounded-corners .ck.ck-dropdown .ck-dropdown__panel .ck-toolbar {
        background-color: transparent;
        margin-bottom: initial;
        box-shadow: initial;
    }

    .ck-rounded-corners .ck.ck-dropdown .ck-dropdown__panel .ck-toolbar.ck-toolbar_vertical .ck-button {
        border-radius: initial !important;
        &:first-child {
            border-top-left-radius: 4px !important;
            border-top-right-radius: 4px !important;
        }
        &:last-child {
            border-bottom-left-radius: 4px !important;
            border-bottom-right-radius: 4px !important;
        }
    }

    .ck-rounded-corners .ck.ck-dropdown .ck-dropdown__panel .ck-toolbar .ck-button.ck-on {
        color: var(--druk-on-surface);
        background-color: var(--druk-state-layers-secondary-0-08) !important;
        &:hover {
            background-color: var(--druk-state-layers-on-surface-0-08) !important;
        }
        &:active {
            background-color: var(--druk-state-layers-on-surface-0-12) !important;
        }
    }

    .ck-rounded-corners .ck.ck-dropdown .ck-dropdown__panel .ck-list {
        max-height: 300px;
        background-color: transparent;
    }

    .ck-rounded-corners .ck.ck-dropdown .ck-dropdown__panel .ck-toolbar .ck-toolbar__items .ck.ck-toolbar__separator {
        outline: none !important;
        background-color: var(--druk-outline-variant) !important;
    }

    .ck.ck-dropdown .ck-dropdown__panel .ck-toolbar {
        border-radius: 4px;
        border: none;
        background-color: var(--druk-tint-surface-container-brighter);
        box-shadow: var(--druk-elevation-2);
        outline: none;
    }

    .ck.ck-list__item {
        &:first-child {
            border-top-left-radius: 4px !important;
            border-top-right-radius: 4px !important;
        }
        &:last-child {
            border-bottom-left-radius: 4px !important;
            border-bottom-right-radius: 4px !important;
        }
    }

    .ck.ck-list__item .ck.ck-button {
        min-height: 36px;
        height: auto !important;
        border-radius: initial !important;
    }

    .ck.ck-list__item .ck.ck-button.ck-on {
        color: var(--druk-on-surface);
        &:hover {
            background-color: var(--druk-state-layers-on-surface-0-08) !important;
        }
        &:active {
            background-color: var(--druk-state-layers-on-surface-0-12) !important;
        }
    }

    .ck.ck-list__item .ck-button.ck-on {
        color: var(--druk-on-surface);
        background-color: var(--druk-state-layers-secondary-0-08);
    }

    .ck.ck-form__header {
        height: initial;
        border-bottom: 1px solid var(--druk-outline-variant);
    }

    .ck.ck-character-grid {
        overflow-x: auto;
        width: auto;
    }

    .ck.ck-character-grid .ck-character-grid__tiles {
        grid-gap: initial;
        grid-template-columns: repeat(7, auto);
        column-gap: 4px;
        row-gap: 4px;
        justify-items: center;
    }

    .ck.ck-character-grid .ck-character-grid__tile {
        box-shadow: initial !important;
    }

    .ck.ck-character-info {
        border-top: 1px solid var(--druk-outline-variant);
    }

    .ck.ck-character-info .ck-character-info__name {
        max-width: 240px;
    }

    .ck.ck-character-info .ck-character-info__code {
        color: var(--druk-on-surface-variant);
        opacity: 1;
    }

    .ck .ck-button.ck-on:not(.ck-disabled):active,
    .ck.ck-button.ck-on,
    .ck-source-editing-area textarea:not([readonly]):focus {
        background: initial;
        box-shadow: initial;
    }

    .ck.ck-list__item .ck-button {
        padding: 8px 10px !important;
        margin: 0px;
    }

    .ck.ck-heading_heading1 span {
        font-size: 32px !important;
    }

    .ck.ck-heading_heading2 span {
        font-size: 24px !important;
    }

    .ck.ck-heading_heading3 span {
        font-size: 18.72px !important;
    }

    .ck.ck-heading_heading4 span {
        font-size: 14.4px !important;
    }

    .ck.ck-reset.ck-list {
        max-height: 220px;
        overflow: auto;
    }

    .ck.ck-color-table
        .ck.ck-button:not(.ck-color-grid__tile):not(.ck-button-save):not(.ck-buttton-cancel):not(.ck-button-find):not(
            .ck-button-replace
        ):not(.ck-button-replaceall) {
        border: initial;
        &:first-child {
            border-radius: 4px 4px 0px 0px !important;
        }
        &:last-child {
            border-radius: 0px 0px 4px 4px !important;
        }
    }

    .ck.ck-button.ck-input-color__remove-color {
        flex-direction: row;
        padding: 8px 12px !important;
        border-radius: 4px 4px 0px 0px !important;
    }

    .ck.ck-color-table .ck.ck-button.ck-button_with-text {
        flex-direction: row;
        padding: 8px 12px !important;
        &:first-child {
            border-top-left-radius: 4px !important;
            border-top-right-radius: 4px !important;
        }
        &:last-child {
            border-bottom-left-radius: 4px !important;
            border-bottom-right-radius: 4px !important;
        }
    }

    .ck.ck-color-table .ck-color-grid {
        border-top: 1px solid var(--druk-outline-variant);
        border-bottom: 1px solid var(--druk-outline-variant);
    }

    .ck.ck-color-grid {
        border-top: 1px solid var(--druk-outline-variant);
    }

    .ck.ck-color-table .ck.ck-button.ck-button_with-text .ck-button__label {
        line-height: 20px;
    }

    .ck.ck-color-table .ck.ck-color-grid__tile,
    .ck.ck-color-grid .ck.ck-color-grid__tile {
        box-shadow: var(--druk-elevation-1);
        &:hover {
            box-shadow: var(--druk-elevation-2);
        }
        &:active {
            box-shadow: var(--druk-elevation-1);
        }
    }

    .ck.ck-button.ck-insert-table-dropdown-grid-box {
        padding: 1px !important;
        margin: 1px !important;
    }

    .ck-source-editing-button {
        display: inline !important;
        .ck.ck-button__label {
            display: none !important;
        }
        .ck.ck-icon.ck-reset_all-excluded.ck-icon_inherit-color.ck-button__icon {
            margin-right: 0px !important;
        }
    }

    .ck .ck-sticky-panel {
        min-height: 45px;
    }

    .ck .ck-sticky-panel__content {
        border: initial !important;
    }

    .ck-reset_all :not(.ck-reset_all-excluded *),
    .ck.ck-reset_all {
        color: var(--druk-on-surface-variant);
    }

    .ck .ck.ck-editor__editable {
        --ck-color-focus-border: var(--druk-ck-widget-container);
        --ck-color-widget-type-around-button-active: var(--druk-ck-widget-container);
        --ck-color-widget-drag-handler-icon-color-default: var(--druk-ck-widget-on-container);
        --ck-color-table-column-resizer-hover: var(--druk-ck-widget-container);

        --ck-color-widget-blurred-border: var(--druk-ck-widget-blurred-container);
        --ck-color-widget-type-around-button-blurred-editable: var(--druk-ck-widget-blurred-container);
        --ck-color-widget-drag-handler-icon-color-blurred: var(--druk-ck-widget-blurred-on-container);

        --ck-color-widget-hover-border: var(--druk-ck-widget-hover-container);
        --ck-color-widget-type-around-button-hover: var(--druk-ck-widget-hover-container);
        --ck-color-widget-drag-handler-icon-color-hover: var(--druk-ck-widget-hover-on-container);

        --ck-table-selected-cell-background: var(--druk-state-layers-blue-0-08);
        --ck-color-table-focused-cell-background: var(--druk-state-layers-blue-0-12);

        border-width: 0px !important;
        &:hover {
            --ck-color-widget-drag-handler-icon-color: var(--ck-color-widget-drag-handler-icon-color-hover);
        }
        &.ck-blurred {
            --ck-color-widget-drag-handler-icon-color: var(--ck-color-widget-drag-handler-icon-color-blurred);
        }
        &.ck-focused {
            --ck-color-widget-hover-border: var(--druk-ck-widget-container);
            --ck-color-widget-type-around-button-hover: var(--druk-ck-widget-container);
            --ck-color-widget-drag-handler-icon-color-hover: var(--ck-color-widget-drag-handler-icon-color-default);
            --ck-color-widget-drag-handler-icon-color: var(--ck-color-widget-drag-handler-icon-color-default);
        }
        .ck-widget__type-around__button {
            overflow: initial;
        }
        .ck-widget__type-around__button::before {
            content: '';
            position: absolute;
            display: inline-block;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 30px;
            height: 30px;
            border-radius: 50%;
        }
        .ck-widget__type-around__button:hover {
            animation: none;
            &::before {
                animation: ck-widget-type-around-button-sonar 0.6s ease-in-out infinite;
            }
        }
        .ck-widget__type-around__button svg * {
            stroke: var(--ck-color-widget-drag-handler-icon-color) !important;
        }
        .ck-widget.table td.ck-editor__nested-editable.ck-editor__nested-details_focused {
            box-shadow: initial;
            &:focus {
                box-shadow: initial;
            }
        }
        .table .ck-table-column-resizer {
            opacity: 0.38;
        }
        .ck-widget__resizer__handle {
            border: 1px solid var(--ck-color-focus-border);
        }

        @keyframes ck-widget-type-around-button-sonar {
            0% {
                background-color: var(--druk-state-layers-blue-0-08);
            }
            100% {
                background-color: var(--druk-state-layers-blue-0-16);
            }
        }
        @-webkit-keyframes ck-widget-type-around-button-sonar {
            0% {
                background-color: var(--druk-state-layers-blue-0-08);
            }
            100% {
                background-color: var(--druk-state-layers-blue-0-16);
            }
        }
    }

    .ck .ck-balloon-panel {
        border: none;
        outline: none;
        box-shadow: var(--druk-elevation-2);
        &::before,
        &::after {
            display: none;
        }
    }

    .ck-rounded-corners .ck.ck-balloon-panel {
        border-radius: 4px;
    }

    .ck .ck-balloon-rotator {
        height: 100%;
    }

    .ck .ck-balloon-rotator .ck.ck-toolbar {
        margin-bottom: -1px;
        border-radius: 4px;
        outline: none;
        background: initial;
        background-color: var(--druk-tint-surface-container-brighter);
    }

    .ck .ck-balloon-rotator__content {
        border-radius: 4px;
        background-color: var(--druk-tint-surface-container-brighter);
    }

    .ck.ck-button.ck-switchbutton {
        flex-direction: row;
        &:focus {
            .ck-button__toggle {
                outline: none;
                box-shadow: initial;
            }
        }
        &.ck-on {
            &:hover {
                .ck-button__toggle {
                    background-color: var(--druk-primary);
                }
                .ck-button__toggle .ck-button__toggle__inner {
                    background-color: var(--druk-primary-container);
                }
                .ck-button__toggle .ck-button__toggle__inner::before {
                    background-color: var(--druk-state-layers-primary-0-08);
                }
            }
            &:active,
            &:focus {
                .ck-button__toggle .ck-button__toggle__inner::before {
                    background-color: var(--druk-state-layers-primary-0-12);
                }
            }
            .ck-button__toggle {
                border: solid 2px var(--druk-primary);
                background-color: var(--druk-primary);
                &:active,
                &:focus {
                    background-color: var(--druk-primary) !important;
                    .ck-button__toggle__inner {
                        transform: translate(14px, -50%) !important;
                        background-color: var(--druk-primary-container) !important;
                    }
                }
            }
            .ck-button__toggle .ck-button__toggle__inner {
                width: 16px;
                height: 16px;
                transform: translate(16px, -50%) !important;
                background-color: var(--druk-on-primary);
            }
            .ck-button__toggle .ck-button__toggle__inner::before {
                transform: translate(-6px, -50%) !important;
            }
        }
        .ck-button__toggle {
            cursor: pointer;
            position: relative;
            width: 38px;
            height: 24px;
            border-radius: 16px !important;
            border: solid 2px var(--druk-outline);
            background-color: var(--druk-tint-surface-container-brighter);
            &:hover {
                background-color: var(--druk-tint-surface-container-brighter);
                .ck-button__toggle__inner {
                    background-color: var(--druk-on-surface);
                    box-shadow: initial;
                }
                .ck-button__toggle__inner::before {
                    background-color: var(--druk-state-layers-on-surface-0-08);
                }
            }
            &:active {
                .ck-button__toggle__inner {
                    background-color: var(--druk-on-surface);
                    width: 20px;
                    height: 20px;
                    transform: translate(0px, -50%);
                }
                .ck-button__toggle__inner::before {
                    transform: translate(-6px, -50%);
                    background-color: var(--druk-state-layers-on-surface-0-12);
                }
            }
            &:active,
            &:focus {
                background-color: var(--druk-tint-surface-container-brighter);
            }
        }
        .ck-button__toggle .ck-button__toggle__inner {
            cursor: pointer;
            position: absolute;
            top: 50%;
            left: 0px;
            transform: translate(4px, -50%);
            width: 10px;
            height: 10px;
            border-radius: 50% !important;
            background-color: var(--druk-outline);
            transition:
                transform 0.2s ease-in-out,
                width 0.2s ease-in-out,
                height 0.2s ease-in-out,
                background-color 0.2s ease-in-out;
            &::before {
                position: absolute;
                content: '';
                top: 50%;
                left: 0;
                transform: translate(-10px, -50%);
                border-radius: 50%;
                width: 30px;
                height: 30px;
                background-color: transparent;
                transition:
                    transform 0.2s ease-in-out,
                    background-color 0.2s ease-in-out;
            }
            &:active,
            &:focus {
                background-color: var(--druk-on-surface) !important;
            }
        }
    }

    .ck.ck-list__separator {
        background: var(--druk-outline-variant);
    }

    .ck .ck.ck-labeled-field-view {
        .ck.ck-labeled-field-view__input-wrapper {
            height: 40px;
            border-radius: 4px;
            border: solid 2px transparent;
            outline: 1px solid var(--druk-outline);
            background-color: transparent;
            transition:
                border 0.2s ease-in-out,
                outline 0.2s ease-in-out;
            &:hover {
                outline: 1px solid var(--druk-on-surface);
                .ck.ck-label {
                    color: var(--druk-on-surface);
                }
            }
        }
        .ck.ck-labeled-field-view__input-wrapper .ck.ck-label {
            top: -2px !important;
            transform: translate(4px, -50%) !important;
            font-size: 11px;
            padding: 0px 4px !important;
            background-color: var(--druk-tint-surface-container-brighter) !important;
            transition: color 0.2s ease-in-out;
        }
        &_focused {
            .ck.ck-labeled-field-view__input-wrapper {
                outline: 1px solid var(--druk-primary) !important;
                border: solid 2px var(--druk-primary) !important;
                .ck.ck-label {
                    color: var(--druk-primary) !important;
                    background-color: var(--druk-tint-surface-container-brighter) !important;
                }
            }
        }
    }

    .ck .ck.ck-labeled-field-view.ck-labeled-field-view__focused {
        .ck.ck-labeled-field-view__input-wrapper {
            outline: 1px solid var(--druk-primary) !important;
            border: solid 2px var(--druk-primary) !important;
            .ck.ck-label {
                color: var(--druk-primary) !important;
            }
        }
    }

    .ck .ck-input,
    .ck .ck-input-color,
    .ck .ck-input[readonly] {
        border: initial;
        outline: initial;
        color: var(--druk-on-surface);
        background-color: transparent;
        &:focus {
            border: initial;
            outline: initial;
            box-shadow: initial;
        }
        .ck-input-color__button {
            width: 24px;
            height: 24px;
            border: initial !important;
        }
        .ck-input-color__button__preview {
            pointer-events: none;
            border: 1px solid var(--druk-outline-variant);
        }
        .ck-input-color__button__preview__no-color-indicator {
            background-color: var(--druk-error);
        }
    }

    .ck.ck-form .ck.ck-dropdown .ck-dropdown__button:not(:focus) {
        border: transparent;
    }

    .ck.ck-form .ck-form__row .ck-table-form__dimensions-row .ck-table-form__dimension-operator {
        display: inline-flex;
        align-items: center;
        height: 40px;
    }

    .ck .ck.ck-results-counter {
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        color: var(--druk-on-surface-variant) !important;
    }

    .ck .ck-link-form,
    .ck .ck-media-form {
        .ck.ck-labeled-field-view {
            margin-right: 8px;
        }
    }
</style>
