<template>
    <div :class="{ 'druk-l-options druk-is-alt': !isInner, 'druk-l-form__footer-group druk-is-alt': isInner }">
        <div
            v-if="!item.deleted && $fn.hasAccess(PERM_MODULE_EMPLOYEES, PERM_ACTION_DELETE)"
            :class="{ 'druk-l-options__item': !isInner, 'druk-l-form__footer-item druk-is-separate': isInner }">
            <druk-dialog-option :isUnavailable="hasLoader" @success="onDeactivate">
                <druk-button
                    :type="'outlined'"
                    :icon="'user-slash'"
                    :label="$t('employees.deactivation_confirmation_action')"
                    :isHighlighted="true"
                    :isWainting="hasLoader"
                    :isDisabled="isOwner && hasTypographyAccess" />
            </druk-dialog-option>

            <div
                v-if="isOwner && hasTypographyAccess"
                :class="{ 'druk-l-section__support': !isInner, 'druk-l-form__support': isInner }">
                <druk-support :isWarning="true">{{ $t('employees.owner_deactivate_unavailable') }}</druk-support>
            </div>
        </div>

        <div v-if="isEditMode" :class="{ 'druk-l-options__item': !isInner, 'druk-l-form__footer-item': isInner }">
            <druk-button
                :type="'outlined'"
                :icon="'xmark'"
                :label="$t('admin.btn.cancel')"
                :isWainting="hasLoader"
                @click="$emit('onCancel')" />
        </div>

        <div v-if="!item.deleted" :class="{ 'druk-l-options__item': !isInner, 'druk-l-form__footer-item': isInner }">
            <druk-button
                :icon="isEditMode ? 'floppy-disk' : 'pen'"
                :label="isEditMode ? $t('btn.save') : $t('btn.edit')"
                :isWainting="hasLoader"
                @click="$emit('onStartSaving', $event)" />
        </div>

        <div v-else :class="{ 'druk-l-options__group': !isInner, 'druk-l-form__footer-group': isInner }">
            <div v-if="hasReachedEmployeesLimit" :class="{ 'druk-l-options__hint': !isInner, 'druk-l-form__footer-hint': isInner }">
                <prompt-limit :isToolTip="true" :isActivationType="true" />
            </div>

            <div :class="{ 'druk-l-options__item': !isInner, 'druk-l-form__footer-item': isInner }">
                <druk-dialog-option :isUnavailable="hasLoader || hasReachedEmployeesLimit" @success="onActivate">
                    <druk-button
                        :icon="'user-check'"
                        :label="$t('employees.on_activate_employee')"
                        :isWainting="hasLoader"
                        :isDisabled="hasReachedEmployeesLimit" />
                </druk-dialog-option>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

    import ModalDeactivation from './ModalDeactivation';

    import PromptLimit from './PromptLimit';

    export default {
        name: 'employees-actions',

        components: {
            PromptLimit,
        },

        props: {
            item: Object,

            isEditMode: Boolean,
            isInner: Boolean,
        },

        computed: {
            ...mapState({
                PERM_MODULE_EMPLOYEES: (state) => state.permissions.PERM_MODULE_EMPLOYEES,
                PERM_ACTION_DELETE: (state) => state.permissions.PERM_ACTION_DELETE,

                loadingGetRole: (state) => state.roles.loadingGetItem,
                loadingGetRoleTemplate: (state) => state.roles.templates.loadingGetItem,
                loadingRestoreItem: (state) => state.employees.loadingRestoreItem,
                loadingDeleteItem: (state) => state.employees.loadingDeleteItem,
            }),

            ...mapGetters({
                hasERPAccess: 'hasERPAccess',
                hasTypographyAccess: 'hasTypographyAccess',
                hasReachedEmployeesLimit: 'pricing/hasReachedEmployeesLimit',
            }),

            hasLoader() {
                return this.loadingGetRole || this.loadingGetRoleTemplate || this.loadingRestoreItem || this.loadingDeleteItem;
            },

            isOwner() {
                return this.item.typography_role?.owner;
            },
        },

        methods: {
            ...mapActions({
                GET_ITEM: 'employees/GET_ITEM',
                RESTORE_ITEM: 'employees/RESTORE_ITEM',
                DELETE_ITEM: 'employees/DELETE_ITEM',
            }),

            async onDeactivate() {
                let resp = await this.deleteItem({ id: this.item.id, force: this.hasERPAccess, is_single: true });

                if (!resp.success) {
                    this.$modal.show(ModalDeactivation, {
                        item: this.item,
                        dependencies: resp.dependencies,
                        fnWait: () => this.deleteItem({ id: this.item.id, force: true, is_single: true }),
                    });
                } else this.getItem(this.item.id);
            },

            deleteItem(data) {
                return this.DELETE_ITEM(data);
            },

            getItem(id) {
                return this.GET_ITEM(id);
            },

            onActivate() {
                return this.RESTORE_ITEM({ id: this.item.id, is_single: true });
            },
        },
    };
</script>
