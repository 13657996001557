import { render, staticRenderFns } from "./DrukDropdown.vue?vue&type=template&id=0a7c3fd0&scoped=true"
import script from "./DrukDropdown.vue?vue&type=script&lang=js"
export * from "./DrukDropdown.vue?vue&type=script&lang=js"
import style0 from "./DrukDropdown.vue?vue&type=style&index=0&id=0a7c3fd0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a7c3fd0",
  null
  
)

export default component.exports